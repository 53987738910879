var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Flex, SimpleGrid } from '@chakra-ui/react';
import { useQueryModel } from '@obtainly/http';
import { formatUnixTime, fromUnixTime, getUnixTime } from '@obtainly/utils';
import { useMemo, useState } from 'react';
import { Card, CardBody } from './Card';
import { ContentLoader } from './ContentLoader';
import { MonthYearPicker } from './DateInput';
import { LedgerLogItem } from './LedgerLogs';
import { ListMaker } from './ListMaker';
export var MerchantPayments = function (_a) {
    var date = _a.date, setDate = _a.setDate, merchantId = _a.merchantId, distributorId = _a.distributorId;
    var _b = useState('all'), filter = _b[0], setFilter = _b[1];
    var activeDate = useMemo(function () { return (date ? fromUnixTime(date) : new Date()); }, [date]);
    var payload = useMemo(function () {
        return {
            _id: merchantId,
            distributor_id: distributorId,
            month: activeDate.getMonth() + 1,
            year: activeDate.getFullYear(),
        };
    }, [activeDate, merchantId, distributorId]);
    var _c = useQueryModel('merchant.statement', payload, {
        enabled: !!distributorId,
    }), data = _c.data, isLoading = _c.isLoading;
    var transactions = useMemo(function () {
        var _a;
        return ((_a = data === null || data === void 0 ? void 0 : data.all) === null || _a === void 0 ? void 0 : _a.list) || [];
    }, [data]);
    var filteredTransactions = useMemo(function () {
        var _transactions = transactions.reduce(function (output, currentItem) {
            // drop any unwanted tag from this point
            if (['signup'].includes(currentItem.tag)) {
                return output;
            }
            if (filter === 'all') {
                output.push(currentItem);
                return output;
            }
            if (currentItem.tag !== 'transfer') {
                output.push(currentItem);
                return output;
            }
            return output;
        }, []);
        return _transactions.sort(function (a, b) {
            return Number(a.created || 0) > Number(b.created || 0) ? -1 : 1;
        });
    }, [filter, transactions]);
    return (_jsxs(_Fragment, { children: [_jsx(SimpleGrid, __assign({ mt: 3, pt: "4px", alignItems: "center", justifyContent: "flex-end", mb: 3, gridTemplateColumns: "1fr" }, { children: _jsxs(Flex, __assign({ alignItems: "center", justifyContent: "flex-end", gap: "0.75rem" }, { children: [_jsx(MonthYearPicker, { value: getUnixTime(activeDate), onChange: setDate }, void 0), _jsx(Button, __assign({ size: "sm", onClick: function () { return setFilter('all'); }, colorScheme: filter === 'all' ? 'primary' : 'grey', color: filter === 'all' ? 'white' : 'black', _hover: { bg: filter === 'all' ? 'primary.500' : 'grey.500' } }, { children: "All" }), void 0), _jsx(Button, __assign({ size: "sm", onClick: function () { return setFilter('credit'); }, colorScheme: filter === 'credit' ? 'primary' : 'grey', color: filter === 'credit' ? 'white' : 'black', _hover: { bg: filter === 'credit' ? 'primary.500' : 'grey.500' } }, { children: "Credit" }), void 0)] }), void 0) }), void 0), _jsx(Card, { children: _jsxs(CardBody, __assign({ p: 0, pt: 2 }, { children: [_jsx(ContentLoader, { isLoading: isLoading, contentUnavailable: !(filteredTransactions === null || filteredTransactions === void 0 ? void 0 : filteredTransactions.length), errorMessage: !distributorId
                                ? 'Select a Supplier'
                                : "No payments found in ".concat(formatUnixTime(getUnixTime(activeDate), 'MMMM yyyy')) }, void 0), !isLoading && (filteredTransactions === null || filteredTransactions === void 0 ? void 0 : filteredTransactions.length) ? (_jsx(ListMaker, { data: filteredTransactions, component: _jsx(LedgerLogItem, {}, void 0) }, void 0)) : null] }), void 0) }, void 0)] }, void 0));
};
