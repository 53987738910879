var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Box, Flex, Heading } from '@chakra-ui/layout';
import { StylesProvider, useMultiStyleConfig, useStyles, } from '@chakra-ui/system';
export var Card = function (props) {
    var children = props.children, rest = __rest(props, ["children"]);
    var styles = useMultiStyleConfig('Card', {});
    // Pass the computed styles into the `__css` prop
    return (_jsx(Box, __assign({ __css: styles.card }, rest, { children: _jsx(StylesProvider, __assign({ value: styles }, { children: children }), void 0) }), void 0));
};
export var CardHeader = function (props) {
    var styles = useStyles();
    var children = props.children, heading = props.heading, rest = __rest(props, ["children", "heading"]);
    return (_jsxs(Flex, __assign({ as: "header", __css: styles.header, pt: children ? '12px' : '20px' }, rest, { children: [_jsx(Flex, __assign({ alignItems: "center", gridGap: 2 }, { children: _jsx(Heading, __assign({ fontSize: "lg", fontWeight: "semibold" }, { children: heading }), void 0) }), void 0), _jsx(Flex, { children: children }, void 0)] }), void 0));
};
export var CardBody = function (props) {
    var styles = useStyles();
    return _jsx(Box, __assign({ __css: styles.body }, props), void 0);
};
export var CardFooter = function (props) {
    var styles = useStyles();
    return _jsx(Box, __assign({ __css: styles.footer }, props), void 0);
};
