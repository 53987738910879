/**
 * Build query string from object keys & values
 *
 * @param params
 */
export var buildQueryString = function (params) {
    var paramsArray = [];
    if (params && typeof params === 'object') {
        Object.keys(params).forEach(function (key) {
            params[key] && paramsArray.push("".concat(key, "=").concat(params[key]));
        });
    }
    return (paramsArray === null || paramsArray === void 0 ? void 0 : paramsArray.length) ? "?".concat(paramsArray.join('&')) : '';
};
/**
 * Search URL params
 */
export var getQueryParams = function () {
    return new URLSearchParams(window ? window.location.search : {});
};
