var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export function parseOrderMeta(order, extraMeta) {
    var metaString = '';
    var metaObject = {};
    metaString = (order === null || order === void 0 ? void 0 : order.meta) || metaString;
    try {
        metaObject = JSON.parse((order === null || order === void 0 ? void 0 : order.meta) || '{}');
    }
    catch (e) {
        throw new Error('Failed to parse order meta');
    }
    metaObject = __assign(__assign({}, metaObject), extraMeta);
    metaString = Object.keys(metaObject).length
        ? JSON.stringify(metaObject)
        : metaString;
    return {
        metaString: metaString,
        metaObject: metaObject,
    };
}
