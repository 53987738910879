export var navigationItems = [
    {
        name: 'Operations',
        route: '',
        path: '',
        permission: 'view_settings',
        children: [
            {
                name: 'Credit',
                route: 'funding-requests',
                path: 'funding-requests',
                permission: 'view_settings',
            },
            {
                name: 'Customers',
                route: 'merchants',
                path: 'merchant',
                permission: 'view_settings',
            },
            {
                name: 'Suppliers',
                route: 'suppliers',
                path: 'supplier',
                permission: 'view_settings',
            },
        ],
    },
    {
        name: 'Transactions',
        route: '',
        path: '',
        permission: 'view_transactions',
        children: [
            {
                name: 'Settlements',
                route: 'settlements',
                path: 'settlements',
                permission: 'view_transactions',
            },
            {
                name: 'Inflows',
                route: 'inflows',
                path: 'inflows',
                permission: 'view_transactions',
            },
        ],
    },
    {
        name: 'Utilities',
        route: '',
        path: '',
        permission: 'view_settings',
        children: [
            /*{
              name: 'MonoConnect',
              route: 'mono-connect',
              path: 'monoconnect',
              permission: 'view_settings',
            },*/
            {
                name: 'Mailing List',
                route: 'mailing-list',
                path: 'mailing_list',
                permission: 'view_settings',
            },
            {
                name: 'Notifications',
                route: 'notifications',
                path: 'notifications',
                permission: 'view_settings',
            },
            {
                name: 'Configurations',
                route: 'configs',
                path: 'configs',
                permission: 'view_settings',
            },
        ],
    },
    {
        name: 'Reports',
        route: '',
        path: '',
        permission: 'view_reports',
        children: [
            {
                name: 'Daily Settlements',
                route: 'administrator_settlement',
                path: 'settlement',
                permission: 'view_reports',
            },
            {
                name: 'Supplier Reconciliation',
                route: 'administrator_reconciliation',
                path: 'reconciliation',
                permission: 'view_reports',
            },
            {
                name: 'Credit Reports',
                path: 'funding-requests-reports',
                route: '/funding-requests/reports',
                permission: 'view_reports',
            },
        ],
    },
    {
        name: 'Settings',
        route: 'settings',
        path: 'settings',
        permission: 'view_settings',
    },
];
