import { Settlements } from './pages';
export var settlementRoutes = [
    {
        id: 'settlements',
        path: '/settlements',
        component: Settlements,
        requiresAuth: true,
        exact: true,
    },
];
export * from './components';
