var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFilter, useQueryStringParse } from '@obtainly/hooks';
import { useQueryModel } from '@obtainly/http';
import { basicSearch, capitalizeString, getUnixTime } from '@obtainly/utils';
import React from 'react';
import { Button, Flex, Menu, MenuButton, MenuItem, MenuList, } from '@chakra-ui/react';
import { InflowStatus } from '@obtainly/models';
import { startOfDay, subDays } from 'date-fns';
import { RiArrowDropDownLine } from 'react-icons/ri';
import { Card, CardBody, ContentHeader, ContentLoader, Layout, PageTabs, SearchInput, } from 'src/core/components';
import { useRouter } from 'src/core/hooks';
import { InflowsTable } from '../components';
var DateRange = {
    Today: 'today',
    Yesterday: 'yesterday',
    Last_Week: 'last_week',
    Thirty_Days: '30_days',
    Ninety_Days: '90_days',
};
export var Inflows = function () {
    var _a;
    var router = useRouter('inflows');
    var filters = useQueryStringParse(['search', 'status', 'from_date'], ['page']);
    var activeFilters = Object.keys(filters);
    var fromDate = React.useMemo(function () {
        var now = new Date();
        var past = now;
        if (activeFilters.includes('from_date')) {
            past =
                filters.from_date === DateRange.Ninety_Days
                    ? subDays(now, 90)
                    : filters.from_date === DateRange.Thirty_Days
                        ? subDays(now, 30)
                        : filters.from_date === DateRange.Last_Week
                            ? subDays(now, 7)
                            : filters.from_date === DateRange.Yesterday
                                ? subDays(now, 1)
                                : now;
        }
        return getUnixTime(startOfDay(past));
    }, [activeFilters, filters]);
    var _b = useQueryModel('inflow.read_from', { from_timestamp: fromDate }, { enabled: !!fromDate }), data = _b.data, isLoading = _b.isLoading;
    var inflows = React.useMemo(function () {
        return data || [];
    }, [data]);
    var filteredInflows = useFilter(inflows, filters, {
        search: function (inflow) {
            return basicSearch(inflow.merchant.business_name, filters.search) ||
                basicSearch(inflow.distributor.name, filters.search) ||
                basicSearch(inflow.reference, filters.search);
        },
        status: function () { return basicSearch(inflows.status, filters.status); },
        from_date: function () { return true; },
    });
    var activeTab = React.useMemo(function () {
        if (activeFilters.includes('from_date')) {
            return (Object.entries(DateRange).findIndex(function (_a) {
                var value = _a[1];
                return value === filters.from_date;
            }) || 0);
        }
        return 0;
    }, [filters, activeFilters]);
    var pushQueryParam = function (values) {
        if (values) {
            router.switchQuery(__assign(__assign({}, filters), values));
            return;
        }
        router.go();
    };
    var handleTabChange = function (index) {
        var dateRangeValues = Object.values(DateRange);
        if (dateRangeValues[index]) {
            pushQueryParam({ from_date: dateRangeValues[index] });
        }
        else {
            pushQueryParam();
        }
    };
    return (_jsxs(Layout, __assign({ meta: { title: 'Inflows' } }, { children: [_jsx(ContentHeader, __assign({ heading: "Inflows", border: 0 }, { children: _jsx(Flex, __assign({ gridGap: 2 }, { children: _jsxs(Menu, { children: [_jsx(MenuButton, __assign({ as: Button, size: "sm", colorScheme: (filters === null || filters === void 0 ? void 0 : filters.status) ? 'primary' : 'gray', rightIcon: _jsx(RiArrowDropDownLine, {}, void 0), textTransform: "capitalize" }, { children: (_a = filters === null || filters === void 0 ? void 0 : filters.status) !== null && _a !== void 0 ? _a : 'All' }), void 0), _jsxs(MenuList, { children: [_jsx(MenuItem, __assign({ onClick: function () { return pushQueryParam({ status: undefined }); } }, { children: "All" }), void 0), _jsx(MenuItem, __assign({ onClick: function () { return pushQueryParam({ status: InflowStatus.Pending }); } }, { children: "Pending" }), void 0), _jsx(MenuItem, __assign({ onClick: function () {
                                            return pushQueryParam({ status: InflowStatus.Processed });
                                        } }, { children: "Processed" }), void 0)] }, void 0)] }, void 0) }), void 0) }), void 0), _jsxs(Card, { children: [_jsx(PageTabs, { index: activeTab, onChange: handleTabChange, tabs: Object.entries(DateRange).map(function (_a) {
                            var value = _a[1];
                            return capitalizeString(value.split('_').join(' '));
                        }) }, void 0), _jsx(CardBody, { children: _jsx(SearchInput, { value: filters.search || '', onChange: function (value) { return pushQueryParam({ search: value }); }, placeholder: "Search inflows", isDisabled: !(inflows === null || inflows === void 0 ? void 0 : inflows.length) }, void 0) }, void 0), _jsxs(CardBody, __assign({ p: 0, pt: 2 }, { children: [_jsx(ContentLoader, { isLoading: isLoading }, void 0), !isLoading && _jsx(InflowsTable, { inflows: filteredInflows || [] }, void 0)] }), void 0)] }, void 0)] }), void 0));
};
