import React from 'react';
import getObjectPath from 'lodash/get';
export function useExporter(dataSet, initialColumns) {
    var _a = React.useState(initialColumns || []), columns = _a[0], setColumns = _a[1];
    var toggleColumn = function (label) {
        var newColumns = columns.map(function (column) {
            if (column.label === label) {
                column.selected = (column === null || column === void 0 ? void 0 : column.selected) ? false : true;
            }
            return column;
        });
        setColumns(newColumns);
    };
    // Sync columns with initialColumns
    React.useEffect(function () {
        (initialColumns === null || initialColumns === void 0 ? void 0 : initialColumns.length) && setColumns(initialColumns);
    }, [initialColumns]);
    var data = React.useMemo(function () {
        var data = dataSet || [];
        if ((data === null || data === void 0 ? void 0 : data.length) && (columns === null || columns === void 0 ? void 0 : columns.length)) {
            data = data.map(function (payload) {
                var newItem = {};
                var selectedColumns = columns.filter(function (column) {
                    return column === null || column === void 0 ? void 0 : column.selected;
                });
                selectedColumns.forEach(function (column) {
                    var value = getObjectPath(payload, column.accessor, '-');
                    if (column === null || column === void 0 ? void 0 : column.modifier) {
                        value = column.modifier(value, payload);
                    }
                    newItem[column.label] = value;
                });
                return newItem;
            });
        }
        return data;
    }, [dataSet, columns]);
    return { columns: columns, toggleColumn: toggleColumn, data: data };
}
