export var Input = {
    parts: ['field', 'icon', 'addon'],
    baseStyle: {
        icon: {
            color: 'gray.300',
        },
        field: {
            _placeholder: {
                color: 'gray.600',
            },
            _disabled: {
                bg: 'gray.50',
                opacity: '1 !important',
            },
        },
    },
    sizes: {
        sm: {
            field: {
                borderRadius: 'md',
                fontSize: 'md',
                height: '34px',
            },
            addon: {
                height: '34px',
                fontWeight: 'medium',
                borderRadius: 'md',
            },
        },
    },
    variants: {
        outline: {
            field: {
                _focus: {
                    boxShadow: 'var(--chakra-shadows-outline)',
                },
            },
        },
    },
};
export var Select = {
    parts: ['select', 'field', 'icon'],
    baseStyle: {
        icon: {
            color: 'gray.400',
        },
    },
    sizes: {
        sm: {
            field: {
                borderRadius: 'md',
                fontSize: 'md',
                height: '34px',
                _disabled: {
                    bg: 'gray.50',
                    opacity: 1,
                },
            },
        },
    },
    variants: {
        outline: {
            field: {
                _focus: {
                    // borderColor: 'primary.600',
                    boxShadow: 'var(--chakra-shadows-outline)',
                },
            },
        },
    },
};
export var Switch = {
    defaultProps: {
        colorScheme: 'primary',
    },
};
export var Checkbox = {
    defaultProps: {
        colorScheme: 'primary',
    },
};
