var _a;
import { useQueryModel } from '@obtainly/http';
import { CACRegistrationType, MerchantDocumentStatus, MerchantDocumentTag, } from '@obtainly/models';
import { useMemo } from 'react';
var defaultRequiredDocuments = new Set([
    MerchantDocumentTag.BVN,
    MerchantDocumentTag.IdentityCard,
    MerchantDocumentTag.Passport,
    MerchantDocumentTag.UtilityBill,
    MerchantDocumentTag.CACCertificate,
    MerchantDocumentTag.CACRegistrationType,
    MerchantDocumentTag.CACStatusReport,
    MerchantDocumentTag.AuthLetter,
    MerchantDocumentTag.BankStatement,
    MerchantDocumentTag.Remita,
]);
var otherRequiredDocuments = (_a = {},
    _a[CACRegistrationType.Business] = new Set(),
    _a[CACRegistrationType.Company] = new Set([
        MerchantDocumentTag.CACMemorandum,
        MerchantDocumentTag.CACShareAllotmentPage,
    ]),
    _a);
export function useMerchantDocumentState(merchantId) {
    var _a = useQueryModel('merchant_document.read_by_merchant', { merchant_id: merchantId }, { enabled: !!merchantId, refetchOnWindowFocus: true }), merchantDocuments = _a.data, isLoading = _a.isLoading, isRefetching = _a.isRefetching, refetchDocuments = _a.refetch;
    var documentsState = useMemo(function () {
        var _a;
        var _documents = {};
        var aggregateRequiredDocuments = new Set(defaultRequiredDocuments);
        var registrationType = (_a = merchantDocuments === null || merchantDocuments === void 0 ? void 0 : merchantDocuments.find(function (doc) { return doc.tag === MerchantDocumentTag.CACRegistrationType; })) === null || _a === void 0 ? void 0 : _a.value;
        if (registrationType &&
            (otherRequiredDocuments === null || otherRequiredDocuments === void 0 ? void 0 : otherRequiredDocuments[registrationType])) {
            otherRequiredDocuments[registrationType].forEach(function (tag) { return aggregateRequiredDocuments.add(tag); });
        }
        var unset = new Set(aggregateRequiredDocuments);
        var unverified = new Set(aggregateRequiredDocuments);
        var verified = new Set();
        merchantDocuments === null || merchantDocuments === void 0 ? void 0 : merchantDocuments.forEach(function (doc) {
            _documents[doc.tag] = doc;
            var isRequired = aggregateRequiredDocuments.has(doc.tag);
            if (isRequired) {
                if ((doc === null || doc === void 0 ? void 0 : doc.status) === MerchantDocumentStatus.Verified) {
                    verified.add(doc.tag);
                    unverified.delete(doc.tag);
                }
                unset.delete(doc.tag);
            }
        });
        return {
            documents: merchantDocuments || [],
            documentsDict: _documents,
            unverified: unverified,
            unverifiedCount: unverified.size,
            verified: verified,
            verifiedCount: verified.size,
            unset: unset,
            unsetCount: unset.size,
            requiredDocumentCount: aggregateRequiredDocuments.size,
            isComplete: aggregateRequiredDocuments.size === verified.size,
            isLoading: isLoading,
            isRefetching: isRefetching,
            refetchDocuments: refetchDocuments,
        };
    }, [merchantDocuments, isLoading, isRefetching]); // eslint-disable-line
    return documentsState;
}
