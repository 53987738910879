var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button } from '@chakra-ui/react';
import { useFilter, useQueryStringParse } from '@obtainly/hooks';
import { useQueryModel } from '@obtainly/http';
import { SearchInput } from '@obtainly/ui/web-shared';
import { basicSearch } from '@obtainly/utils';
import { orderBy } from 'lodash';
import React from 'react';
import { Card, CardBody, ContentHeader, ContentLoader, Layout, } from 'src/core/components';
import { useRouter } from 'src/core/hooks';
import { MailingListTable } from '../components';
import { MailingListForm } from '../components/MailingListForm';
export var MailingList = function () {
    var _a = React.useState(false), createMode = _a[0], setCreateMode = _a[1];
    var toggleCreateMode = function () {
        setCreateMode(!createMode);
    };
    var router = useRouter('mailing_list');
    var _b = useQueryModel('mailing_list.read_all', {}), mailList = _b.data, isLoading = _b.isLoading, refetch = _b.refetch;
    var filters = useQueryStringParse([], ['page']);
    var filteredList = useFilter(orderBy(mailList, 'dec'), filters, {
        search: function (mailList) {
            return basicSearch(mailList.channel, filters.search) ||
                basicSearch(mailList.identifier, filters.search) ||
                basicSearch(mailList.recipients, filters.search) ||
                basicSearch(mailList.status, filters.search) ||
                basicSearch(mailList.tag, filters.search) ||
                basicSearch(mailList.type, filters.search);
        },
    });
    return (_jsxs(Layout, __assign({ meta: { title: 'Mailing List' } }, { children: [_jsx(ContentHeader, __assign({ heading: "Mailing List", border: 0 }, { children: _jsxs(React.Fragment, { children: [_jsx(Button, __assign({ colorScheme: "primary", size: "sm", onClick: toggleCreateMode }, { children: "New List" }), void 0), _jsx(MailingListForm, { action: "create", isOpen: createMode, onCancel: toggleCreateMode, onSuccess: function () {
                                toggleCreateMode();
                                refetch();
                            } }, void 0)] }, void 0) }), void 0), _jsxs(Card, { children: [_jsx(ContentLoader, { isLoading: isLoading }, void 0), !isLoading && (_jsxs(_Fragment, { children: [_jsx(CardBody, { children: _jsx(SearchInput, { value: filters.search || '', onChange: function (value) {
                                        return router.switchQuery(__assign(__assign({}, filters), { search: value }));
                                    }, placeholder: "Search mailing list", isDisabled: false }, void 0) }, void 0), _jsx(CardBody, __assign({ p: 0, pt: 2 }, { children: _jsx(MailingListTable, { mailList: filteredList, refetchList: refetch }, void 0) }), void 0)] }, void 0))] }, void 0)] }), void 0));
};
