var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack } from '@chakra-ui/react';
import { useQueryModel } from '@obtainly/http';
import { MailingListType, } from '@obtainly/models';
import { capitalizeString } from '@obtainly/utils';
import sortBy from 'lodash/sortBy';
import React, { useEffect, useMemo, useState } from 'react';
import { Autocomplete } from 'src/core/components';
var getOptions = function (props) {
    var data = props.data, label = props.label, isMulti = props.isMulti, disableSelectAll = props.disableSelectAll;
    if (!(data === null || data === void 0 ? void 0 : data.length)) {
        return [];
    }
    var options = [];
    if (!isMulti && !disableSelectAll) {
        options.push({
            value: '*',
            label: "All ".concat(capitalizeString(label)),
        });
    }
    var filtered = data.map(function (identifierObject) { return ({
        value: identifierObject._id,
        label: (identifierObject === null || identifierObject === void 0 ? void 0 : identifierObject.business_name) || (identifierObject === null || identifierObject === void 0 ? void 0 : identifierObject.name),
    }); });
    return __spreadArray(__spreadArray([], options, true), sortBy(filtered, function (identifierObject) { return identifierObject.label; }), true);
};
export var MailingListIdentifierAutocomplete = function (props) {
    var value = props.value, onChange = props.onChange, mailingListType = props.mailingListType, rest = __rest(props, ["value", "onChange", "mailingListType"]);
    var _a = useState(null), distributorId = _a[0], setDistributorId = _a[1];
    var _b = useState(null), merchantId = _b[0], setMerchantId = _b[1];
    var isDistroMerchantType = mailingListType === MailingListType.DistributorMerchant;
    var merchantData = useQueryModel("".concat(MailingListType.Merchant, ".read_all"), {}, {
        enabled: [
            MailingListType.Merchant,
            MailingListType.DistributorMerchant,
        ].includes(mailingListType),
    }).data;
    var distributorData = useQueryModel("".concat(MailingListType.Distributor, ".read_all"), {}, {
        enabled: [
            MailingListType.Distributor,
            MailingListType.DistributorMerchant,
        ].includes(mailingListType),
    }).data;
    var data = useMemo(function () {
        return mailingListType === MailingListType.Distributor
            ? distributorData || []
            : mailingListType === MailingListType.Merchant
                ? merchantData || []
                : [];
    }, [distributorData, mailingListType, merchantData]);
    var options = React.useMemo(function () {
        var _a;
        return _a = {},
            _a[MailingListType.Distributor] = getOptions({
                data: distributorData || [],
                label: 'Distributor',
                isMulti: !!props.isMulti && !isDistroMerchantType,
                disableSelectAll: !!props.isDisabled,
            }),
            _a[MailingListType.Merchant] = getOptions({
                data: merchantData || [],
                label: 'Merchant',
                isMulti: !!props.isMulti && !isDistroMerchantType,
                disableSelectAll: !!props.isDisabled,
            }),
            _a[MailingListType.DistributorMerchant] = [],
            _a[MailingListType.Any] = [],
            _a;
    }, [
        distributorData,
        props.isMulti,
        props.isDisabled,
        isDistroMerchantType,
        merchantData,
    ]);
    var combinedValueSetter = function (value, type) {
        if (type === 'distributor') {
            setDistributorId(value);
            if (merchantId) {
                onChange({ value: "".concat(value, "|").concat(merchantId) });
            }
        }
        if (type === 'merchant') {
            setMerchantId(value);
            if (distributorId) {
                onChange({ value: "".concat(distributorId, "|").concat(value) });
            }
        }
    };
    useEffect(function () {
        if (isDistroMerchantType) {
            var values = String(value).split('|');
            if (values.length > 1) {
                setDistributorId(values[0]);
                setMerchantId(values[1]);
                return;
            }
        }
        setDistributorId(null);
        setMerchantId(null);
    }, [value, isDistroMerchantType]);
    return isDistroMerchantType ? (_jsxs(Stack, __assign({ gap: "8px" }, { children: [_jsx(Autocomplete, __assign({ value: distributorId, options: options[MailingListType.Distributor] || [], placeholder: "Select Distributor", onChange: function (selected) {
                    return combinedValueSetter(selected.value, 'distributor');
                }, isActive: value && value.value !== '*' ? true : false, isDisabled: !options[MailingListType.Distributor].length }, rest), "".concat(mailingListType, "-distributor-autocomplete")), _jsx(Autocomplete, __assign({ value: merchantId, options: options[MailingListType.Merchant] || [], placeholder: "Select Merchant", onChange: function (selected) {
                    return combinedValueSetter(selected.value, 'merchant');
                }, isActive: value && value.value !== '*' ? true : false, isDisabled: !options[MailingListType.Merchant].length }, rest), "".concat(mailingListType, "-merchant-autocomplete"))] }), void 0)) : (_jsx(Autocomplete, __assign({ value: value, options: options[mailingListType] || [], placeholder: "Select ".concat(capitalizeString(mailingListType)), onChange: function (selected) {
            return onChange(selected, data === null || data === void 0 ? void 0 : data.find(function (identifierObject) { return identifierObject._id === selected.value; }));
        }, isActive: value && value.value !== '*' ? true : false, isDisabled: !options[mailingListType].length }, rest), mailingListType));
};
