export var Alert = {
    parts: ['container'],
    baseStyle: {
        borderRadius: 'md',
    },
    variants: {
        subtle: {
            borderRadius: 'md',
        },
    },
};
