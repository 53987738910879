import React from 'react';
export function useOnline(whenOnline, whenOffline) {
    var _a = React.useState(navigator.onLine), isOnline = _a[0], setIsOnline = _a[1];
    React.useEffect(function () {
        window.addEventListener('online', function () {
            setIsOnline(true);
            whenOnline();
        });
        window.addEventListener('offline', function () {
            whenOffline();
        });
    }, []); // eslint-disable-line
    return isOnline;
}
export default useOnline;
