var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Flex, Text } from '@chakra-ui/layout';
import { Stat, StatGroup, StatLabel, StatNumber } from '@chakra-ui/stat';
import React from 'react';
import { FiPercent, FiUsers } from 'react-icons/fi';
import { IoWalletOutline } from 'react-icons/io5';
import { Badge } from '@chakra-ui/react';
import { useQueryModel } from '@obtainly/http';
import { formatCurrency, formatUnixTime } from '@obtainly/utils';
import { TableMaker } from '../web-desktop';
import { Card, CardBody, CardHeader } from './Card';
import { ContentLoader } from './ContentLoader';
import { ListMaker } from './ListMaker';
export var DistributorPayments = function (_a) {
    var _b, _c, _d, _e;
    var setExportableData = _a.setExportableData, queryData = _a.queryData;
    var payload = React.useMemo(function () {
        return queryData;
    }, [queryData]);
    var _f = useQueryModel('distributor.statement', payload, {
        queryKey: [
            'distributor',
            'statement',
            payload._id,
            payload.month,
            payload.year,
        ],
        enabled: !!(payload === null || payload === void 0 ? void 0 : payload._id) || !!(payload === null || payload === void 0 ? void 0 : payload.month) || !!(payload === null || payload === void 0 ? void 0 : payload.year),
    }), data = _f.data, isLoading = _f.isLoading;
    var payments = React.useMemo(function () {
        var _a;
        return (((_a = data === null || data === void 0 ? void 0 : data.all) === null || _a === void 0 ? void 0 : _a.list.filter(function (_a) {
            var value = _a.value, tag = _a.tag;
            return value > 0 && tag === 'pay-in';
        })) || []);
    }, [data]);
    React.useEffect(function () {
        if (payments.length) {
            setExportableData && setExportableData(payments);
        }
    }, [payments, setExportableData]);
    var stats = React.useMemo(function () {
        return [
            {
                icon: _jsx(FiPercent, {}, void 0),
                iconColor: 'critical.600',
                label: 'Opening Balance',
                value: data === null || data === void 0 ? void 0 : data.opening_balance,
            },
            {
                icon: _jsx(FiPercent, {}, void 0),
                iconColor: 'success.600',
                label: 'Closing Balance',
                value: data === null || data === void 0 ? void 0 : data.closing_balance,
            },
            {
                icon: _jsx(FiUsers, {}, void 0),
                label: 'Customer Payment',
                value: data === null || data === void 0 ? void 0 : data.payments.total,
            },
            {
                icon: _jsx(IoWalletOutline, {}, void 0),
                label: 'Settlement',
                value: Math.abs(data === null || data === void 0 ? void 0 : data.processed.total),
            },
        ];
    }, [data]);
    var columns = React.useMemo(function () {
        return [
            { Header: '#', Cell: function (_a) {
                    var row = _a.row;
                    return Number(row.id) + 1;
                } },
            {
                Header: 'Customer',
                Cell: function (_a) {
                    var _b;
                    var cell = _a.cell, row = _a.row;
                    var description = cell.value;
                    try {
                        var json = JSON.parse(description);
                        description = ((_b = json === null || json === void 0 ? void 0 : json.merchant) === null || _b === void 0 ? void 0 : _b.business_name) || '-';
                    }
                    catch (error) {
                        description = '-';
                    }
                    return row.original.value < 0 ? 'Obtainly' : description;
                },
                accessor: 'description',
            },
            {
                Header: 'Payment',
                Cell: function (_a) {
                    var cell = _a.cell;
                    return formatCurrency(cell.value);
                },
                props: { isNumeric: true },
                accessor: 'value',
                id: 'payment',
            },
            {
                Header: 'Date',
                Cell: function (_a) {
                    var cell = _a.cell;
                    return formatUnixTime(cell.value, 'd MMM yyyy - hh:mm a');
                },
                accessor: 'created',
            },
            // {
            //   Header: 'Action',
            //   Cell: ({ row }: any) => {
            //     return (
            //       <>
            //         {row.original.tag !== 'transfer' && (
            //           <Link to={`/payments/${row.original._id}`}>
            //             <Button size="sm" colorScheme="blue">
            //               View
            //             </Button>
            //           </Link>
            //         )}
            //       </>
            //     )
            //   },
            // },
        ];
    }, []);
    var RenderMobileRow = function (item) {
        var _a, _b, _c, _d, _e, _f;
        var discription = ((_a = item === null || item === void 0 ? void 0 : item.item) === null || _a === void 0 ? void 0 : _a.description)
            ? JSON.parse((_b = item === null || item === void 0 ? void 0 : item.item) === null || _b === void 0 ? void 0 : _b.description)
            : '';
        return (_jsx(Card, __assign({ borderBottomWidth: "1px", borderBottomStyle: "solid", borderBottomColor: "grey.100", cursor: "pointer" }, { children: _jsxs(CardBody, { children: [_jsxs(Box, __assign({ justifyContent: "space-between" }, { children: [_jsxs(Flex, __assign({ mb: "1rem", align: "center", justifyContent: "space-between" }, { children: [_jsx(Text, { children: formatUnixTime((_c = item === null || item === void 0 ? void 0 : item.item) === null || _c === void 0 ? void 0 : _c.created, 'd MMM yyy, hh:mm') }, void 0), _jsx(Text, __assign({ fontSize: "1rem", fontWeight: "medium" }, { children: formatCurrency((_d = item === null || item === void 0 ? void 0 : item.item) === null || _d === void 0 ? void 0 : _d.value) }), void 0)] }), void 0), _jsx(Text, __assign({ fontWeight: "medium", mt: "4px" }, { children: discription === null || discription === void 0 ? void 0 : discription.narration }), void 0)] }), void 0), _jsx(Flex, __assign({ mt: "1rem", align: "center", justifyContent: "flex-end" }, { children: _jsx(Badge, { children: (_e = item === null || item === void 0 ? void 0 : item.item) === null || _e === void 0 ? void 0 : _e.tag }, void 0) }), void 0)] }, void 0) }), (_f = item === null || item === void 0 ? void 0 : item.item) === null || _f === void 0 ? void 0 : _f._id));
    };
    return (_jsxs(Card, { children: [_jsx(ContentLoader, { isLoading: isLoading, contentUnavailable: !((_c = (_b = data === null || data === void 0 ? void 0 : data.all) === null || _b === void 0 ? void 0 : _b.list) === null || _c === void 0 ? void 0 : _c.length), errorMessage: "No payments found" }, void 0), !isLoading && ((_e = (_d = data === null || data === void 0 ? void 0 : data.all) === null || _d === void 0 ? void 0 : _d.list) === null || _e === void 0 ? void 0 : _e.length) ? (_jsxs(_Fragment, { children: [_jsx(CardBody, { children: _jsx(StatGroup, __assign({ display: "grid", gridGap: [6, 2], gridTemplateColumns: ['1fr 1fr', '1fr 1fr 1fr 1fr'] }, { children: stats.map(function (stat) { return (_jsxs(Stat, { children: [_jsxs(StatNumber, { children: ["NGN ", formatCurrency(stat.value, { compact: true })] }, void 0), _jsx(StatLabel, __assign({ fontWeight: "regular", color: "gray.700" }, { children: _jsxs(Flex, __assign({ alignItems: "center", gridGap: 1 }, { children: [_jsx(Box, __assign({ color: stat.iconColor || 'gray.600' }, { children: stat.icon }), void 0), _jsx(Text, { children: stat.label }, void 0)] }), void 0) }), void 0)] }, stat.label)); }) }), void 0) }, void 0), _jsx(CardHeader, { border: 0, borderRadius: 0, heading: "Payments", borderTop: "1px solid", borderColor: "gray.100" }, void 0), _jsx(CardBody, __assign({ display: ['none', 'none', 'block'], p: 0, pt: 2 }, { children: _jsx(TableMaker, { data: payments, columns: columns, tableProps: { isFit: true } }, void 0) }), void 0), _jsx(CardBody, __assign({ display: ['block', 'block', 'none'], p: 0, pt: 2 }, { children: _jsx(ListMaker, { data: payments, component: _jsx(RenderMobileRow, {}, void 0) }, void 0) }), void 0)] }, void 0)) : null] }, void 0));
};
