var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Button, HStack } from '@chakra-ui/react';
import { Pagination as UnstyledPagination, PaginationItem as UnstyledPaginationItem, } from '@unstyled-react/pagination';
export var Pagination = function (props) {
    return (_jsx(UnstyledPagination, __assign({ as: HStack, spacing: "10px", pageSize: 10, justifyContent: "center", itemLabels: { first: false, last: false }, renderItem: function (item) { return (_jsx(UnstyledPaginationItem, __assign({}, item, { as: Button, size: "sm", variant: item.active ? 'solid' : 'outline', colorScheme: item.active ? 'gray' : 'gray', borderRadius: "md", transition: "none" }), void 0)); } }, props), void 0));
};
