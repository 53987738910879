var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Flex } from '@chakra-ui/layout';
import { Spinner } from '@chakra-ui/spinner';
import { ErrorBox } from '../web-shared';
export var ContentLoader = function (_a) {
    var isLoading = _a.isLoading, contentUnavailable = _a.contentUnavailable, errorTitle = _a.errorTitle, errorMessage = _a.errorMessage, props = __rest(_a, ["isLoading", "contentUnavailable", "errorTitle", "errorMessage"]);
    var showError = errorTitle || errorMessage;
    return (_jsxs(_Fragment, { children: [isLoading && (_jsx(Flex, __assign({ justifyContent: "center", alignItems: "center", h: 150 }, props, { children: _jsx(Spinner, { color: "gray.500" }, void 0) }), void 0)), !isLoading && contentUnavailable && showError ? (_jsx(Flex, __assign({ alignItems: "center", h: 150 }, props, { children: _jsx(ErrorBox, { title: errorTitle || '', message: errorMessage || '' }, void 0) }), void 0)) : null] }, void 0));
};
