var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Tabs, TabList, Tab } from '@chakra-ui/tabs';
export var PageTabs = function (_a) {
    var tabs = _a.tabs, props = __rest(_a, ["tabs"]);
    return (_jsx(Tabs, __assign({ pt: "10px", px: "20px", borderBottom: "1px solid", borderColor: "gray.100", overflow: "auto" }, props, { children: _jsx(TabList, __assign({ borderColor: "white" }, { children: tabs.map(function (tab, index) { return (_jsx(Tab, { children: typeof tab === 'string' ? tab : tab.label }, index)); }) }), void 0) }), void 0));
};
