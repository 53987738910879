import { ReconciliationReport, SettlementReport } from './pages';
export var administratorRoutes = [
    {
        id: 'administrator_settlement',
        path: '/settlement',
        component: SettlementReport,
        requiresAuth: true,
        exact: true,
    },
    {
        id: 'administrator_reconciliation',
        path: '/reconciliation',
        component: ReconciliationReport,
        requiresAuth: true,
        exact: true,
    },
];
export * from './components';
