var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, HStack } from '@chakra-ui/react';
import { useFilter, useQueryStringParse, useRouter } from '@obtainly/hooks';
import { useQueryModel } from '@obtainly/http';
import { Card, CardBody, ContentLoader, DateInput, SearchInput, } from '@obtainly/ui/web-shared';
import { basicSearch, formatCurrency, formatUnixTime, updateStatus, } from '@obtainly/utils';
import { fromUnixTime, getMonth, getUnixTime, getYear } from 'date-fns';
import React, { useCallback } from 'react';
import { ContentHeader, Exporter, Layout, } from 'src/core/components';
import { dateAtom, useAtom } from 'src/core/store';
import { FundingReportTable } from '../components/FundingReportTable';
export var FundingReport = function () {
    var router = useRouter('reports');
    var _a = useAtom(dateAtom), selectedDate = _a[0], setDate = _a[1];
    var filters = useQueryStringParse([], ['page']);
    var _b = useQueryModel('funding_request.report', {
        year: getYear(fromUnixTime(selectedDate)),
    }), data = _b.data, isLoading = _b.isLoading;
    var columns = [
        {
            label: 'Id',
            accessor: '_id',
        },
        {
            label: 'Ref',
            accessor: 'ref',
            selected: true,
        },
        {
            label: 'Customer',
            accessor: 'name_of_customer',
            selected: true,
        },
        {
            label: 'Customer Type',
            accessor: 'type_of_credit',
            selected: true,
        },
        {
            label: 'Month of Disbursement',
            accessor: 'disbursement_month',
            selected: true,
        },
        {
            label: 'Principal',
            accessor: 'principal',
            modifier: function (value) {
                return formatCurrency(Number(value));
            },
            selected: true,
        },
        {
            label: 'Interest rate',
            accessor: 'interest_rate',
            modifier: function (value) {
                return value.toFixed(2);
            },
            selected: true,
        },
        {
            label: 'Plan Duration (Days)',
            accessor: 'planned_duration_days',
            selected: true,
        },
        {
            label: 'Actual Duration (Days)',
            accessor: 'actual_duration_days',
            modifier: function (value) {
                return value ? value : '-';
            },
            selected: true,
        },
        {
            label: 'Interest Amount',
            accessor: 'interest',
            selected: true,
        },
        {
            label: 'Amount Due',
            accessor: 'amount_due',
            modifier: function (value) {
                return formatCurrency(Number(value));
            },
            selected: true,
        },
        {
            label: 'Amount Collected',
            accessor: 'amount_collected',
            modifier: function (value) {
                return formatCurrency(Number(value));
            },
            selected: true,
        },
        {
            label: 'Outstanding',
            accessor: 'amount_outstanding',
            modifier: function (value) {
                return formatCurrency(Number(value));
            },
            selected: true,
        },
        {
            label: 'Disbursement Date',
            accessor: 'release_date',
            modifier: function (value) {
                return formatUnixTime(value || '', 'dd MMM, yyyy');
            },
            selected: true,
        },
        {
            label: 'Due Date',
            accessor: 'due_date',
            modifier: function (value) {
                return formatUnixTime(value || '', 'dd MMM, yyyy');
            },
            selected: true,
        },
        {
            label: 'Pay Back Date',
            accessor: 'collection_date',
            modifier: function (value) {
                return formatUnixTime(value || '', 'dd MMM, yyyy');
            },
            selected: true,
        },
        {
            label: 'Pay Back Month',
            accessor: 'collection_month',
            selected: true,
        },
        {
            label: 'Status',
            accessor: 'status',
            modifier: function (value, cell) {
                return cell.meta.status !== 'paid'
                    ? cell.meta.status
                    : updateStatus(value);
            },
            selected: true,
        },
        {
            label: 'Meta',
            accessor: 'meta',
            modifier: function (value) {
                return JSON.stringify(value, null, 2);
            },
            selected: false,
        },
    ];
    var fundingRequest = React.useMemo(function () {
        return (data === null || data === void 0 ? void 0 : data.length)
            ? data.sort(function (a, b) {
                return Number(a.created_ms) < Number(b.created_ms) ? 1 : -1;
            })
            : [];
    }, [data]);
    var filteredFundingRequest = useFilter(fundingRequest, filters, {
        search: function (funding) {
            return basicSearch(funding.name_of_customer, filters.search) ||
                basicSearch(funding === null || funding === void 0 ? void 0 : funding.status, filters.search);
        },
    });
    var onBack = function () { return router.goBack(); };
    var handleDateChange = useCallback(function (date) {
        var year = getYear(fromUnixTime(date));
        setDate(function (prevDate) {
            var month = getMonth(fromUnixTime(prevDate)); // preserve selected month
            return getUnixTime(new Date(year, month));
        });
    }, [setDate]);
    return (_jsxs(Layout, __assign({ meta: { title: 'Credit Reports' } }, { children: [_jsx(ContentHeader, __assign({ onBack: onBack, heading: "Credit Reports", border: 0 }, { children: _jsxs(HStack, __assign({ gridColumn: "auto" }, { children: [_jsx(DateInput, { value: selectedDate, onChange: handleDateChange, showYearPicker: true }, void 0), _jsx(Box, { children: _jsx(Exporter, { filename: "obtainly-credits-report-".concat(formatUnixTime(getUnixTime(new Date()), 'dd-MM-yyyy')), data: filteredFundingRequest, columns: columns, isDisabled: !(filteredFundingRequest === null || filteredFundingRequest === void 0 ? void 0 : filteredFundingRequest.length) }, void 0) }, void 0)] }), void 0) }), void 0), _jsxs(Card, { children: [_jsx(CardBody, { children: _jsx(SearchInput, { w: "full", value: filters.search || '', onChange: function (value) {
                                return router.switchQuery(__assign(__assign({}, filters), { search: value }));
                            }, placeholder: "Search credit report", isDisabled: !(filteredFundingRequest === null || filteredFundingRequest === void 0 ? void 0 : filteredFundingRequest.length) }, void 0) }, void 0), _jsxs(CardBody, __assign({ p: 0, pt: 2 }, { children: [_jsx(ContentLoader, { isLoading: isLoading }, void 0), !isLoading && (_jsx(FundingReportTable, { fundingRequests: filteredFundingRequest || [] }, void 0))] }), void 0)] }, void 0)] }), void 0));
};
