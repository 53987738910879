var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Text, Box, Flex, SimpleGrid } from '@chakra-ui/layout';
import { Radio, RadioGroup as DefaultRadioGroup } from '@chakra-ui/react';
import { RiCheckboxCircleFill } from 'react-icons/ri';
export var RadioCardGroup = function (props) {
    var options = props.options, columns = props.columns, errorMessage = props.errorMessage, rest = __rest(props, ["options", "columns", "errorMessage"]);
    return (_jsx(_Fragment, { children: _jsxs(DefaultRadioGroup, __assign({ colorScheme: "primary" }, rest, { children: [_jsx(SimpleGrid, __assign({ columns: columns || 2, gridGap: 3 }, { children: options.map(function (_a) {
                        var label = _a.label, value = _a.value, description = _a.description;
                        return (_jsxs(Flex, __assign({ position: "relative", p: "12px", cursor: "pointer", borderRadius: "md", border: "1px solid", alignItems: "center", justifyContent: "space-between", bg: value === props.value ? 'primary.50' : 'white', borderColor: value === props.value ? 'primary.100' : 'gray.200', transition: "all 300ms", onClick: function () { return !props.isDisabled && props.onChange(value); } }, { children: [_jsxs(Flex, __assign({ flexDir: "column", justifyContent: "center" }, { children: [_jsx(Radio, __assign({ display: "none", value: value }, { children: label }), void 0), _jsx(Text, __assign({ fontWeight: "medium" }, { children: label }), void 0), !!description && (_jsx(Text, __assign({ fontSize: "sm", color: "gray.600" }, { children: description }), void 0))] }), void 0), _jsx(Box, __assign({ color: "primary.600", position: "absolute", top: 2, right: 2, flexShrink: 0 }, { children: value === props.value && _jsx(RiCheckboxCircleFill, {}, void 0) }), void 0)] }), value));
                    }) }), void 0), !!errorMessage && (_jsx(Text, __assign({ fontSize: "sm", color: "red.600", mt: 1 }, { children: errorMessage }), void 0))] }), void 0) }, void 0));
};
