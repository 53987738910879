var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { FormLabel, FormControl } from '@chakra-ui/form-control';
import { Input, InputGroup, InputLeftAddon, InputRightAddon, } from '@chakra-ui/input';
import { Select } from '@chakra-ui/select';
import { Textarea } from '@chakra-ui/textarea';
import { Autocomplete } from '../Autocomplete';
export var FormField = function (_a) {
    var as = _a.as, label = _a.label, prepend = _a.prepend, prependProps = _a.prependProps, append = _a.append, appendProps = _a.appendProps, children = _a.children, isRequired = _a.isRequired, props = __rest(_a, ["as", "label", "prepend", "prependProps", "append", "appendProps", "children", "isRequired"]);
    var Tag = as === 'textarea'
        ? Textarea
        : as === 'select'
            ? Select
            : as === 'autocomplete'
                ? Autocomplete
                : Input;
    return (_jsxs(_Fragment, { children: [!!label && (_jsx(FormControl, __assign({ mb: 0, isRequired: isRequired }, { children: _jsx(FormLabel, __assign({ mb: 1 }, { children: label }), void 0) }), void 0)), _jsxs(InputGroup, __assign({ size: props === null || props === void 0 ? void 0 : props.size }, { children: [!!prepend && (_jsx(InputLeftAddon, __assign({}, prependProps, { children: prepend }), void 0)), _jsx(Tag, __assign({}, props, { children: children }), void 0), !!append && (_jsx(InputRightAddon, __assign({}, appendProps, { children: append }), void 0))] }), void 0)] }, void 0));
};
