import { Connect } from './pages/Connect.page';
export var monoRoutes = [
    {
        id: 'mono-connect',
        path: '/monoconnect',
        component: Connect,
        requiresAuth: true,
        exact: true,
    },
];
export * from './components';
