var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Button } from '@chakra-ui/react';
import MonoConnect from '@mono.co/connect.js';
export var ConnectButton = function (_a) {
    var onSuccess = _a.onSuccess;
    var monoConnect = React.useMemo(function () {
        var monoInstance = new MonoConnect({
            onClose: function () { return console.log('Widget closed'); },
            onLoad: function () { return console.log('Widget loaded successfully'); },
            onSuccess: function (payload) {
                // const { code } = payload
                // console.log(`Linked successfully: ${code}`)
                onSuccess(payload);
            },
            key: process.env.REACT_APP_MONO_KEY,
        });
        monoInstance.setup();
        return monoInstance;
    }, [onSuccess]);
    var initiateMonoWidget = React.useCallback(function () {
        monoConnect === null || monoConnect === void 0 ? void 0 : monoConnect.open();
    }, [monoConnect]);
    return (_jsx(Button, __assign({ colorScheme: "primary", onClick: initiateMonoWidget }, { children: "Connect" }), void 0));
};
