var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Flex, Heading, useDisclosure } from '@chakra-ui/react';
import { useFilter, useQueryStringParse, useRouter } from '@obtainly/hooks';
import { useQueryModel } from '@obtainly/http';
import { PageTabs } from '@obtainly/ui/web-desktop';
import { Card, CardBody, ContentLoader, SearchInput, } from '@obtainly/ui/web-shared';
import { basicSearch } from '@obtainly/utils';
import { useAtom } from 'jotai';
import { orderBy } from 'lodash';
import React from 'react';
import { IoIosAdd } from 'react-icons/io';
import { Exporter, Importer } from 'src/core/components';
import { configAtom } from 'src/core/store';
import { MerchantForm, MerchantsTable } from 'src/merchant';
import { merchantsExportableColumns } from 'src/merchant/pages';
var PageTab;
(function (PageTab) {
    PageTab[PageTab["All"] = 0] = "All";
    PageTab[PageTab["Active"] = 1] = "Active";
    PageTab[PageTab["Inactive"] = 2] = "Inactive";
})(PageTab || (PageTab = {}));
var importColumns = function (distributorId, storeTypeId, locationId) {
    return [
        { id: 'business_name', label: 'Business Name' },
        { id: 'firstname', label: 'First Name' },
        { id: 'lastname', label: 'Last Name' },
        { id: 'phone', label: 'Phone Number' },
        { id: 'email', label: 'Email Address' },
        { id: 'address', label: 'Address' },
        { id: 'hash', label: 'Password', value: 'football' },
        { id: 'status', label: 'Status', value: 'active' },
        { id: 'store_type_id', label: 'Store Type ID', value: storeTypeId },
        { id: 'distributor_id', label: 'Distributor ID', value: distributorId },
        { id: 'location_id', label: 'Location ID', value: locationId },
    ];
};
export var DistributorMerchants = function (_a) {
    var distributor = _a.distributor;
    var _b = useAtom(configAtom)[0], defaultStoreTypeId = _b.defaultStoreTypeId, defaultLocationId = _b.defaultLocationId;
    var distributorId = (distributor === null || distributor === void 0 ? void 0 : distributor._id) || '';
    var _c = useQueryModel('merchant.read_by_distributor', {
        distributor_id: distributorId,
    }, { enabled: !!distributorId }), merchants = _c.data, isMerchantLoading = _c.isLoading, refetch = _c.refetch;
    var _d = useDisclosure(), isOpen = _d.isOpen, onOpen = _d.onOpen, onClose = _d.onClose;
    var router = useRouter(distributorId);
    var filters = useQueryStringParse([], ['page']);
    var filteredMerchants = useFilter(orderBy(merchants, 'business_name', 'asc'), filters, {
        search: function (merchant) {
            return basicSearch(merchant.business_name, filters.search) ||
                basicSearch(merchant.firstname, filters.search) ||
                basicSearch(merchant.lastname, filters.search) ||
                basicSearch(merchant.email, filters.search) ||
                basicSearch(merchant.phone, filters.search);
        },
    });
    var activeFilters = Object.keys(filters);
    var activeTab = React.useMemo(function () {
        if (activeFilters.length === 1) {
            return filters.status === 'active'
                ? PageTab.Active
                : filters.status === 'inactive'
                    ? PageTab.Inactive
                    : PageTab.All;
        }
        return PageTab.All;
    }, [filters, activeFilters]);
    var handleTabChange = function (index) {
        if (index === PageTab.Active) {
            router.switchQuery({ status: 'active' });
        }
        else if (index === PageTab.Inactive) {
            router.switchQuery({ status: 'inactive' });
        }
        else {
            router.go();
        }
    };
    return (_jsxs(Box, { children: [_jsxs(Flex, __assign({ alignItems: "center", justifyContent: "space-between" }, { children: [_jsx(Heading, __assign({ fontSize: "2xl", py: "0.75rem", border: 0 }, { children: "Customers" }), void 0), _jsxs(Flex, __assign({ align: "center", justify: "right", gap: "15px" }, { children: [_jsx(Button, __assign({ colorScheme: "primary", onClick: onOpen, leftIcon: _jsx(IoIosAdd, {}, void 0), size: "sm" }, { children: "Add Customer" }), void 0), _jsx(Importer, { model: "merchant", label: "Customer", columns: importColumns(distributorId, defaultStoreTypeId, defaultLocationId), onSuccess: function () { return refetch(); } }, void 0), _jsx(Exporter, { label: "Export", filename: "obtainly-".concat(distributor.name, "-merchants"), data: filteredMerchants, columns: merchantsExportableColumns(['distributors']), isDisabled: isMerchantLoading || !(filteredMerchants === null || filteredMerchants === void 0 ? void 0 : filteredMerchants.length) }, void 0)] }), void 0)] }), void 0), _jsxs(Card, { children: [_jsx(PageTabs, { index: activeTab, onChange: handleTabChange, tabs: [{ label: 'All' }, { label: 'Active' }, { label: 'Inactive' }] }, void 0), _jsx(CardBody, { children: _jsx(SearchInput, { value: filters.search || '', onChange: function (value) {
                                return router.switchQuery(__assign(__assign({}, filters), { search: value }));
                            }, placeholder: "Search customers", isDisabled: !(merchants === null || merchants === void 0 ? void 0 : merchants.length) }, void 0) }, void 0), _jsxs(CardBody, __assign({ pt: 0 }, { children: [_jsx(ContentLoader, { isLoading: isMerchantLoading }, void 0), !isMerchantLoading && (_jsx(MerchantsTable, { merchants: filteredMerchants || [] }, void 0))] }), void 0)] }, void 0), _jsx(MerchantForm, { supplierId: distributorId, isOpen: isOpen, onCancel: onClose }, void 0)] }, void 0));
};
