var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Flex, Grid, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useDisclosure, } from '@chakra-ui/react';
import { useToast } from '@obtainly/hooks';
import { useMutateModel, useQueryModel } from '@obtainly/http';
import { Autocomplete, ContentLoader } from '@obtainly/ui/web-shared';
import { useAtom } from 'jotai';
import { useMemo, useState } from 'react';
import { IoIosAdd } from 'react-icons/io';
import { useParams } from 'react-router';
import { Card, CardBody, ContentHeader, FormGroup, Layout, } from 'src/core/components';
import { useRouter } from 'src/core/hooks';
import { distributorsAtom } from 'src/core/store/distributor';
import { MerchantAutocomplete, MerchantDetails, SelectDistributor, } from '../components';
import { MerchantPageContent } from '../components/MerchantPageContent';
import { SecondAccount } from './SecondAccount';
export var Merchant = function () {
    var _a;
    var router = useRouter('merchant');
    var selectedDistributor = useAtom(distributorsAtom)[0].active;
    var _b = useDisclosure(), isOpen = _b.isOpen, onOpen = _b.onOpen, onClose = _b.onClose;
    var _c = useState(''), selectedSupplier = _c[0], setSelectedSupplier = _c[1];
    var merchantId = useParams().id;
    var _d = useQueryModel('merchant.read', {
        _id: merchantId,
        distributor_id: selectedDistributor === null || selectedDistributor === void 0 ? void 0 : selectedDistributor.distributor_id,
    }), merchants = _d.data, isLoading = _d.isLoading, refetch = _d.refetch;
    var allDistributors = useQueryModel('distributor.read_all', {}).data;
    var toast = useToast().toast;
    var distributor_merchant_create = useMutateModel('distributor_merchant.create');
    var onSuccess = function () {
        refetch();
        toast({ description: 'Updated', status: 'success' });
        onClose();
    };
    var onError = function (error) {
        toast({
            description: (error === null || error === void 0 ? void 0 : error.message) || 'An unknown error occurred',
            status: 'error',
        });
    };
    var distroOptions = useMemo(function () {
        return allDistributors === null || allDistributors === void 0 ? void 0 : allDistributors.map(function (distributor) {
            return {
                label: distributor.name,
                value: distributor._id,
            };
        });
    }, [allDistributors]);
    var merchant = useMemo(function () {
        return (merchants === null || merchants === void 0 ? void 0 : merchants[0]) || null;
    }, [merchants]);
    function handleSelectSupplier(value) {
        setSelectedSupplier(value);
    }
    function handleAddSupplier() {
        distributor_merchant_create.mutate({
            merchant_id: merchantId,
            distributor_id: selectedSupplier,
            location: '*',
            officer: '*',
        }, { onSuccess: onSuccess, onError: onError });
    }
    var onBack = function () { return router.goBack(); };
    return (_jsxs(Layout, __assign({ meta: { title: 'Customer' } }, { children: [_jsx(ContentHeader, { heading: "".concat((_a = merchant === null || merchant === void 0 ? void 0 : merchant.business_name) !== null && _a !== void 0 ? _a : (isLoading ? '...' : 'Customer')), onBack: onBack, border: 0 }, void 0), _jsxs(Grid, __assign({ gridTemplateColumns: "2fr 4fr", gap: "20px" }, { children: [_jsxs(Box, { children: [_jsx(Card, __assign({ mb: "20px" }, { children: _jsxs(CardBody, { children: [_jsx(FormGroup, __assign({ label: "Choose Customer" }, { children: _jsx(MerchantAutocomplete, { disableSelectAll: true, value: merchantId || null, onChange: function (_a) {
                                                    var value = _a.value;
                                                    router.switchParams({ id: value });
                                                } }, void 0) }), void 0), _jsx(FormGroup, __assign({ label: "Choose Supplier" }, { children: _jsx(SelectDistributor, { merchantId: merchantId, distributors: merchant === null || merchant === void 0 ? void 0 : merchant.distributors, distributorId: merchant === null || merchant === void 0 ? void 0 : merchant.distributor_id, merchant: merchant, refetch: refetch }, void 0) }), void 0), _jsx(Flex, __assign({ justifyContent: "flex-end" }, { children: _jsx(Button, __assign({ onClick: onOpen, leftIcon: _jsx(IoIosAdd, {}, void 0), size: "xs" }, { children: "Add Supplier" }), void 0) }), void 0)] }, void 0) }), void 0), !!merchant && _jsx(MerchantDetails, { merchant: merchant }, void 0), !!merchant && _jsx(SecondAccount, { merchant: merchant }, void 0)] }, void 0), _jsxs(Box, { children: [(isLoading || !merchant) && (_jsx(Card, { children: _jsx(CardBody, { children: _jsx(ContentLoader, { isLoading: isLoading, contentUnavailable: !merchant, errorMessage: 'Customer not found. Choose a customer' }, void 0) }, void 0) }, void 0)), !!merchant && (_jsx(MerchantPageContent, { merchant: merchant, refetch: refetch }, void 0))] }, void 0)] }), void 0), _jsxs(Modal, __assign({ isOpen: isOpen, onClose: onClose }, { children: [_jsx(ModalOverlay, {}, void 0), _jsxs(ModalContent, { children: [_jsx(ModalHeader, { children: "Add a Supplier" }, void 0), _jsx(ModalCloseButton, {}, void 0), _jsxs(ModalBody, __assign({ minH: "500px", h: "auto" }, { children: [_jsx(FormGroup, __assign({ label: "Select Supplier" }, { children: _jsx(Autocomplete, { value: selectedSupplier, onChange: function (_a) {
                                                var value = _a.value;
                                                return handleSelectSupplier(value);
                                            }, options: distroOptions || [], menuPlacement: "auto" }, void 0) }), void 0), _jsx(Flex, __assign({ justifyContent: "flex-end" }, { children: _jsx(Button, __assign({ onClick: handleAddSupplier, disabled: selectedSupplier === '', colorScheme: "primary", isLoading: distributor_merchant_create.isLoading }, { children: "Add Supplier" }), void 0) }), void 0)] }), void 0)] }, void 0)] }), void 0)] }), void 0));
};
