var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Flex } from '@chakra-ui/layout';
import { getUnixTime } from 'date-fns';
import { formatCurrency, formatUnixTime } from '@obtainly/utils';
import { ContentHeader, Exporter, Layout, MonthYearPicker, } from 'src/core/components';
import { dateAtom, useAtom } from 'src/core/store';
import { AdministratorDailySettlements } from '../components';
export var SettlementReport = function () {
    var _a = React.useState([]), exportableData = _a[0], setExportableData = _a[1];
    var columns = [
        {
            label: 'Date',
            accessor: 'timestamp',
            modifier: function (value) { return String(formatUnixTime(value, 'd MMM yyy')); },
            selected: true,
        },
        {
            label: 'Distributor',
            accessor: 'distributor.name',
            selected: true,
        },
        {
            label: 'Opening Unsettled',
            modifier: function (value, data) {
                return formatCurrency(value - data.value) || '0.00';
            },
            accessor: 'balance',
            selected: true,
        },
        {
            label: 'Customer Payment',
            modifier: function (value) { return formatCurrency(value) || '-'; },
            accessor: 'payments.total',
            selected: true,
        },
        {
            label: 'Settlement',
            modifier: function (value) { return formatCurrency(Math.abs(value)) || '-'; },
            accessor: 'processed.total',
            selected: true,
        },
        {
            label: 'Closing Unsettled',
            modifier: function (value) { return formatCurrency(value); },
            accessor: 'balance',
            selected: true,
        },
    ];
    var _b = useAtom(dateAtom), selectedDate = _b[0], setSelectedDate = _b[1];
    return (_jsxs(Layout, __assign({ meta: { title: 'Daily Settlements' } }, { children: [_jsx(ContentHeader, __assign({ heading: "Daily Settlements", border: 0 }, { children: _jsxs(Flex, __assign({ gridGap: 2 }, { children: [_jsx(MonthYearPicker, { value: selectedDate, onChange: setSelectedDate, isFullWidth: false }, void 0), _jsx(Exporter, { filename: "obtainly-settlement-report-".concat(getUnixTime(new Date())), data: exportableData, columns: columns, isDisabled: !exportableData.length }, void 0)] }), void 0) }), void 0), _jsx(AdministratorDailySettlements, { showHeader: false, setExportableData: function (data) { return setExportableData(data); } }, void 0)] }), void 0));
};
