var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Flex, Grid, GridItem, HStack, IconButton, Image, Text, } from '@chakra-ui/react';
import { getFileName, getFileType } from '@obtainly/utils';
import pluralize from 'pluralize';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { RiCloseFill } from 'react-icons/ri';
import DefaultImage from '../Image/img-placeholder.svg';
import PDFImage from '../Image/pdf-placeholder.svg';
export var FileSelectorPreview = function (_a) {
    var isOpen = _a.isOpen, onClose = _a.onClose, files = _a.files, 
    // errors,
    onSelectFiles = _a.onSelectFiles;
    var boxRef = useRef();
    var _b = useState(function () { return new Map(); }), mutableFiles = _b[0], setMutableFiles = _b[1];
    var _c = useState(''), selectedFileId = _c[0], setSelectedFileId = _c[1];
    var onSelect = function (id) {
        if (mutableFiles.has(id)) {
            setSelectedFileId(id);
        }
    };
    var onRemove = function (id) {
        if (mutableFiles.has(id)) {
            setSelectedFileId('');
            setMutableFiles(function (prev) {
                var newMappedFiles = new Map(prev);
                newMappedFiles.delete(id);
                return newMappedFiles;
            });
            if (mutableFiles.size === 1) {
                onClose();
            }
        }
    };
    var pushFiles = useCallback(function () {
        onSelectFiles === null || onSelectFiles === void 0 ? void 0 : onSelectFiles(mutableFiles);
    }, [mutableFiles, onSelectFiles]);
    useEffect(function () {
        if (files.size)
            setSelectedFileId(Array.from(files.keys())[0]);
        setMutableFiles(function () { return new Map(files); });
    }, [files]);
    return (_jsx(_Fragment, { children: _jsxs(Drawer, __assign({ onClose: onClose, isOpen: isOpen, size: "lg" }, { children: [_jsx(DrawerOverlay, {}, void 0), _jsxs(DrawerContent, { children: [_jsx(DrawerCloseButton, {}, void 0), _jsx(DrawerHeader, { children: "Select files" }, void 0), _jsx(DrawerBody, __assign({ ref: boxRef }, { children: _jsxs(Grid, __assign({ templateColumns: "1fr", templateRows: "auto 1fr auto", justifyItems: "stretch", maxW: "100%", height: "full", maxH: "100%" }, { children: [_jsx(GridItem, { children: _jsxs(Flex, __assign({ pb: "10px", align: "center", justify: "space-between" }, { children: [_jsxs(Text, __assign({ fontSize: "md", fontWeight: "medium" }, { children: [mutableFiles.size, " ", pluralize('file', mutableFiles.size), ' ', "selected"] }), void 0), _jsx(Button, __assign({ size: "sm", colorScheme: "success", onClick: pushFiles }, { children: "Upload" }), void 0)] }), void 0) }, void 0), _jsxs(GridItem, __assign({ position: "relative", overflow: "auto", borderRadius: "10px", boxShadow: "md" }, { children: [_jsx(FileThumbnail, { file: mutableFiles.get(selectedFileId), size: "full", objectFit: "contain", borderRadius: "0px" }, selectedFileId), _jsx(Box, { children: !!mutableFiles.get(selectedFileId) && (_jsx(Text, __assign({ position: "absolute", p: "10px", left: 0, right: 0, bottom: 0, color: "white", bgColor: "rgba(0,0,0,0.3)" }, { children: getFileName(mutableFiles.get(selectedFileId)) }), void 0)) }, void 0)] }), void 0), _jsx(GridItem, __assign({ overflowX: "auto", pt: "20px" }, { children: _jsx(FileStrip, { files: mutableFiles, onSelect: onSelect, onRemove: onRemove, selectedMapId: selectedFileId }, void 0) }), void 0)] }), void 0) }), void 0)] }, void 0)] }), void 0) }, void 0));
};
var FileStrip = function (_a) {
    var _b;
    var files = _a.files, onSelect = _a.onSelect, onRemove = _a.onRemove, selectedMapId = _a.selectedMapId;
    return (_jsx(Box, __assign({ overflowX: "auto", width: "full" }, { children: _jsx(HStack, __assign({ align: "center", gap: "4px" }, { children: (_b = Array.from(files.entries())) === null || _b === void 0 ? void 0 : _b.map(function (_a) {
                var id = _a[0], file = _a[1];
                return (_jsxs(Box, __assign({ onClick: function () { return onSelect(id); }, borderWidth: "4px", borderColor: selectedMapId === id ? 'primary.100' : 'gray.50', position: "relative", borderRadius: "10px" }, { children: [_jsx(FileThumbnail, { file: file, minW: "120px", height: "120px", objectFit: "cover", borderRadius: "6px" }, void 0), _jsx(IconButton, __assign({ "aria-label": "remove-file", icon: _jsx(RiCloseFill, {}, void 0), onClick: function () { return onRemove(id); }, size: "sm", position: "absolute", top: "4px", right: "4px", boxShadow: "sm", colorScheme: "blackAlpha" }, { children: "Remove" }), void 0)] }), id));
            }) }), void 0) }), void 0));
};
var FileThumbnail = function (_a) {
    var file = _a.file, size = _a.size, rest = __rest(_a, ["file", "size"]);
    var _b = useState(), image = _b[0], setImage = _b[1];
    useEffect(function () {
        // read image
        var reader = new FileReader();
        if (file) {
            reader.onloadend = function () { return __awaiter(void 0, void 0, void 0, function () {
                var _a;
                return __generator(this, function (_b) {
                    setImage((_a = reader.result) === null || _a === void 0 ? void 0 : _a.toString());
                    return [2 /*return*/];
                });
            }); };
            reader.readAsDataURL(file);
        }
        else {
            setImage(DefaultImage);
        }
        return function () { return reader.abort(); };
    }, [file]);
    return (_jsx(Image, __assign({ boxSize: size !== null && size !== void 0 ? size : '120px', objectFit: "cover", src: image, fallbackSrc: file && getFileType(file) === 'pdf' ? PDFImage : DefaultImage, borderRadius: "10px" }, rest), void 0));
};
