var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Table as ChakraTable, } from '@chakra-ui/table';
import { Box } from '@chakra-ui/layout';
export var Table = function (_a) {
    var tableRef = _a.tableRef, isFit = _a.isFit, isMini = _a.isMini, hasBorders = _a.hasBorders, children = _a.children, props = __rest(_a, ["tableRef", "isFit", "isMini", "hasBorders", "children"]);
    return (_jsx(Box, __assign({ ref: tableRef, overflowX: "scroll" }, { children: _jsx(ChakraTable, __assign({ css: {
                tableLayout: isFit ? 'auto' : 'unset',
                width: isFit ? 'auto' : 'inherit',
                fontSize: isMini ? '13px' : 'inherit',
            } }, props, { children: _jsx(TableContext.Provider, __assign({ value: { isFit: isFit, isMini: isMini, hasBorders: hasBorders } }, { children: children }), void 0) }), void 0) }), void 0));
};
export var TableContext = React.createContext({});
