var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure, VStack, } from '@chakra-ui/react';
import { useToast } from '@obtainly/hooks';
import { useMutateModel } from '@obtainly/http';
import { FormGroup, FormikField, FormikRadioGroup, } from '@obtainly/ui/web-shared/FormField';
import { Form, Formik } from 'formik';
import { IoIosAdd } from 'react-icons/io';
import * as Yup from 'yup';
var supplierFormSchema = Yup.object({
    status: Yup.string().required('Status is required'),
    name: Yup.string().required('Name is required'),
});
export var SupplierForm = function (props) {
    var toast = useToast().toast;
    var action = props.action, distributor = props.distributor;
    var _a = useDisclosure(), isOpen = _a.isOpen, onOpen = _a.onOpen, onClose = _a.onClose;
    var createSupplier = useMutateModel('distributor.create');
    var updateSupplier = useMutateModel('distributor.update');
    var initialValues = {
        status: (distributor === null || distributor === void 0 ? void 0 : distributor.status) || 'active',
        name: (distributor === null || distributor === void 0 ? void 0 : distributor.name) || '',
    };
    var onSubmit = function (values) {
        if (action === 'create') {
            createSupplier.mutate(values, {
                onSuccess: function () {
                    toast({
                        title: 'Supplier created',
                        status: 'success',
                        duration: 5000,
                        isClosable: true,
                    });
                    props.refetch();
                    onClose();
                },
                onError: function (error) {
                    toast({
                        title: 'Error creating supplier',
                        description: error.message,
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                    });
                },
            });
        }
        else {
            updateSupplier.mutate(__assign({ _id: distributor === null || distributor === void 0 ? void 0 : distributor._id }, values), {
                onSuccess: function () {
                    toast({
                        title: 'Supplier updated',
                        status: 'success',
                        duration: 5000,
                        isClosable: true,
                    });
                    props.refetch();
                    onClose();
                },
                onError: function (error) {
                    toast({
                        title: 'Error updating supplier',
                        description: error.message,
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                    });
                },
            });
        }
    };
    return (_jsxs(_Fragment, { children: [action === 'create' ? (_jsx(Button, __assign({ leftIcon: _jsx(IoIosAdd, {}, void 0), colorScheme: "primary", size: "sm", onClick: onOpen }, { children: "Add Supplier" }), void 0)) : (_jsx(Button, __assign({ variant: "outline", onClick: onOpen, size: "sm" }, { children: "Edit" }), void 0)), _jsxs(Modal, __assign({ isOpen: isOpen, onClose: onClose }, { children: [_jsx(ModalOverlay, {}, void 0), _jsx(ModalContent, { children: _jsx(Formik, __assign({ initialValues: initialValues, validationSchema: supplierFormSchema, onSubmit: onSubmit, enableReinitialize: true, validateOnMount: true }, { children: function (_a) {
                                var formik = __rest(_a, []);
                                return (_jsxs(Form, __assign({ onSubmit: formik.handleSubmit }, { children: [_jsxs(ModalHeader, { children: [action === 'create' ? 'Add' : 'Update', " Supplier"] }, void 0), _jsx(ModalCloseButton, {}, void 0), _jsx(ModalBody, { children: _jsxs(VStack, __assign({ spacing: 5, align: "stretch" }, { children: [_jsx(FormGroup, __assign({ label: "Status" }, { children: _jsx(FormikRadioGroup, { name: "status", options: [
                                                                { value: 'inactive', label: 'Inactive' },
                                                                { value: 'active', label: 'Active' },
                                                            ] }, void 0) }), void 0), _jsx(FormGroup, __assign({ label: "Name" }, { children: _jsx(FormikField, { size: "sm", name: "name", placeholder: "name" }, void 0) }), void 0)] }), void 0) }, void 0), _jsxs(ModalFooter, { children: [_jsx(Button, __assign({ size: "sm", variant: "outline", mr: 3, onClick: onClose }, { children: "Cancel" }), void 0), _jsxs(Button, __assign({ size: "sm", type: "submit", colorScheme: "primary", isLoading: createSupplier.isLoading || updateSupplier.isLoading, isDisabled: createSupplier.isLoading ||
                                                        updateSupplier.isLoading ||
                                                        !formik.dirty ||
                                                        !formik.isValid }, { children: [action === 'create' ? 'Add' : 'Update', " List"] }), void 0)] }, void 0)] }), void 0));
                            } }), void 0) }, void 0)] }), void 0)] }, void 0));
};
