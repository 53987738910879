var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Error404 } from 'src/core/pages/404';
import { administratorRoutes } from 'src/administrator';
import { configRoutes } from 'src/config';
import { dashboardRoutes } from 'src/dashboard';
import { fundingRequestRoutes } from 'src/funding-request';
import { inflowRoutes } from 'src/inflow';
import { mailingListRoutes } from 'src/mailing_list';
import { merchantRoutes } from 'src/merchant';
import { monoRoutes } from 'src/mono';
import { notificationRoutes } from 'src/notification';
import { settlementRoutes } from 'src/settlement';
import { supplierRoutes } from 'src/supplier';
import { userRoutes } from 'src/user';
var resolvedRoutes = [];
var resolveRouteGroup = function (routeGroup) {
    if (routeGroup === null || routeGroup === void 0 ? void 0 : routeGroup.length) {
        resolvedRoutes = __spreadArray(__spreadArray([], resolvedRoutes, true), routeGroup, true);
    }
};
resolveRouteGroup(administratorRoutes);
resolveRouteGroup(dashboardRoutes);
resolveRouteGroup(userRoutes);
resolveRouteGroup(mailingListRoutes);
resolveRouteGroup(monoRoutes);
resolveRouteGroup(notificationRoutes);
resolveRouteGroup(settlementRoutes);
resolveRouteGroup(configRoutes);
resolveRouteGroup(fundingRequestRoutes);
resolveRouteGroup(merchantRoutes);
resolveRouteGroup(supplierRoutes);
resolveRouteGroup(inflowRoutes);
export var routes = __spreadArray(__spreadArray([], resolvedRoutes, true), [
    {
        id: 'error_404',
        path: '*',
        component: Error404,
    },
], false);
