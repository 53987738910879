var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Badge, Box, Flex, SimpleGrid, Text, VStack } from '@chakra-ui/react';
import { useQueryModel } from '@obtainly/http';
import { FundingRequestStatus } from '@obtainly/models';
import { FundingRequestStatusBadge, FundingRequestTypeBadge, } from '@obtainly/ui/web-shared';
import { formatCurrency, formatUnixTime, getFundingRequestPayback, } from '@obtainly/utils';
import React, { useMemo } from 'react';
export var FundingRequestDetails = function (_a) {
    var _b;
    var fundingRequest = _a.fundingRequest;
    var payback = getFundingRequestPayback((fundingRequest === null || fundingRequest === void 0 ? void 0 : fundingRequest.amount) || 0, fundingRequest === null || fundingRequest === void 0 ? void 0 : fundingRequest.interest).payback;
    var amountDue = (_b = fundingRequest === null || fundingRequest === void 0 ? void 0 : fundingRequest.ledger) === null || _b === void 0 ? void 0 : _b.balance;
    var _c = useQueryModel('funding_request.read_statement', { _id: fundingRequest === null || fundingRequest === void 0 ? void 0 : fundingRequest._id }, { enabled: !!(fundingRequest === null || fundingRequest === void 0 ? void 0 : fundingRequest._id) }), statement = _c.data, isLoadingFundingStatement = _c.isLoading;
    var amountPaid = useMemo(function () {
        var _a = (statement === null || statement === void 0 ? void 0 : statement.paid) || {}, principal = _a.principal, interest = _a.interest, penalty = _a.penalty;
        return (principal || 0) + (interest || 0) + (penalty || 0);
    }, [statement]);
    var details = useMemo(function () {
        var output = [
            {
                label: 'Pay back',
                value: formatCurrency(payback, { showCurrency: true }),
            },
            {
                label: 'Interest',
                value: "".concat(fundingRequest === null || fundingRequest === void 0 ? void 0 : fundingRequest.interest, "%"),
            },
        ];
        if ((amountDue && (fundingRequest === null || fundingRequest === void 0 ? void 0 : fundingRequest.status) !== FundingRequestStatus.Approved) ||
            (amountDue === 0 && (fundingRequest === null || fundingRequest === void 0 ? void 0 : fundingRequest.status) === FundingRequestStatus.Paid)) {
            output.push({
                label: 'Amount Due',
                value: formatCurrency(Math.abs(amountDue), { showCurrency: true }),
            });
        }
        if ((fundingRequest === null || fundingRequest === void 0 ? void 0 : fundingRequest.status) === FundingRequestStatus.Paid) {
            output.push({
                label: 'Amount Paid',
                value: isLoadingFundingStatement
                    ? '...'
                    : formatCurrency(amountPaid, { showCurrency: true }),
            });
        }
        if (fundingRequest === null || fundingRequest === void 0 ? void 0 : fundingRequest.expired_at) {
            output.push({
                label: 'Due date',
                value: formatUnixTime(fundingRequest.expired_at, 'dd MMM, yyyy') || '-',
            });
        }
        return output;
    }, [
        amountDue,
        payback,
        fundingRequest,
        amountPaid,
        isLoadingFundingStatement,
    ]);
    return (_jsx(_Fragment, { children: !!fundingRequest && (_jsxs(VStack, __assign({ gap: "10px", align: "stretch" }, { children: [_jsxs(Flex, __assign({ justifyContent: "space-between" }, { children: [_jsxs(Flex, __assign({ alignItems: "center", gridGap: 2, mr: 4 }, { children: [_jsxs(Text, __assign({ display: "block", fontWeight: "medium", isTruncated: true }, { children: ["#", fundingRequest === null || fundingRequest === void 0 ? void 0 : fundingRequest.ref] }), void 0), fundingRequest.is_new && (_jsx(Badge, __assign({ colorScheme: "success" }, { children: "New" }), void 0))] }), void 0), _jsx(Text, __assign({ fontWeight: "semibold" }, { children: formatCurrency((fundingRequest === null || fundingRequest === void 0 ? void 0 : fundingRequest.amount) || 0, {
                                showCurrency: true,
                            }) }), void 0)] }), void 0), _jsx(SimpleGrid, __assign({ templateColumns: "1fr 1fr", gap: "10px" }, { children: details.map(function (detail, index) { return (_jsxs(Box, { children: [_jsx(Text, __assign({ color: "gray.700", fontSize: "sm", fontWeight: "medium" }, { children: detail.label }), void 0), _jsx(Text, __assign({ fontSize: "md", fontWeight: "medium" }, { children: detail.value }), void 0)] }, index)); }) }), void 0), _jsxs(Flex, __assign({ my: 1, gridGap: 3 }, { children: [_jsx(Text, __assign({ fontFamily: "mono", fontSize: "sm" }, { children: formatUnixTime(fundingRequest === null || fundingRequest === void 0 ? void 0 : fundingRequest.created, 'MMM d') }), void 0), _jsx(FundingRequestStatusBadge, { status: fundingRequest === null || fundingRequest === void 0 ? void 0 : fundingRequest.status, funding: fundingRequest }, void 0), !!fundingRequest.type && (_jsx(FundingRequestTypeBadge, { type: fundingRequest === null || fundingRequest === void 0 ? void 0 : fundingRequest.type }, void 0))] }), void 0)] }), void 0)) }, void 0));
};
