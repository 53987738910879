var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Flex, Text, Tooltip } from '@chakra-ui/react';
import { fromUnixTime, getUnixTime, set } from 'date-fns';
import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FiCalendar } from 'react-icons/fi';
import 'src/core/assets/styles/plugins/react-datepicker.css';
export var DateInput = function (props) {
    var value = props.value, onChange = props.onChange, showTimeSelect = props.showTimeSelect, rest = __rest(props, ["value", "onChange", "showTimeSelect"]);
    var date = value ? fromUnixTime(value) : null;
    return (_jsx(Flex, __assign({ w: "100%" }, { children: _jsx(DatePicker, __assign({ selected: date, maxDate: new Date(), onChange: function (newDate) {
                onChange(getUnixTime(newDate));
            }, placeholderText: "Date", dateFormat: props.showYearPicker
                ? 'yyyy'
                : showTimeSelect
                    ? 'dd/MM/yyyy h:mm aa'
                    : 'dd/MM/yyyy', customInput: _jsx(CustomInput, { date: date, size: props.size, isDisabled: props.isDisabled }, void 0) }, rest), void 0) }), void 0));
};
export var MonthYearPicker = function (props) {
    var value = props.value, onChange = props.onChange, isFullWidth = props.isFullWidth, rest = __rest(props, ["value", "onChange", "isFullWidth"]);
    var date = value ? fromUnixTime(value) : null;
    return (_jsx(Flex, __assign({ w: "auto" }, { children: _jsx(DatePicker, __assign({ selected: fromUnixTime(value), onChange: function (newDate) {
                onChange(getUnixTime(newDate));
            }, placeholderText: "Month Year", showMonthYearPicker: true, dateFormat: 'MMMM yyyy', customInput: _jsx(CustomInput, { date: date, size: props.size, isDisabled: props.isDisabled, isFullWidth: isFullWidth, isRounded: false, left: true }, void 0) }, rest), void 0) }), void 0));
};
export var DateRangeInput = function (props) {
    var start = props.start, end = props.end, onChange = props.onChange, rest = __rest(props, ["start", "end", "onChange"]);
    var startDate = start ? fromUnixTime(start) : null;
    var endDate = end ? fromUnixTime(end) : null;
    var formatStartDate = function (date) {
        return getUnixTime(set(date, { hours: 0, minutes: 0, seconds: 0 }));
    };
    var formatEndDate = function (date) {
        return getUnixTime(set(date, { hours: 23, minutes: 59, seconds: 0 }));
    };
    return (_jsxs(Flex, __assign({ w: "100%", position: "relative", alignItems: "center", gridGap: 2 }, { children: [_jsx(DatePicker, __assign({ selected: startDate, onChange: function (newStartDate) {
                    var newEndDate = endDate && newStartDate < endDate ? formatEndDate(endDate) : null;
                    onChange(getUnixTime(newStartDate), newEndDate);
                }, startDate: startDate, endDate: endDate, maxDate: new Date(), placeholderText: "Start date", dateFormat: props.showTimeSelect ? 'dd/MM/yyyy h:mm aa' : 'dd/MM/yyyy', customInput: _jsx(CustomInput, { size: props.size, date: startDate, isDisabled: props.isDisabled, left: true }, void 0) }, rest), void 0), _jsx(Text, __assign({ color: "gray.600" }, { children: "to" }), void 0), _jsx(DatePicker, __assign({ selected: endDate, onChange: function (newEndDate) {
                    onChange(startDate ? formatStartDate(startDate) : null, formatEndDate(newEndDate));
                }, startDate: startDate, endDate: endDate, minDate: startDate, maxDate: new Date(), isDisabled: !startDate, placeholderText: "End date", dateFormat: props.showTimeSelect ? 'dd/MM/yyyy h:mm aa' : 'dd/MM/yyyy', customInput: _jsx(CustomInput, { size: props.size, date: endDate, isDisabled: props.isDisabled || !startDate, right: true }, void 0) }, rest), void 0)] }), void 0));
};
var CustomInput = React.forwardRef(function (_a, ref) {
    var value = _a.value, onClick = _a.onClick, props = __rest(_a, ["value", "onClick"]);
    return (_jsx(Button, __assign({ ref: ref, px: props.isRounded ? '6px' : undefined, size: props.size, variant: "outline", borderRadius: props.isRounded ? 'md' : undefined, isDisabled: props.isDisabled, leftIcon: _jsx(FiCalendar, {}, void 0), _hover: { borderColor: 'gray.300' }, onClick: !props.isDisabled ? onClick : null, isFullWidth: props.isFullWidth }, { children: _jsx(Tooltip, __assign({ label: value || (props === null || props === void 0 ? void 0 : props.placeholder), "aria-label": "Date tooltip" }, { children: _jsx(Text, __assign({ color: value ? 'black' : 'gray.500', lineHeight: "20px", overflow: "scroll", noOfLines: 1 }, { children: value || (props === null || props === void 0 ? void 0 : props.placeholder) }), void 0) }), void 0) }), void 0));
});
CustomInput.displayName = 'CustomInput';
CustomInput.defaultProps = {
    size: 'sm',
    isFullWidth: true,
    isRounded: true,
};
