var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Button } from '@chakra-ui/react';
import { useToast } from '@obtainly/hooks';
import { useMutateModel } from '@obtainly/http';
import { useCallback } from 'react';
export function ExecuteInflow(_a) {
    var id = _a.id;
    var toast = useToast().toast;
    var executeInflow = useMutateModel('inflow.execute');
    var handleInflowRetry = useCallback(function () {
        executeInflow.mutate({
            id: id,
            commit: true,
        }, {
            onSuccess: function () {
                toast({
                    status: 'success',
                    description: 'Inflow executed successfully',
                });
            },
            onError: function (_a) {
                var message = _a.message;
                toast({ status: 'error', description: message });
            },
        });
    }, [id, executeInflow, toast]);
    return (_jsx(Button, __assign({ size: "sm", colorScheme: "orange", variant: "outline", onClick: function () { return handleInflowRetry(); }, isLoading: executeInflow.isLoading, isDisabled: executeInflow.isLoading }, { children: "Execute" }), void 0));
}
