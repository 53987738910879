var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import axios from 'axios';
import { useQuery, useMutation, useQueries, } from 'react-query';
import { buildQueryString } from '@obtainly/utils';
var TOKEN_STORAGE_KEY = 'token';
export var http = axios.create();
export function getSessionToken() {
    var token;
    try {
        token = JSON.parse(localStorage.getItem(TOKEN_STORAGE_KEY));
    }
    catch (error) {
        throw new Error('Session token not found');
    }
    return token;
}
export function setSessionToken(token) {
    if (token === void 0) { token = false; }
    return new Promise(function (resolve) {
        if (!token) {
            delete http.defaults.headers.common.Authorization;
            resolve();
            return;
        }
        localStorage.setItem(TOKEN_STORAGE_KEY, JSON.stringify(token));
        http.defaults.headers['user_session_token'] = token;
        resolve();
    });
}
export function unsetSessionToken() {
    setSessionToken(null);
    localStorage.removeItem(TOKEN_STORAGE_KEY);
}
function makeRequest(options) {
    var baseUrl = options.baseUrl, method = options.method, model = options.model, action = options.action, payload = options.payload;
    var params = buildQueryString(options === null || options === void 0 ? void 0 : options.params);
    var requestPath = "".concat(model, "/").concat(action).concat(params);
    if (baseUrl) {
        requestPath = "".concat(baseUrl, "/").concat(requestPath);
    }
    else {
        requestPath = "".concat(process.env.REACT_APP_API_URL, "/").concat(requestPath);
    }
    return http[method || 'post']("".concat(requestPath), payload)
        .then(function (_a) {
        var data = _a.data;
        return data.data || data;
    })
        .catch(function (error) {
        if (error.response) {
            throw new Error(error.response.data.message || error.message);
        }
        else if (error.request) {
            // may need to specify message from error.request
            throw new Error(error.message);
        }
        else if (error.message) {
            throw new Error(error.message);
        }
        else {
            throw new Error(error.message);
        }
    });
}
/**
 * Parse request string and merge with request object
 *
 * @param requestString - ex. get.order.read_by_id
 * @param rest - Request object to be merged
 * @returns
 */
export function parseRequestString(requestString, rest) {
    var urlParts = requestString.split('$');
    var _a = urlParts.length > 1
        ? [urlParts[0], urlParts[1].split('.')]
        : [null, urlParts[0].split('.')], baseUrl = _a[0], requestParts = _a[1];
    var requestObject = { method: 'post' };
    if (baseUrl) {
        requestObject.baseUrl = baseUrl;
    }
    if ((requestParts === null || requestParts === void 0 ? void 0 : requestParts.length) === 3) {
        requestObject = __assign(__assign({}, requestObject), { method: requestParts[0], model: requestParts[1], action: requestParts[2] });
    }
    else if ((requestParts === null || requestParts === void 0 ? void 0 : requestParts.length) === 2) {
        requestObject = __assign(__assign({}, requestObject), { model: requestParts[0], action: requestParts[1] });
    }
    else {
        throw new Error("Request string \"".concat(requestString, " could not be parsed.\""));
    }
    return __assign(__assign({}, requestObject), rest);
}
export function useQueryMaker(reqOptions, queryOptions) {
    var action = reqOptions.action, method = reqOptions.method, model = reqOptions.model;
    var defaultQueryKey = "".concat(method, "_").concat(model, "_").concat(action);
    var queryKey = (queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) || defaultQueryKey;
    var queryFn = function () {
        return makeRequest(reqOptions);
    };
    var query = useQuery(queryKey, queryFn, __assign({ retry: false, refetchOnWindowFocus: false }, queryOptions));
    return query;
}
export function useQueriesMaker(requestsData, queryOptions) {
    var queries = [];
    // iterate through requests
    requestsData.forEach(function (reqData) {
        var reqOption = parseRequestString(reqData.requestString, {
            payload: reqData.payload,
        });
        var action = reqOption.action, method = reqOption.method, model = reqOption.model;
        var defaultQueryKey = "".concat(method, "_").concat(model, "_").concat(action);
        var queryKey = reqData.payload
            ? "".concat(model, "_").concat(action, "_").concat(Object.values(reqData.payload).join('_'))
            : defaultQueryKey;
        var queryFn = function () {
            return makeRequest(reqOption);
        };
        queries.push(__assign(__assign({ queryKey: queryKey, queryFn: queryFn, enabled: !!reqOption.payload }, queryOptions), reqData.options));
    });
    var queriesResult = useQueries(queries);
    return queriesResult;
}
export function useMutationMaker(reqOptions, mutationOptions) {
    var mutationFn = function (payload) {
        return makeRequest(__assign(__assign({}, reqOptions), { payload: payload }));
    };
    var mutation = useMutation(mutationFn, mutationOptions);
    return mutation;
}
export function useQueryModel(requestString, payload, options) {
    var request = parseRequestString(requestString, { payload: payload });
    return useQueryMaker(request, __assign({ queryKey: payload
            ? "".concat(request.model, "_").concat(request.action).concat(Object.keys(payload).length ? '_' : '').concat(Object.values(payload).join('_'))
            : [], enabled: !!payload }, options));
}
export function useQueryModels(requestData, options) {
    return useQueriesMaker(requestData, __assign({}, options));
}
export function useMutateModel(requestString) {
    return useMutationMaker(parseRequestString(requestString));
}
