var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { IconButton } from '@chakra-ui/button';
import { Flex, Box, Heading } from '@chakra-ui/layout';
import { useTheme } from '@chakra-ui/system';
import { FiArrowLeft } from 'react-icons/fi';
export var ContentHeader = function (props) {
    var _a, _b;
    var heading = props.heading, onBack = props.onBack, children = props.children, rest = __rest(props, ["heading", "onBack", "children"]);
    var sizes = useTheme().sizes;
    return (_jsxs(Box, __assign({ position: "sticky", top: sizes.layout.stickyHeaderTop, zIndex: 30 }, { children: [_jsx(Flex, __assign({ zIndex: 30, px: ['20px'], bg: "rgba(247,248,249,0.8)", backdropFilter: "blur(4px)", alignItems: "center", justifyContent: "space-between", h: sizes.layout.contentHeader }, rest, { children: _jsxs(Flex, __assign({ alignItems: "center", gridGap: [1, 2] }, { children: [onBack && (_jsx(IconButton, { w: "34px", size: "sm", bg: "white", "aria-label": "Back", borderRadius: 99999, boxShadow: "1px 1px 4px 0px rgba(23, 28, 52, 0.05)", icon: _jsx(FiArrowLeft, { size: 16 }, void 0), _hover: {
                                transform: 'translateX(-3px)',
                                boxShadow: '1px 1px 4px 0px rgba(23, 28, 52, 0.2)',
                            }, onClick: onBack }, void 0)), _jsx(Heading, __assign({ fontSize: onBack ? ['lg', 'xl'] : '2xl', fontWeight: "semibold", lineHeight: "inherit" }, { children: heading }), void 0)] }), void 0) }), void 0), children && (_jsx(Flex, __assign({ right: [0, (_a = rest === null || rest === void 0 ? void 0 : rest.px) !== null && _a !== void 0 ? _a : '20px', (_b = rest === null || rest === void 0 ? void 0 : rest.px) !== null && _b !== void 0 ? _b : '20px'], zIndex: 31, p: [4, 'inherit'], bottom: [0, 'auto'], w: ['100%', 'auto'], justifyContent: "center", position: ['fixed', 'absolute'], bg: "rgba(247,248,249,0.8)", backdropFilter: "blur(4px)", top: ['auto', "calc(".concat(sizes.layout.contentHeader, " / 4)")] }, { children: children }), void 0))] }), void 0));
};
