export function exportToCsv(csv, filename) {
    var csvElement = document.createElement('a');
    csvElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
    csvElement.target = '_blank';
    csvElement.download = "".concat(filename, ".csv");
    csvElement.click();
}
/**
 * Return asset URL
 *
 * @param path
 */
export var asset = function (path) {
    return "".concat(process.env.REACT_APP_IMAGE_PREFIX).concat(path);
};
export var uploadableFileTypes = new Set(['jpeg', 'jpg', 'png', 'pdf']);
export var getFileName = function (file) { return file.name.split('-').join('_'); };
export var getFileType = function (file) { var _a; return ((_a = file.type.split('/').pop()) === null || _a === void 0 ? void 0 : _a.toLowerCase()) || ''; };
