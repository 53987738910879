var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button } from '@chakra-ui/react';
import { useToast } from '@obtainly/hooks';
import { useMutateModel } from '@obtainly/http';
import { MerchantDocumentStatus, } from '@obtainly/models';
import { debounce } from 'lodash';
import { Fragment, useRef, useState } from 'react';
import { FileSelector, FileUploader } from './File';
import { MerchantDocument } from './MerchantDocumentForm';
export var MerchantDocumentList = function (_a) {
    var _b;
    var label = _a.label, tag = _a.tag, merchant = _a.merchant, documents = _a.documents, refetch = _a.refetch, isAdmin = _a.isAdmin, allowMultipleFiles = _a.allowMultipleFiles;
    var toast = useToast().toast;
    var buttonRef = useRef(null);
    var _c = useState(new Map()), files = _c[0], setFiles = _c[1];
    var addSelectedFiles = function (fileMap) {
        setFiles(function (prevFiles) {
            var nextFiles = new Map(prevFiles);
            for (var _i = 0, _a = Array.from(fileMap); _i < _a.length; _i++) {
                var _b = _a[_i], key = _b[0], file = _b[1];
                nextFiles.set(key, file);
            }
            return nextFiles;
        });
    };
    var removeFile = function (key) {
        setFiles(function (prevFiles) {
            var nextFiles = new Map(prevFiles);
            nextFiles.delete(key);
            return nextFiles;
        });
    };
    var deferredRefetch = debounce(refetch, 100);
    var createMerchantDocMutation = useMutateModel('merchant_document.create');
    var uploadDocument = function (uploadId) {
        deferredRefetch.cancel();
        return new Promise(function (resolve, reject) {
            //
            createMerchantDocMutation.mutate({
                tag: tag,
                value: uploadId,
                status: isAdmin
                    ? MerchantDocumentStatus.Verified
                    : MerchantDocumentStatus.Unverified,
                merchant_id: merchant._id || '',
                type: 'upload',
            }, {
                onSuccess: function () {
                    toast({
                        status: 'success',
                        description: isAdmin
                            ? "".concat(label, " marked as verified")
                            : 'Document file updated',
                    });
                    resolve();
                },
                onError: function (error) {
                    var description = typeof error === 'string'
                        ? error
                        : (error === null || error === void 0 ? void 0 : error.message) || 'An unexpected error has occurred!';
                    toast({
                        status: 'error',
                        description: description,
                    });
                    reject(new Error(description));
                },
            });
        });
    };
    return (_jsxs(Box, { children: [documents === null || documents === void 0 ? void 0 : documents.map(function (document, index) { return (_jsx(Fragment, { children: _jsx(MerchantDocument, { label: label, merchant: merchant, tag: tag, merchantDocument: document, isAdmin: isAdmin, onUpdate: function () { return refetch(); } }, void 0) }, index)); }), (_b = Array.from(files.entries())) === null || _b === void 0 ? void 0 : _b.map(function (_a) {
                var key = _a[0], file = _a[1];
                return (_jsx(FileUploader, { tag: tag, label: label, file: file, onUpload: uploadDocument, onRemove: function () {
                        removeFile(key);
                        deferredRefetch();
                    }, autoStart: true }, key));
            }), _jsx(FileSelector, { onSelect: addSelectedFiles, multiple: allowMultipleFiles, ref: buttonRef }, void 0), (allowMultipleFiles ||
                !documents ||
                (documents && (documents === null || documents === void 0 ? void 0 : documents.length) === 0)) && (_jsxs(Button, __assign({ mt: "10px", onClick: function () { var _a; return (_a = buttonRef.current) === null || _a === void 0 ? void 0 : _a.click(); } }, { children: ["Upload ", (documents === null || documents === void 0 ? void 0 : documents.length) ? 'more' : '', " file", allowMultipleFiles ? 's' : ''] }), void 0))] }, void 0));
};
