var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { VStack } from '@chakra-ui/layout';
import { AdministratorMetricCard } from 'src/administrator';
import { AdministratorReconciliation } from './AdministratorReconciliation';
export var AdministratorDashboard = function () {
    return (_jsxs(VStack, __assign({ pt: 3, spacing: 5, alignItems: "stretch" }, { children: [_jsx(AdministratorMetricCard, {}, void 0), _jsx(AdministratorReconciliation, {}, void 0)] }), void 0));
};
