var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Flex, Icon, IconButton, Text, useClipboard, } from '@chakra-ui/react';
import { useToast } from '@obtainly/hooks';
import { useMutateModel } from '@obtainly/http';
import { MerchantDocumentStatus, MerchantDocumentTag, } from '@obtainly/models';
import { obscureString } from '@obtainly/utils';
import { useEffect, useState } from 'react';
import { RiCheckboxCircleFill, RiFileCopyFill } from 'react-icons/ri';
import { FormField } from './FormField';
export var BVNForm = function (_a) {
    var merchant = _a.merchant, merchantDocument = _a.merchantDocument, isAdmin = _a.isAdmin, onUpdate = _a.onUpdate;
    var toast = useToast().toast;
    var _b = useState(''), bvn = _b[0], setBvn = _b[1];
    var _c = useClipboard(bvn), onCopy = _c.onCopy, hasCopied = _c.hasCopied;
    var _d = useState(false), verified = _d[0], setVerified = _d[1];
    var verifyBvnMutation = useMutateModel('verify.bvn_verification');
    var createMerchantDocMutation = useMutateModel('merchant_document.create');
    var saveBvn = function () {
        if (merchant) {
            verifyBvnMutation.mutate({ bvn: bvn, options: '{}' }, {
                onSuccess: function (data) {
                    var _a;
                    if (((_a = data === null || data === void 0 ? void 0 : data.api_response) === null || _a === void 0 ? void 0 : _a.status) &&
                        (data === null || data === void 0 ? void 0 : data.first_name) &&
                        (data === null || data === void 0 ? void 0 : data.last_name)) {
                        var firstNameMatches = String(data.first_name).toLowerCase().trim() ===
                            String(merchant.firstname).toLowerCase().trim();
                        var lastNameMatches = String(data.last_name).toLowerCase().trim() ===
                            String(merchant.lastname).toLowerCase().trim();
                        if (firstNameMatches && lastNameMatches) {
                            createMerchantDocMutation.mutate({
                                tag: MerchantDocumentTag.BVN,
                                value: bvn,
                                status: MerchantDocumentStatus.Verified,
                                type: 'string',
                                merchant_id: "".concat(merchant._id),
                            }, {
                                onSuccess: function () {
                                    setVerified(true);
                                    toast({
                                        status: 'success',
                                        description: 'BVN verification successful.',
                                    });
                                    onUpdate === null || onUpdate === void 0 ? void 0 : onUpdate();
                                },
                            });
                        }
                        else {
                            toast({
                                status: 'error',
                                description: "Your name \n                    (".concat(data.first_name, " ").concat(data.last_name, ") \n                    on BVN record did not match profile name \n                    (").concat(merchant.firstname, " ").concat(merchant.lastname, ")"),
                                duration: 10000,
                            });
                        }
                    }
                    else {
                        toast({
                            status: 'error',
                            description: 'We could not verify your BVN.',
                        });
                    }
                },
                onError: function () {
                    toast({
                        status: 'error',
                        description: 'We could not verify your BVN.',
                    });
                },
            });
        }
    };
    var isLoading = verifyBvnMutation.isLoading || createMerchantDocMutation.isLoading;
    useEffect(function () {
        setBvn((merchantDocument === null || merchantDocument === void 0 ? void 0 : merchantDocument.value) || '');
        setVerified((merchantDocument === null || merchantDocument === void 0 ? void 0 : merchantDocument.status) === MerchantDocumentStatus.Verified);
    }, [merchantDocument]);
    return verified ? (_jsxs(Flex, __assign({ alignItems: "start", gap: "6px" }, { children: [_jsx(Icon, { as: RiCheckboxCircleFill, color: "success.500", fontSize: "18px" }, void 0), _jsxs(Box, { children: [_jsx(Text, __assign({ color: "success.600" }, { children: "BVN has been verified" }), void 0), _jsxs(Flex, __assign({ alignItems: "center", gap: "6px", mt: "4px" }, { children: [_jsx(Text, __assign({ color: "gray.800" }, { children: isAdmin ? bvn : obscureString(bvn, 3) }), void 0), !!isAdmin && (_jsx(_Fragment, { children: hasCopied ? (_jsx(Text, __assign({ fontSize: "sm", color: "success.600" }, { children: "Copied!" }), void 0)) : (_jsx(IconButton, { "aria-label": "copy", size: "xs", color: "primary.500", bgColor: "primary.50", _hover: { bgColor: 'primary.50' }, icon: _jsx(RiFileCopyFill, {}, void 0), onClick: function () { return onCopy(); } }, void 0)) }, void 0))] }), void 0)] }, void 0)] }), void 0)) : merchant ? (_jsxs(_Fragment, { children: [_jsx(FormField, { size: "sm", value: bvn, onChange: function (_a) {
                    var target = _a.target;
                    return setBvn(target.value);
                } }, void 0), _jsx(Button, __assign({ type: "button", mt: "15px", size: "sm", colorScheme: "primary", isLoading: isLoading, isDisabled: isLoading || !bvn, onClick: saveBvn }, { children: "Save" }), void 0)] }, void 0)) : (_jsx(Flex, __assign({ alignItems: "center", gap: "6px" }, { children: _jsx(Text, __assign({ color: "gray.600" }, { children: "No verified BVN found." }), void 0) }), void 0));
};
