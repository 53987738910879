var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Image, } from '@chakra-ui/react';
import { useRef, useState } from 'react';
export var FilePreviewer = function (_a) {
    var isOpen = _a.isOpen, onClose = _a.onClose, data = _a.data;
    var boxRef = useRef();
    var _b = useState(0), boxHeight = _b[0], setBoxHeight = _b[1];
    var setIframeHeight = function () {
        var _a;
        if (boxRef.current) {
            setBoxHeight((_a = boxRef.current) === null || _a === void 0 ? void 0 : _a.clientHeight);
        }
    };
    if (!data)
        return null;
    if (!(data.startsWith('https://') ||
        data.startsWith('http://') ||
        data.startsWith('data:')))
        return null;
    return (_jsx(_Fragment, { children: _jsxs(Drawer, __assign({ onClose: onClose, isOpen: isOpen, size: "lg" }, { children: [_jsx(DrawerOverlay, {}, void 0), _jsxs(DrawerContent, { children: [_jsx(DrawerCloseButton, {}, void 0), _jsx(DrawerHeader, { children: "Document Preview" }, void 0), _jsx(DrawerBody, __assign({ ref: boxRef }, { children: _jsx(Box, __assign({ boxShadow: "md", rounded: "md", bg: "white", overflow: "hidden" }, { children: data.startsWith('data:image') ||
                                    data.endsWith('.jpg') ||
                                    data.endsWith('.jpeg') ||
                                    data.endsWith('.png') ? (_jsx(Image, { src: data }, void 0)) : (_jsx("iframe", { title: "file-preview-window", onLoad: setIframeHeight, width: "100%", height: "".concat(boxHeight - 40, "px"), src: data }, void 0)) }), void 0) }), void 0)] }, void 0)] }), void 0) }, void 0));
};
