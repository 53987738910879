import { Supplier, Suppliers } from './pages';
export var supplierRoutes = [
    {
        id: 'suppliers',
        path: '/supplier',
        component: Suppliers,
        requiresAuth: true,
        exact: true,
    },
    {
        id: 'supplier',
        path: '/supplier/:id',
        component: Supplier,
        requiresAuth: true,
        exact: true,
    },
];
