import { MailingList } from './pages';
export var mailingListRoutes = [
    {
        id: 'mailing-list',
        path: '/mailing_list',
        component: MailingList,
        requiresAuth: true,
        exact: true,
    },
];
export * from './components';
