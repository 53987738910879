import React from 'react';
export function useDebounce(value, delay) {
    if (delay === void 0) { delay = 750; }
    /** State and setters for debounced value */
    var _a = React.useState(value), debouncedValue = _a[0], setDebouncedValue = _a[1];
    React.useEffect(function () {
        // Set debouncedValue to value (passed in) after the specified delay
        var handler = setTimeout(function () {
            setDebouncedValue(value);
        }, delay);
        /**
         * Return a cleanup function that will be called every time ...
         * ... useEffect is re-called. useEffect will only be re-called ...
         * ... if value changes (see the inputs array below).
         * This is how we prevent debouncedValue from changing if value is ...
         * ... changed within the delay period. Timeout gets cleared and restarted.
         * To put it in context, if the user is typing within our app's ...
         * ... search box, we don't want the debouncedValue to update until ...
         * ... they've stopped typing for more than 500ms.
         */
        return function () {
            clearTimeout(handler);
        };
    }, 
    /**
     * // Only re-call effect if value changes
     * You could also add the "delay" var to inputs array if you ...
     * ... need to be able to change that dynamically.
     */
    [value, delay]);
    return debouncedValue;
}
