export var colors = {
    body: '#0A0A0F',
    black: '#0A0A0F',
    white: '#ffffff',
    primary: {
        50: '#F5F9FF',
        100: '#B3D3FF',
        200: '#80B6FF',
        500: '#016DFF',
        600: '#0157CC',
    },
    gray: {
        50: '#F7F8F9',
        100: '#F2F3F5',
        200: '#ECEDEF',
        300: '#E0E3E6',
        400: '#CCD0D5',
        500: '#99A1AC',
        600: '#808A97',
        700: '#666E79',
        800: '#4D535B',
        900: '#33373C',
    },
    success: {
        50: '#F0FFF5',
        100: '#C6F7D5',
        200: '#9AE6B4',
        500: '#38A169',
        600: '#2F855A',
    },
    critical: {
        50: '#FFF5F5',
        100: '#FED7D7',
        200: '#FEB2B2',
        500: '#E53E3E',
        600: '#C53030',
    },
    warning: {
        50: '#FFFAF0',
        100: '#FEEBC8',
        200: '#FBD38D',
        500: '#DD6B20',
        600: '#C05621',
    },
    info: {
        50: '#EBF8FF',
        100: '#BEE3F8',
        200: '#90CDF4',
        500: '#3182CE',
        600: '#2B6CB0',
    },
    accent: {
        50: '#F0FCFF',
        100: '#DCF6FE',
        200: '#A7E9FD',
        300: '#7BDFFD',
        400: '#65D9FC',
        500: '#39CEFB',
        600: '#23C9FB',
        700: '#20B5E2',
        800: '#1CA1C9',
        900: '#198DB0',
    },
    secondary: {
        main: '#06263E',
        dodgerBlue: '#23C9FB',
        mirage: '#171C34',
        ebony: '#0C1326',
    },
};
