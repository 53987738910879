var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/layout';
import { Select } from '@chakra-ui/select';
import pluralize from 'pluralize';
import { usePagination, useTable, useExpanded } from 'react-table';
import { Pagination } from '../Pagination';
import { Table } from './Table';
import { Tbody } from './Tbody';
import { Td } from './Td';
import { Th } from './Th';
import { Thead } from './Thead';
import { Tr } from './Tr';
export var TableMaker = function (_a) {
    var _b, _c;
    var columns = _a.columns, tableProps = _a.tableProps, hiddenColumns = _a.hiddenColumns, rowSubComponent = _a.rowSubComponent, page = _a.page, pageSize = _a.pageSize, onPageChange = _a.onPageChange, props = __rest(_a, ["columns", "tableProps", "hiddenColumns", "rowSubComponent", "page", "pageSize", "onPageChange"]);
    var skipPageResetRef = React.useRef(false);
    var data = React.useMemo(function () {
        // When data gets updated with this function, set a flag
        // to disable all of the auto resetting
        skipPageResetRef.current = true;
        return props.data;
    }, [props.data]);
    React.useEffect(function () {
        // After the table has updated, always remove the flag
        skipPageResetRef.current = false;
    });
    var tableInstance = useTable({
        data: data,
        columns: columns,
        initialState: { pageSize: pageSize, hiddenColumns: hiddenColumns },
        // Prevent auto reset when data changes
        autoResetPage: !skipPageResetRef.current,
        autoResetExpanded: !skipPageResetRef.current,
        autoResetGroupBy: !skipPageResetRef.current,
        autoResetSelectedRows: !skipPageResetRef.current,
        autoResetSortBy: !skipPageResetRef.current,
        autoResetFilters: !skipPageResetRef.current,
        autoResetRowState: !skipPageResetRef.current,
    }, useExpanded, usePagination);
    React.useEffect(function () {
        page && tableInstance.gotoPage(page - 1);
    }, [page]); // eslint-disable-line
    var getCustomRowProps = function (row) {
        return props.getCustomRowProps ? props.getCustomRowProps(row) : {};
    };
    return (_jsxs(Box, __assign({ pb: 5 }, { children: [_jsxs(Table, __assign({}, tableInstance.getTableProps(), tableProps, { mb: 5 }, { children: [_jsx(Thead, { children: tableInstance.headerGroups.map(function (headerGroup) { return (_jsx(Tr, __assign({}, headerGroup.getHeaderGroupProps(), { children: headerGroup.headers.map(function (column) { return (_jsx(Th, __assign({}, column.getHeaderProps(), column.props, { children: column.render('Header') }), void 0)); }) }), void 0)); }) }, void 0), _jsx(Tbody, __assign({}, tableInstance.getTableBodyProps(), { children: tableInstance.page.map(function (row, index) {
                            tableInstance.prepareRow(row);
                            return (
                            // Use a React.Fragment here so the table markup is still valid
                            _jsxs(React.Fragment, { children: [_jsx(Tr, __assign({}, row.getRowProps(), getCustomRowProps(row.original), { children: row.cells.map(function (cell) { return (_jsx(Td, __assign({}, cell.getCellProps(), cell.column.props, { children: cell.render('Cell') }), void 0)); }) }), void 0), row.isExpanded && rowSubComponent ? (_jsx(Tr, { children: _jsx(Td, __assign({ as: "td", colSpan: tableInstance.visibleColumns.length }, { children: React.cloneElement(rowSubComponent, { row: row }) }), void 0) }, void 0)) : null] }, index));
                        }) }), void 0)] }), void 0), !props.hidePagination && (_jsxs(Flex, __assign({ justifyContent: "space-between", px: "20px" }, { children: [_jsxs(Flex, __assign({ alignItems: "center", gridGap: 2 }, { children: [_jsxs(Text, __assign({ fontWeight: "medium" }, { children: [(_b = tableInstance.data) === null || _b === void 0 ? void 0 : _b.length, ' ', pluralize('result', (_c = tableInstance.data) === null || _c === void 0 ? void 0 : _c.length)] }), void 0), _jsxs(Select, __assign({ size: "sm", width: "auto", value: tableInstance.state.pageSize, onChange: function (event) {
                                    tableInstance.setPageSize(Number(event.target.value));
                                } }, { children: [_jsx("option", __assign({ value: 10 }, { children: "10 per page" }), void 0), _jsx("option", __assign({ value: 25 }, { children: "25 per page" }), void 0), _jsx("option", __assign({ value: 50 }, { children: "50 per page" }), void 0), _jsx("option", __assign({ value: 100 }, { children: "100 per page" }), void 0)] }), void 0)] }), void 0), tableInstance.pageCount > 1 && (_jsx(Pagination, { count: tableInstance.pageCount, page: tableInstance.state.pageIndex + 1, onChange: function (index) {
                            onPageChange
                                ? onPageChange(index)
                                : tableInstance.gotoPage(index - 1);
                        } }, void 0))] }), void 0))] }), void 0));
};
TableMaker.defaultProps = {
    hiddenColumns: [],
    pageSize: 10,
};
