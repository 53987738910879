var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable no-console */
import { Button } from '@chakra-ui/button';
import { Badge, Flex, Text } from '@chakra-ui/layout';
import React from 'react';
import { Link } from 'react-router-dom';
import { formatUnixTime } from '@obtainly/utils';
import { ContentLoader, TableMaker } from 'src/core/components';
import { useRouter } from 'src/core/hooks';
export var MerchantsTable = function (props) {
    var _a, _b;
    var router = useRouter();
    var columns = React.useMemo(function () {
        return [
            { Header: '#', Cell: function (_a) {
                    var row = _a.row;
                    return Number(row.id) + 1;
                } },
            {
                accessor: 'status',
                Header: 'Status',
                Cell: function (_a) {
                    var cell = _a.cell;
                    return (_jsx(Badge, __assign({ colorScheme: cell.value === 'paid' ? 'success' : 'orange' }, { children: cell.value }), void 0));
                },
            },
            {
                accessor: 'business_name',
                Header: 'Customer',
                Cell: function (_a) {
                    var cell = _a.cell, row = _a.row;
                    return (_jsx(_Fragment, { children: _jsxs(Flex, __assign({ align: "flex-start", justifyContent: "flex-end", gridGap: 3, flexDir: "column" }, { children: [_jsx(Text, __assign({ textTransform: "capitalize", fontWeight: "medium" }, { children: _jsx(Link, __assign({ to: router.getLink('merchant', { id: row.original._id }) }, { children: cell.value }), void 0) }), void 0), _jsx(Link, __assign({ to: router.getLink('merchant', { id: row.original._id }) }, { children: _jsx(Button, __assign({ size: "xs", variant: "outline" }, { children: "View" }), void 0) }), void 0)] }), void 0) }, void 0));
                },
            },
            {
                accessor: 'distributors',
                Header: 'Distributors',
                Cell: function (_a) {
                    var cell = _a.cell;
                    return (_jsx(_Fragment, { children: _jsx(Flex, __assign({ align: "center", gap: 2, wrap: "wrap" }, { children: Object.values((cell === null || cell === void 0 ? void 0 : cell.value) || {}).map(function (value) {
                                var _a;
                                return (_jsx(Badge, __assign({ colorScheme: "primary" }, { children: (_a = value === null || value === void 0 ? void 0 : value.distributor) === null || _a === void 0 ? void 0 : _a.name }), value._id));
                            }) }), void 0) }, void 0));
                },
            },
            { accessor: 'firstname', Header: 'First Name' },
            { accessor: 'lastname', Header: 'Last Name' },
            { accessor: 'phone', Header: 'Phone' },
            { accessor: 'email', Header: 'Email' },
            {
                accessor: 'created',
                Header: 'Joined on',
                Cell: function (_a) {
                    var cell = _a.cell;
                    return (_jsxs(Text, __assign({ fontSize: "xs" }, { children: [_jsx("pre", { children: String(formatUnixTime(cell.value, 'dd MMM, yyyy')) }, void 0), _jsx("pre", { children: String(formatUnixTime(cell.value, 'hh:mm a')) }, void 0)] }), void 0));
                },
            },
            {
                accessor: 'last_payment_on',
                Header: 'Last payment on',
                Cell: function (_a) {
                    var cell = _a.cell;
                    var value = cell.value;
                    return (_jsx(Text, __assign({ fontSize: "xs" }, { children: value ? (_jsxs(_Fragment, { children: [_jsx("pre", { children: String(formatUnixTime(value, 'dd MMM, yyyy')) }, void 0), _jsx("pre", { children: String(formatUnixTime(value, 'hh:mm a')) }, void 0)] }, void 0)) : (_jsx("pre", { children: "-" }, void 0)) }), void 0));
                },
            },
            {
                accessor: 'last_credit_on',
                Header: 'Last credit on',
                Cell: function (_a) {
                    var cell = _a.cell;
                    var value = cell.value;
                    return (_jsx(Text, __assign({ fontSize: "xs" }, { children: value ? (_jsxs(_Fragment, { children: [_jsx("pre", { children: String(formatUnixTime(value, 'dd MMM, yyyy')) }, void 0), _jsx("pre", { children: String(formatUnixTime(value, 'hh:mm a')) }, void 0)] }, void 0)) : (_jsx("pre", { children: "-" }, void 0)) }), void 0));
                },
            },
        ];
    }, [router]);
    return (_jsxs(_Fragment, { children: [!((_a = props.merchants) === null || _a === void 0 ? void 0 : _a.length) && (_jsx(ContentLoader, { errorSummary: "No customer was found", contentUnavailable: true }, void 0)), !!((_b = props.merchants) === null || _b === void 0 ? void 0 : _b.length) && (_jsx(TableMaker, { columns: columns, data: props.merchants, tableProps: { isFit: true, mb: 20 } }, void 0))] }, void 0));
};
