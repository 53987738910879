import { jsx as _jsx } from "react/jsx-runtime";
import { Autocomplete } from '@obtainly/ui/web-shared';
import { useAtom } from 'jotai';
import { useEffect, useMemo } from 'react';
import { distributorsAtom } from 'src/core/store/distributor';
export var SelectDistributor = function (_a) {
    var distributors = _a.distributors;
    var _b = useAtom(distributorsAtom), selectedDistributor = _b[0].active, setDistributor = _b[1];
    var convertedDistributorsValue = useMemo(function () {
        return Object.values(distributors || {});
    }, [distributors]);
    var distroOptions = useMemo(function () {
        return convertedDistributorsValue.map(function (distributorMerchant) {
            var _a;
            return ({
                label: (_a = distributorMerchant.distributor) === null || _a === void 0 ? void 0 : _a.name,
                value: distributorMerchant.distributor_id,
            });
        });
    }, [convertedDistributorsValue]);
    useEffect(function () {
        if (convertedDistributorsValue.length === 1) {
            setDistributor({
                all: convertedDistributorsValue,
                active: convertedDistributorsValue[0],
            });
        }
    }, [convertedDistributorsValue, setDistributor]);
    function handleSupplierSwitch(value) {
        var selectedDistributor = convertedDistributorsValue.find(function (distributor) { return distributor.distributor_id === value; });
        if (!selectedDistributor)
            return;
        setDistributor({
            all: convertedDistributorsValue,
            active: selectedDistributor,
        });
    }
    return (_jsx(Autocomplete, { value: selectedDistributor === null || selectedDistributor === void 0 ? void 0 : selectedDistributor.distributor_id, onChange: function (_a) {
            var value = _a.value;
            return handleSupplierSwitch(value);
        }, options: distroOptions }, void 0));
};
