var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { FormControl, FormHelperText, FormLabel } from '@chakra-ui/form-control';
import { Box, Stack } from '@chakra-ui/layout';
/**
 * FormGroup helps you easily create stacked form group
 * elements in the horizontal or vertical direction.
 */
export var FormGroup = function (_a) {
    var _b, _c;
    var label = _a.label, isRequired = _a.isRequired, helperText = _a.helperText, columns = _a.columns, orientation = _a.orientation, children = _a.children, props = __rest(_a, ["label", "isRequired", "helperText", "columns", "orientation", "children"]);
    var direction = ['column'];
    if (orientation === 'horizontal') {
        direction = ['column', 'column', 'row'];
    }
    var leftFlex = orientation === 'horizontal' ? ((_b = columns === null || columns === void 0 ? void 0 : columns[0]) !== null && _b !== void 0 ? _b : 3) / 12 : 0;
    var rightFlex = orientation === 'horizontal' ? ((_c = columns === null || columns === void 0 ? void 0 : columns[1]) !== null && _c !== void 0 ? _c : 9) / 12 : 0;
    return (_jsxs(Stack, __assign({ mb: 3, direction: direction }, props, { children: [!!label || !!helperText ? (_jsx(Box, __assign({ flex: leftFlex }, { children: _jsxs(FormControl, __assign({ mb: 0, isRequired: isRequired }, { children: [!!label && (_jsx(FormLabel, __assign({ size: "md", mb: 0 }, { children: label }), void 0)), !!helperText && (_jsx(FormHelperText, __assign({ color: "gray.600", mt: 1 }, { children: helperText }), void 0))] }), void 0) }), void 0)) : null, _jsx(Box, __assign({ flex: rightFlex }, { children: children }), void 0)] }), void 0));
};
FormGroup.defaultProps = {
    orientation: 'vertical',
};
