export function formatTag(tag) {
    switch (tag) {
        case 'transfer':
            return 'Cash Payment';
        case 'credit':
            return 'Credit Payment';
        case 'credit_payback':
            return 'Credit Pay Back';
        case 'penalty-approved':
            return 'Charge';
        default:
            return tag;
    }
}
