var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Badge, Button, Flex, Text } from '@chakra-ui/react';
import { TableMaker } from '@obtainly/ui/web-desktop';
import { ContentLoader } from '@obtainly/ui/web-shared';
import React from 'react';
import { Link } from 'react-router-dom';
import { useRouter } from 'src/core/hooks';
import { SupplierForm } from './SupplierForm';
export var SuppliersTable = function (props) {
    var _a, _b;
    var router = useRouter();
    var columns = React.useMemo(function () {
        return [
            { Header: '#', Cell: function (_a) {
                    var row = _a.row;
                    return Number(row.id) + 1;
                } },
            {
                accessor: 'name',
                Header: 'Supplier',
                Cell: function (_a) {
                    var cell = _a.cell, row = _a.row;
                    return (_jsx(_Fragment, { children: _jsx(Flex, __assign({ align: "flex-start", justifyContent: "flex-end", gridGap: 3, flexDir: "column" }, { children: _jsx(Text, __assign({ textTransform: "capitalize", fontWeight: "medium" }, { children: _jsx(Link, __assign({ to: router.getLink('supplier', { id: row.original._id }) }, { children: cell.value }), void 0) }), void 0) }), void 0) }, void 0));
                },
            },
            {
                accessor: 'status',
                Header: 'Status',
                Cell: function (_a) {
                    var cell = _a.cell;
                    return (_jsx(Badge, __assign({ colorScheme: cell.value === 'paid' ? 'success' : 'orange' }, { children: cell.value }), void 0));
                },
            },
            {
                accessor: 'merchant_count',
                Header: '# of Customers',
            },
            {
                Header: 'Actions',
                Cell: function (_a) {
                    var row = _a.row;
                    return (_jsxs(Flex, __assign({ gridGap: 2 }, { children: [_jsx(SupplierForm, { distributor: props.suppliers.find(function (s) { return s._id === row.original._id; }), action: "update", refetch: props.refetch }, void 0), _jsx(Link, __assign({ to: router.getLink('supplier', {
                                    id: row.original._id,
                                }) }, { children: _jsx(Button, __assign({ size: "sm", variant: "outline" }, { children: "View" }), void 0) }), void 0)] }), void 0));
                },
            },
        ];
    }, [props.refetch, props.suppliers, router]);
    return (_jsxs(_Fragment, { children: [!((_a = props.suppliers) === null || _a === void 0 ? void 0 : _a.length) && (_jsx(ContentLoader, { errorMessage: "No suppliers was found", contentUnavailable: true }, void 0)), !!((_b = props.suppliers) === null || _b === void 0 ? void 0 : _b.length) && (_jsx(TableMaker, { columns: columns, data: props.suppliers, tableProps: { isFit: true, mb: 20 } }, void 0))] }, void 0));
};
