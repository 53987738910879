var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/layout';
import { Select } from '@chakra-ui/select';
import pluralize from 'pluralize';
import { usePagination, useTable, useExpanded } from 'react-table';
import { useQueryStringParse } from '@obtainly/hooks';
import { Pagination, Table, Tbody, Td, Th, Thead, Tr, } from 'src/core/components';
import { useRouter } from 'src/core/hooks';
export var TableMaker = function (props) {
    var _a, _b, _c;
    var navigate = useRouter(((_a = props === null || props === void 0 ? void 0 : props.route) === null || _a === void 0 ? void 0 : _a.id) || '');
    var columns = props.columns, tableProps = props.tableProps, hiddenColumns = props.hiddenColumns, renderRowSubComponent = props.renderRowSubComponent;
    var skipPageResetRef = React.useRef(false);
    var data = React.useMemo(function () {
        // When data gets updated with this function, set a flag
        // to disable all of the auto resetting
        skipPageResetRef.current = true;
        return props.data;
    }, [props.data]);
    React.useEffect(function () {
        // After the table has updated, always remove the flag
        skipPageResetRef.current = false;
    });
    var tableInstance = useTable({
        data: data,
        columns: columns,
        initialState: { pageSize: 10, hiddenColumns: hiddenColumns },
        // Prevent auto reset when data changes
        autoResetPage: !skipPageResetRef.current,
        autoResetExpanded: !skipPageResetRef.current,
        autoResetGroupBy: !skipPageResetRef.current,
        autoResetSelectedRows: !skipPageResetRef.current,
        autoResetSortBy: !skipPageResetRef.current,
        autoResetFilters: !skipPageResetRef.current,
        autoResetRowState: !skipPageResetRef.current,
    }, useExpanded, usePagination);
    var query = useQueryStringParse();
    React.useEffect(function () {
        tableInstance.gotoPage((query === null || query === void 0 ? void 0 : query.page) - 1);
    }, [query === null || query === void 0 ? void 0 : query.page]); // eslint-disable-line
    var getCustomRowProps = function (row) {
        return props.getCustomRowProps ? props.getCustomRowProps(row) : {};
    };
    return (_jsxs(Box, __assign({ pb: 5 }, { children: [_jsxs(Table, __assign({}, tableInstance.getTableProps(), tableProps, { mb: 5 }, { children: [_jsx(Thead, { children: tableInstance.headerGroups.map(function (headerGroup) { return (_jsx(Tr, __assign({}, headerGroup.getHeaderGroupProps(), { children: headerGroup.headers.map(function (column) { return (_jsx(Th, __assign({}, column.getHeaderProps(), column.props, { children: column.render('Header') }), void 0)); }) }), void 0)); }) }, void 0), _jsx(Tbody, __assign({}, tableInstance.getTableBodyProps(), { children: tableInstance.page.map(function (row, index) {
                            tableInstance.prepareRow(row);
                            return (
                            // Use a React.Fragment here so the table markup is still valid
                            _jsxs(React.Fragment, { children: [_jsx(Tr, __assign({}, row.getRowProps(), getCustomRowProps(row.original), { children: row.cells.map(function (cell) { return (_jsx(Td, __assign({}, cell.getCellProps(), cell.column.props, { children: cell.render('Cell') }), void 0)); }) }), void 0), row.isExpanded ? (_jsx(Tr, { children: _jsx(Td, __assign({ as: "td", colSpan: tableInstance.visibleColumns.length }, { children: renderRowSubComponent === null || renderRowSubComponent === void 0 ? void 0 : renderRowSubComponent({ row: row }) }), void 0) }, void 0)) : null] }, index));
                        }) }), void 0)] }), void 0), !props.hidePagination && (_jsxs(Flex, __assign({ justifyContent: "space-between", px: "20px" }, { children: [_jsxs(Flex, __assign({ alignItems: "center", gridGap: 2 }, { children: [_jsxs(Text, __assign({ fontWeight: "medium" }, { children: [(_b = tableInstance.data) === null || _b === void 0 ? void 0 : _b.length, ' ', pluralize('result', (_c = tableInstance.data) === null || _c === void 0 ? void 0 : _c.length)] }), void 0), _jsxs(Select, __assign({ size: "sm", width: "auto", value: tableInstance.state.pageSize, onChange: function (event) {
                                    tableInstance.setPageSize(Number(event.target.value));
                                } }, { children: [_jsx("option", __assign({ value: 10 }, { children: "10 per page" }), void 0), _jsx("option", __assign({ value: 25 }, { children: "25 per page" }), void 0), _jsx("option", __assign({ value: 50 }, { children: "50 per page" }), void 0), _jsx("option", __assign({ value: 100 }, { children: "100 per page" }), void 0)] }), void 0)] }), void 0), tableInstance.pageCount > 1 && (_jsx(Pagination, { count: tableInstance.pageCount, page: tableInstance.state.pageIndex + 1, onChange: function (index) {
                            var _a;
                            ((_a = props === null || props === void 0 ? void 0 : props.route) === null || _a === void 0 ? void 0 : _a.id)
                                ? navigate.switchQuery(__assign(__assign({}, query), { page: index }))
                                : tableInstance.gotoPage(index - 1);
                        } }, void 0))] }), void 0))] }), void 0));
};
TableMaker.defaultProps = {
    hiddenColumns: [],
};
