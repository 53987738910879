var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Spinner, Flex } from '@chakra-ui/react';
import { Route, Switch } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { ErrorBox } from './ErrorBox';
import { ProtectedRoute } from './ProtectedRoute';
export var SuspenseLoader = function () { return (_jsx(Flex, __assign({ justifyContent: "center", my: 150 }, { children: _jsx(Spinner, { color: "gray.500" }, void 0) }), void 0)); };
export var RouteSwitcher = function (props) { return (_jsx(React.Suspense, __assign({ fallback: _jsx(SuspenseLoader, {}, void 0) }, { children: _jsx(Sentry.ErrorBoundary, __assign({ fallback: _jsx(ErrorBox, { type: "suspense", my: 150 }, void 0) }, { children: _jsx(Switch, { children: props.routes.map(function (route) {
                var routeProps = {
                    path: route.path,
                    component: route.component,
                    exact: route.exact,
                };
                return !route.requiresAuth ? (_jsx(Route, __assign({}, routeProps), route.id)) : (_jsx(ProtectedRoute, __assign({}, routeProps, { isAuthenticated: props.isAuthenticated, isFirstLogin: !!props.isFirstLogin }), route.id));
            }) }, void 0) }), void 0) }), void 0)); };
