var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Badge, Box, Flex, Text } from '@chakra-ui/layout';
import { useMerchantDocumentState } from '@obtainly/hooks';
import pluralize from 'pluralize';
import { MdVerified } from 'react-icons/md';
import { Card, CardBody } from './Card';
export var MerchantVerificationStatus = function (_a) {
    var merchantId = _a.merchantId, isAdmin = _a.isAdmin;
    var _b = useMerchantDocumentState(merchantId), unset = _b.unset, unsetCount = _b.unsetCount, unverified = _b.unverified, unverifiedCount = _b.unverifiedCount, requiredDocumentCount = _b.requiredDocumentCount, isComplete = _b.isComplete, isLoading = _b.isLoading;
    if (isLoading)
        return null;
    return (_jsx(Card, { children: _jsxs(CardBody, { children: [!!isComplete && (_jsxs(Flex, __assign({ align: "center", gap: "3px" }, { children: [_jsx(Box, __assign({ color: "primary.600", fontSize: "2xl" }, { children: _jsx(MdVerified, {}, void 0) }), void 0), _jsx(Text, { children: "Profile has been verified" }, void 0)] }), void 0)), !!unsetCount && (_jsxs(Box, __assign({ mb: isAdmin ? '15px' : '0px' }, { children: [_jsxs(Text, __assign({ fontWeight: "medium" }, { children: ["Incomplete ", pluralize('requirement', unsetCount), " (", unsetCount, "/", requiredDocumentCount, ")"] }), void 0), Array.from(unset).map(function (tag, index) { return (_jsx(Badge, __assign({ colorScheme: "critical", mb: "4px", mr: "4px" }, { children: String(tag || '')
                                .split('_')
                                .join(' ') }), index)); })] }), void 0)), unverifiedCount && isAdmin ? (_jsxs(Box, { children: [_jsxs(Text, __assign({ fontWeight: "medium" }, { children: ["Unverified ", pluralize('requirement', unverifiedCount), " (", unverifiedCount, "/", requiredDocumentCount, ")"] }), void 0), Array.from(unverified).map(function (tag, index) { return (_jsx(Badge, __assign({ mr: "4px", mb: "4px", colorScheme: unset.has(tag) ? 'critical' : 'warning' }, { children: String(tag || '')
                                .split('_')
                                .join(' ') }), index)); })] }, void 0)) : null] }, void 0) }, void 0));
};
