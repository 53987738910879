export var Button = {
    baseStyle: {
        bg: '#ffffff',
        fontWeight: 'medium',
        borderRadius: 99999,
    },
    sizes: {
        sm: {
            fontSize: 'md',
            height: '34px',
        },
    },
    variants: {
        link: {
            _hover: {
                textDecoration: 'none',
            },
        },
    },
};
