import React from 'react';
import { useQueryModel } from '@obtainly/http';
export var useProducts = function (context) {
    var action = context.merchant_id
        ? 'read_by_distributor_merchant'
        : 'read_by_distributor';
    var payload = !context.merchant_id
        ? { distributor_id: context.distributor_id }
        : context;
    var enabled = context.merchant_id
        ? !!context.distributor_id && !!context.merchant_id
        : !!context.distributor_id;
    var _a = useQueryModel("product.".concat(action), payload, {
        enabled: enabled,
    }), data = _a.data, isLoading = _a.isLoading, refetch = _a.refetch, isRefetching = _a.isRefetching;
    var activeProducts = React.useMemo(function () {
        return data === null || data === void 0 ? void 0 : data.filter(function (product) { return product.status === 'active'; });
    }, [data]);
    return {
        products: activeProducts,
        loadingProducts: isLoading,
        refetchProducts: refetch,
        refetchingProducts: isRefetching,
    };
};
