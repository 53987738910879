var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Flex, IconButton, Menu, MenuButton, MenuItem, MenuList, Spinner, useDisclosure, } from '@chakra-ui/react';
import { useMutateModel } from '@obtainly/http';
import { FundingRequestStatus } from '@obtainly/models';
import { RiCheckboxCircleLine, RiForbid2Line, RiMenu2Line, RiPlayCircleLine, } from 'react-icons/ri';
import { useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import { useToast } from 'src/core/hooks';
import { FundingRejectionForm } from '.';
export var FundingRequestActions = function (_a) {
    var fundingRequest = _a.fundingRequest, isTableView = _a.isTableView;
    var toast = useToast().toast;
    var _b = useDisclosure(), isOpen = _b.isOpen, onClose = _b.onClose, onOpen = _b.onOpen;
    var queryClient = useQueryClient();
    var refetch = function () {
        queryClient.refetchQueries('funding_request_read_all');
    };
    var approveMutation = useMutateModel('funding_request.mark_as_approved');
    var rejectMutation = useMutateModel('funding_request.mark_as_rejected');
    var activateMutation = useMutateModel('funding_request.mark_as_active');
    var isLoading = approveMutation.isLoading ||
        rejectMutation.isLoading ||
        activateMutation.isLoading;
    var onSuccess = function () {
        refetch();
        toast({ description: 'Updated', status: 'success' });
    };
    var onError = function (error) {
        toast({
            description: (error === null || error === void 0 ? void 0 : error.message) || 'An unknown error occurred',
            status: 'error',
        });
    };
    var approve = function () {
        approveMutation.mutate({ _id: fundingRequest._id }, { onSuccess: onSuccess, onError: onError });
    };
    var reject = function (reason) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, new Promise(function (resolve, reject) {
                    rejectMutation.mutate({ _id: fundingRequest._id, reason: reason }, {
                        onSuccess: function () {
                            onSuccess();
                            resolve();
                        },
                        onError: function (error) {
                            onError(error);
                            reject(error);
                        },
                    });
                })];
        });
    }); };
    var activate = function () {
        activateMutation.mutate({ _id: fundingRequest._id }, { onSuccess: onSuccess, onError: onError });
    };
    return (_jsxs(_Fragment, { children: [_jsx(FundingRejectionForm, { funding: fundingRequest, isOpen: isOpen, onCancel: onClose, onSubmitReason: reject }, void 0), _jsxs(Flex, __assign({ align: "center", gap: "6px" }, { children: [isTableView ? (_jsx(Link, __assign({ to: "/funding-request/".concat(fundingRequest._id) }, { children: _jsx(Button, { children: "View" }, void 0) }), void 0)) : null, [FundingRequestStatus.Pending].includes(fundingRequest.status) ? (_jsxs(Menu, { children: [isTableView ? (_jsx(MenuButton, { as: IconButton, "aria-label": "Options", icon: _jsx(RiMenu2Line, {}, void 0), variant: "outline" }, void 0)) : (_jsx(MenuButton, __assign({ as: Button, "aria-label": "Options", icon: _jsx(RiMenu2Line, {}, void 0), variant: "outline", colorScheme: "primary" }, { children: "Options" }), void 0)), _jsxs(MenuList, { children: [fundingRequest.status === FundingRequestStatus.Pending ? (_jsxs(_Fragment, { children: [_jsx(MenuItem, __assign({ color: "success.600", onClick: approve, disabled: isLoading, icon: approveMutation.isLoading ? (_jsx(Spinner, {}, void 0)) : (_jsx(RiCheckboxCircleLine, {}, void 0)) }, { children: "Approve" }), void 0), _jsx(MenuItem, __assign({ color: "critical.600", onClick: onOpen, disabled: isLoading, icon: rejectMutation.isLoading ? _jsx(Spinner, {}, void 0) : _jsx(RiForbid2Line, {}, void 0) }, { children: "Reject" }), void 0)] }, void 0)) : null, fundingRequest.status === FundingRequestStatus.Approved ? (_jsx(MenuItem, __assign({ onClick: activate, icon: activateMutation.isLoading ? (_jsx(Spinner, {}, void 0)) : (_jsx(RiPlayCircleLine, {}, void 0)), disabled: isLoading, hidden: true }, { children: "Activate" }), void 0)) : null] }, void 0)] }, void 0)) : null] }), void 0)] }, void 0));
};
FundingRequestActions.defaultProps = {
    isTableView: true,
};
export default FundingRequestActions;
