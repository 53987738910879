import { Dashboard } from './pages/Dashboard';
export var dashboardRoutes = [
    {
        id: 'dashboard',
        path: '/',
        component: Dashboard,
        requiresAuth: true,
        exact: true,
    },
];
