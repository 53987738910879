var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Input, InputLeftElement, InputGroup, InputRightElement, } from '@chakra-ui/input';
import { Flex, useTheme } from '@chakra-ui/react';
import { FiSearch, FiX } from 'react-icons/fi';
import { useDebounce } from '@obtainly/hooks';
export var SearchInput = function (props) {
    var size = props.size, value = props.value, onChange = props.onChange, placeholder = props.placeholder, isDisabled = props.isDisabled, delay = props.delay, width = props.width, rest = __rest(props, ["size", "value", "onChange", "placeholder", "isDisabled", "delay", "width"]);
    var colors = useTheme().colors;
    var _a = React.useState(value), searchTerm = _a[0], setSearchTerm = _a[1];
    var debouncedSearchTerm = useDebounce(searchTerm, delay);
    var searchTermRef = React.useRef(debouncedSearchTerm);
    React.useEffect(function () {
        if (searchTermRef.current !== debouncedSearchTerm) {
            searchTermRef.current = debouncedSearchTerm;
            onChange(debouncedSearchTerm);
        }
    }, [debouncedSearchTerm, onChange]);
    return (_jsx(Flex, __assign({ alignItems: "center" }, rest, { children: _jsxs(InputGroup, __assign({ size: size }, { children: [_jsx(InputLeftElement, __assign({ pointerEvents: "none" }, { children: _jsx(FiSearch, { size: 16, color: colors.gray[isDisabled ? '500' : '600'] }, void 0) }), void 0), _jsx(Input, { size: size, type: "text", name: "searchTerm", value: searchTerm || '', className: "search w-".concat(width), placeholder: placeholder, onChange: function (_a) {
                        var target = _a.target;
                        setSearchTerm(target.value);
                    }, isDisabled: isDisabled }, void 0), !!searchTerm && (_jsx(InputRightElement, __assign({ pointerEvents: "all", cursor: "pointer", transition: "transform 300ms", _hover: { transform: 'scale(1.2)' }, onClick: function () { return setSearchTerm(''); } }, { children: _jsx(FiX, { size: 16, color: colors.gray['500'] }, void 0) }), void 0))] }), void 0) }), void 0));
};
SearchInput.defaultProps = {
    size: 'md',
    placeholder: 'Search...',
    isDisabled: false,
    delay: 750,
    width: 'auto',
};
