var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Button, Flex } from '@chakra-ui/react';
import { useFilter, useQueryStringParse } from '@obtainly/hooks';
import { useQueryModel } from '@obtainly/http';
import { basicSearch, capitalizeString, formatUnixTime, getFundingOverdueState, } from '@obtainly/utils';
import { getUnixTime } from 'date-fns';
import { Card, CardBody, ContentHeader, ContentLoader, Exporter, Layout, PageTabs, SearchInput, } from 'src/core/components';
import { useRouter } from 'src/core/hooks';
import { FundingRequestsTable } from '../components';
var pageTab = [
    'all',
    'pending',
    'rejected',
    'approved',
    'active',
    'due_today',
    'due_in_7_days',
    'paid',
    'overdue',
];
export var FundingRequests = function () {
    var router = useRouter('funding-requests');
    var filters = useQueryStringParse([], ['page']);
    var activeTab = React.useMemo(function () {
        if (!(filters === null || filters === void 0 ? void 0 : filters.status)) {
            return 0;
        }
        var pageIndex = pageTab.findIndex(function (page) { return page === filters.status; });
        if (pageIndex < 0) {
            return 0;
        }
        return pageIndex;
    }, [filters]);
    var columns = [
        {
            label: 'Id',
            accessor: '_id',
        },
        {
            label: 'Ref',
            accessor: 'ref',
            selected: true,
        },
        {
            label: 'Status',
            accessor: 'status',
            selected: true,
        },
        {
            label: 'Amount',
            accessor: 'amount',
            selected: true,
        },
        {
            label: 'Penalty Rate',
            accessor: 'penalty_rate',
            selected: true,
        },
        {
            label: 'Interest rate',
            accessor: 'interest',
            selected: true,
        },
        {
            label: 'Type',
            accessor: 'type',
            selected: true,
        },
        {
            label: 'Duration',
            accessor: 'duration',
            selected: true,
        },
        {
            label: 'Merchant ID',
            accessor: 'merchant_id',
            selected: true,
        },
        {
            label: 'Distributor ID',
            accessor: 'distributor_id',
            selected: true,
        },
        {
            label: 'Created',
            accessor: 'created',
            selected: true,
        },
        {
            label: 'Created Ms',
            accessor: 'created_ms',
            selected: true,
        },
        {
            label: 'Approved at',
            accessor: 'approved_at',
            selected: true,
        },
        {
            label: 'Modified',
            accessor: 'modified',
            selected: true,
        },
        {
            label: 'Modified Ms',
            accessor: 'modified_ms',
            selected: true,
        },
        {
            label: 'Active at',
            accessor: 'active_at',
            selected: true,
        },
        {
            label: 'Due Date',
            accessor: 'expired_at',
            selected: true,
        },
        {
            label: 'Payback liens executed',
            accessor: 'payback_liens_executed',
            selected: true,
        },
        {
            label: 'Paid at',
            accessor: 'paid_at',
            selected: true,
        },
        {
            label: 'Meta',
            accessor: 'meta',
            modifier: function (value) {
                return JSON.stringify(JSON.parse(value), null, 2);
            },
            selected: true,
        },
        {
            label: 'Rejected at',
            accessor: 'rejected_at',
            selected: true,
        },
        {
            label: 'Signature',
            accessor: 'signature',
            selected: true,
        },
    ];
    // fetch all funding requests
    var _a = useQueryModel('funding_request.read_all', {}), data = _a.data, isLoading = _a.isLoading;
    var fundingRequest = React.useMemo(function () {
        return (data === null || data === void 0 ? void 0 : data.length)
            ? data.sort(function (a, b) {
                return Number(a.created_ms) < Number(b.created_ms) ? 1 : -1;
            })
            : [];
    }, [data]);
    var filteredFundingRequest = useFilter(fundingRequest, filters, {
        search: function (funding) {
            var _a;
            return basicSearch((_a = funding === null || funding === void 0 ? void 0 : funding.merchant) === null || _a === void 0 ? void 0 : _a.business_name, filters.search) ||
                basicSearch(funding === null || funding === void 0 ? void 0 : funding.status, filters.search) ||
                basicSearch(funding === null || funding === void 0 ? void 0 : funding.status, filters.status);
        },
        status: function (funding) {
            var dueState = getFundingOverdueState(funding);
            return ((filters.status === 'overdue' && dueState.isOverdue) ||
                (filters.status === 'due_today' &&
                    dueState.dueInDays !== null &&
                    dueState.dueInDays === 0) ||
                (filters.status === 'due_in_7_days' &&
                    dueState.dueInDays !== null &&
                    dueState.dueInDays <= 7 &&
                    dueState.dueInDays >= 0));
        },
    });
    var handleTabChange = function (index) {
        var selectedStatus = pageTab[index];
        if (selectedStatus) {
            router.switchQuery({ status: selectedStatus });
        }
        else {
            router.go();
        }
    };
    return (_jsxs(Layout, __assign({ meta: { title: 'Credit' } }, { children: [_jsx(ContentHeader, __assign({ heading: "Credit", border: 0 }, { children: _jsxs(Flex, __assign({ gridGap: 2 }, { children: [_jsx(Button, __assign({ onClick: function () { return router.replace('/funding-requests/reports'); }, variant: "outline", size: "sm" }, { children: "View Report" }), void 0), _jsx(Exporter, { filename: "obtainly-credit-".concat(formatUnixTime(getUnixTime(new Date()), 'dd-MM-yyyy')), data: filteredFundingRequest, columns: columns, isDisabled: !(filteredFundingRequest === null || filteredFundingRequest === void 0 ? void 0 : filteredFundingRequest.length) }, void 0)] }), void 0) }), void 0), _jsxs(Card, { children: [_jsx(PageTabs, { index: activeTab, onChange: handleTabChange, tabs: pageTab.map(function (page) {
                            return capitalizeString(page.split('_').join(' '));
                        }) }, void 0), _jsx(CardBody, { children: _jsx(SearchInput, { w: "full", value: filters.search || '', onChange: function (value) {
                                return router.switchQuery(__assign(__assign({}, filters), { search: value }));
                            }, placeholder: "Search credit", isDisabled: !(filteredFundingRequest === null || filteredFundingRequest === void 0 ? void 0 : filteredFundingRequest.length) }, void 0) }, void 0), _jsxs(CardBody, __assign({ p: 0, pt: 2 }, { children: [_jsx(ContentLoader, { isLoading: isLoading }, void 0), !isLoading && (_jsx(FundingRequestsTable, { fundingRequests: filteredFundingRequest || [] }, void 0))] }), void 0)] }, void 0)] }), void 0));
};
