import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from '@chakra-ui/react';
import { useQueryModel } from '@obtainly/http';
import { MerchantTag } from '@obtainly/models';
import { CardBody, ContentLoader } from '@obtainly/ui/web-shared';
import { formatCurrency } from '@obtainly/utils';
import { useAtom } from 'jotai';
import { useMemo } from 'react';
import { distributorsAtom } from 'src/core/store/distributor';
import * as Yup from 'yup';
import { MerchantTagForm } from './MerchantTagForm';
export var MerchantCreditConfig = function (props) {
    var selectedDistributor = useAtom(distributorsAtom)[0].active;
    var merchantId = props.merchantId;
    var creditLimits = Array(10)
        .fill('_')
        .map(function (_, index) {
        var value = (index + 1) * Math.pow(10, 6);
        return {
            value: String(value),
            label: formatCurrency(value, { showCurrency: true, decimals: 0 }),
        };
    });
    var _a = useQueryModel('merchant.read', {
        _id: merchantId,
    }), merchants = _a.data, isLoading = _a.isLoading;
    var merchant = useMemo(function () {
        var _merchant = merchants === null || merchants === void 0 ? void 0 : merchants[0];
        return _merchant;
    }, [merchants]);
    return (_jsxs(_Fragment, { children: [_jsx(ContentLoader, { isLoading: isLoading, contentUnavailable: !merchant, errorMessage: "Customer not found" }, void 0), !isLoading && !!merchant && (_jsx(CardBody, { children: (selectedDistributor === null || selectedDistributor === void 0 ? void 0 : selectedDistributor._id) ? (_jsx(Box, { children: _jsx(MerchantTagForm, { label: "Credit Limit", tag: MerchantTag.CREDIT_LIMIT, merchantId: merchantId, distributor: selectedDistributor, options: creditLimits, helperText: "Set maximum credit ".concat(merchant.business_name, " can receive"), valueFieldSchema: Yup.string().required('Credit limit is required'), valueFieldPrepend: "NGN", valueFieldType: "text", valueFieldTransform: {
                            encode: function (value) { return Number(value); },
                            decode: function (value) { return String(value); },
                        } }, void 0) }, void 0)) : (_jsx(ContentLoader, { contentUnavailable: true, errorMessage: "Select a supplier" }, void 0)) }, void 0))] }, void 0));
};
