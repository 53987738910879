export var OrderType;
(function (OrderType) {
    OrderType["Delivery"] = "delivery";
    OrderType["Pickup"] = "pickup";
    OrderType["Both"] = "both";
})(OrderType || (OrderType = {}));
export var OrderTag;
(function (OrderTag) {
    OrderTag["Draft"] = "draft";
    OrderTag["Pending"] = "pending";
    OrderTag["Processing"] = "processing";
    OrderTag["InTransit"] = "in-transit";
    OrderTag["Delivered"] = "delivered";
    OrderTag["Cancelled"] = "cancelled";
    // a special tag for reversing processed orders back to pending
    // never should it be passed to backend
    OrderTag["Modify"] = "modify";
})(OrderTag || (OrderTag = {}));
export var OrderPaymentStatus;
(function (OrderPaymentStatus) {
    OrderPaymentStatus["Paid"] = "paid";
    OrderPaymentStatus["Unpaid"] = "un-paid";
})(OrderPaymentStatus || (OrderPaymentStatus = {}));
export var OrderMetaTag;
(function (OrderMetaTag) {
    OrderMetaTag["Discount"] = "DISCOUNT";
    OrderMetaTag["DeliveredAt"] = "DELIVERED_AT";
    OrderMetaTag["Note"] = "NOTE";
    OrderMetaTag["MerchantLocation"] = "MERCHANT_LOCATION";
    OrderMetaTag["PlacedBy"] = "placed_by";
    OrderMetaTag["CancelledBy"] = "cancelled_by";
})(OrderMetaTag || (OrderMetaTag = {}));
