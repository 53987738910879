export var MerchantTag;
(function (MerchantTag) {
    MerchantTag["ALLOWED_ORDER_TYPE"] = "allowed_order_type";
    MerchantTag["PRICE_CLASS"] = "price_class";
    MerchantTag["CREDIT_LIMIT"] = "credit_limit";
    MerchantTag["CREDIT_DURATION"] = "credit_duration";
    MerchantTag["CREDIT_AMOUNT"] = "credit_amount";
})(MerchantTag || (MerchantTag = {}));
export var MerchantDocumentTag;
(function (MerchantDocumentTag) {
    MerchantDocumentTag["BVN"] = "bvn";
    MerchantDocumentTag["IdentityCard"] = "identity_card";
    MerchantDocumentTag["Passport"] = "passport";
    MerchantDocumentTag["UtilityBill"] = "utility_bill";
    MerchantDocumentTag["BankStatement"] = "bank_statement";
    MerchantDocumentTag["CACRegistrationType"] = "registration_type";
    MerchantDocumentTag["CACCertificate"] = "cac_certificate";
    MerchantDocumentTag["CACStatusReport"] = "cac_status_report";
    MerchantDocumentTag["CACMemorandum"] = "cac_memorandum";
    MerchantDocumentTag["CACShareAllotmentPage"] = "cac_share_allotment_page";
    MerchantDocumentTag["AuthLetter"] = "authorization_letter";
    MerchantDocumentTag["Cheque"] = "cheque";
    MerchantDocumentTag["Remita"] = "remita";
    MerchantDocumentTag["Others"] = "other_documents";
    // old
    MerchantDocumentTag["CACDocumentation"] = "cac_documentation";
    MerchantDocumentTag["CACArticleOfAssociation"] = "cac_article_of_association";
    MerchantDocumentTag["CACForm"] = "cac_form";
})(MerchantDocumentTag || (MerchantDocumentTag = {}));
export var MerchantDocumentStatus;
(function (MerchantDocumentStatus) {
    MerchantDocumentStatus["Verified"] = "verified";
    MerchantDocumentStatus["Unverified"] = "unverified";
})(MerchantDocumentStatus || (MerchantDocumentStatus = {}));
export var CACRegistrationType;
(function (CACRegistrationType) {
    CACRegistrationType["Business"] = "business";
    CACRegistrationType["Company"] = "company";
})(CACRegistrationType || (CACRegistrationType = {}));
