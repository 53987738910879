import pluralize from 'pluralize';
export var quickPluralize = function (word, count, hideCount // show only word
) {
    return hideCount
        ? pluralize(word, count)
        : "".concat(length, " ").concat(pluralize(word, count));
};
export var capitalizeString = function (word) {
    return (String(word).charAt(0).toUpperCase() + String(word).slice(1).toLowerCase());
};
// this function helps to secure account number
export function obscureString(data, visibleCount) {
    if (visibleCount === void 0) { visibleCount = 4; }
    return "".concat(new Array(data.length - visibleCount).join('x')).concat(data.slice(-visibleCount));
}
export function trimHtmlTemplate(htmlString) {
    var tags = [
        {
            open: '<!-- =============== START HEADER =============== -->',
            close: '<!-- =============== END HEADER =============== -->',
        },
        {
            open: '<!-- =============== START FOOTER =============== -->',
            close: '<!-- =============== END FOOTER =============== -->',
        },
    ];
    function removeTagAndContent(content, tag) {
        var output = content;
        var openingTagIndex = output.indexOf(tag.open);
        var closingTagIndex = output.indexOf(tag.close);
        if (openingTagIndex > 1 && closingTagIndex > 1) {
            output = [
                output.slice(0, openingTagIndex),
                output.slice(closingTagIndex + tag.close.length),
            ].join('');
        }
        return output;
    }
    var finalCut = htmlString;
    tags.forEach(function (tag) {
        finalCut = removeTagAndContent(finalCut, tag);
    });
    return finalCut;
}
