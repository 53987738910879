var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Flex, SimpleGrid, Text } from '@chakra-ui/layout';
import { Stat, StatLabel, StatNumber } from '@chakra-ui/stat';
import { fromUnixTime } from 'date-fns';
import React from 'react';
import { FiBarChart2 } from 'react-icons/fi';
import { useOrderConfig } from '@obtainly/hooks';
import { useQueryModel } from '@obtainly/http';
import { CardHeader } from '@obtainly/ui/web-shared';
import { formatCurrency } from '@obtainly/utils';
import { Card, CardBody, ContentLoader, MonthYearPicker, } from 'src/core/components';
import { dateAtom, useAtom } from 'src/core/store';
export var AdministratorMetricCard = function () {
    var _a = useAtom(dateAtom), selectedDate = _a[0], setSelectedDate = _a[1];
    var payload = React.useMemo(function () {
        var date = fromUnixTime(selectedDate);
        var month = date.getMonth() + 1;
        var year = date.getFullYear();
        return {
            month: month,
            year: year,
        };
    }, [selectedDate]);
    var _b = useQueryModel('administrator.dashboard', payload, { enabled: !!payload.year && !!payload.month }), data = _b.data, isLoading = _b.isLoading;
    var isOrderActivated = useOrderConfig().isOrderActivated;
    return (_jsx(_Fragment, { children: _jsxs(Card, { children: [_jsx(ContentLoader, { isLoading: isLoading }, void 0), !isLoading && (_jsxs(_Fragment, { children: [_jsx(CardBody, __assign({ pt: 3, pb: 2 }, { children: _jsxs(Flex, __assign({ alignItems: "center", gridGap: 2, ml: 5 }, { children: [_jsx(FiBarChart2, {}, void 0), _jsxs(Flex, __assign({ alignItems: "center", gridGap: 2 }, { children: [_jsx(Text, __assign({ color: "gray.600" }, { children: "Here's how you are doing this month " }), void 0), _jsx(MonthYearPicker, { value: selectedDate, onChange: setSelectedDate, isFullWidth: false }, void 0)] }), void 0)] }), void 0) }), void 0), isOrderActivated !== null && isOrderActivated !== void 0 ? isOrderActivated : (_jsxs(CardBody, __assign({ borderTop: "1px solid", borderColor: "gray.100" }, { children: [_jsx(CardHeader, { heading: "Order Metrics", pt: 0, pl: 0, mt: "0", pb: 4 }, void 0), _jsx(SimpleGrid, __assign({ columns: [2, 4], alignItems: "center", gridGap: [6, 4] }, { children: ['all', 'pending', 'paid', 'delivered'].map(function (key) {
                                        var _a, _b, _c;
                                        return (_jsx(Stat, { children: _jsxs(Flex, __assign({ alignItems: "center" }, { children: [_jsx(Box, { w: "4px", h: "90px", borderRadius: "md", bg: "".concat(key === 'all'
                                                            ? 'primary'
                                                            : key === 'pending'
                                                                ? 'warning'
                                                                : key === 'paid'
                                                                    ? 'success'
                                                                    : 'info', ".100") }, void 0), _jsxs(Box, __assign({ ml: "10px" }, { children: [_jsx(StatLabel, __assign({ textTransform: "capitalize" }, { children: key }), void 0), _jsx(StatNumber, { children: (_a = data === null || data === void 0 ? void 0 : data.order_count_mtd) === null || _a === void 0 ? void 0 : _a[key] }, void 0), _jsxs(Text, __assign({ fontSize: "sm", color: "gray.600" }, { children: ["Value: NGN", ' ', formatCurrency((_b = data === null || data === void 0 ? void 0 : data.order_value_mtd) === null || _b === void 0 ? void 0 : _b[key], {
                                                                        compact: true,
                                                                    })] }), void 0), _jsxs(Text, __assign({ fontSize: "sm", color: "gray.600" }, { children: ["Merchants: ", (_c = data === null || data === void 0 ? void 0 : data.merchants) === null || _c === void 0 ? void 0 : _c[key]] }), void 0)] }), void 0)] }), void 0) }, key));
                                    }) }), void 0)] }), void 0)), _jsxs(CardBody, __assign({ borderTop: "1px solid", borderColor: "gray.100" }, { children: [_jsx(CardHeader, { heading: "Credit Metrics", pt: 0, pl: 0, mt: "0", pb: 4 }, void 0), _jsx(SimpleGrid, __assign({ columns: [2, 4], alignItems: "center", gridGap: [6, 4] }, { children: ['all', 'active', 'paid', 'overdue'].map(function (key) {
                                        var _a, _b, _c;
                                        return (_jsx(Stat, { children: _jsxs(Flex, __assign({ alignItems: "center" }, { children: [_jsx(Box, { w: "4px", h: "90px", borderRadius: "md", bg: "".concat(key === 'all'
                                                            ? 'primary'
                                                            : key === 'overdue'
                                                                ? 'warning'
                                                                : key === 'paid'
                                                                    ? 'success'
                                                                    : 'info', ".100") }, void 0), _jsxs(Box, __assign({ ml: "10px" }, { children: [_jsx(StatLabel, __assign({ textTransform: "capitalize" }, { children: key }), void 0), _jsx(StatNumber, { children: (_a = data === null || data === void 0 ? void 0 : data.credit_count_mtd) === null || _a === void 0 ? void 0 : _a[key] }, void 0), _jsxs(Text, __assign({ fontSize: "sm", color: "gray.600" }, { children: ["Value: NGN", ' ', formatCurrency((_b = data === null || data === void 0 ? void 0 : data.credit_value_mtd) === null || _b === void 0 ? void 0 : _b[key], {
                                                                        compact: true,
                                                                    })] }), void 0), _jsxs(Text, __assign({ fontSize: "sm", color: "gray.600" }, { children: ["Merchants: ", (_c = data === null || data === void 0 ? void 0 : data.credit_merchants) === null || _c === void 0 ? void 0 : _c[key]] }), void 0)] }), void 0)] }), void 0) }, key));
                                    }) }), void 0)] }), void 0)] }, void 0))] }, void 0) }, void 0));
};
