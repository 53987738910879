import { Inflows } from './pages';
export var inflowRoutes = [
    {
        id: 'inflows',
        path: '/inflows',
        component: Inflows,
        requiresAuth: true,
        exact: true,
    },
];
export * from './components';
