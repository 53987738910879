var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-console */
import { Box, Button, Flex, Text, VStack } from '@chakra-ui/react';
import { useToast } from '@obtainly/hooks';
import { useMutateModel, useQueryModel } from '@obtainly/http';
import { Card, CardBody, CardHeader } from '@obtainly/ui/web-shared';
import { useAtom } from 'jotai';
import React, { useMemo, useState } from 'react';
import { distributorsAtom } from 'src/core/store/distributor';
export var SecondAccount = function (_a) {
    var _b;
    var merchant = _a.merchant;
    var toast = useToast().toast;
    var selectedDistributor = useAtom(distributorsAtom)[0].active;
    var _c = useState(false), loading = _c[0], setLoading = _c[1];
    var doCreate = useMutateModel('merchant.generate_payback_account');
    var _d = useQueryModel('merchant.read_payback_accounts', {
        _id: merchant._id,
        distributor_id: selectedDistributor === null || selectedDistributor === void 0 ? void 0 : selectedDistributor.distributor_id,
    }, { enabled: !!merchant._id && !!selectedDistributor }), merchants = _d.data, fetchingAccounts = _d.isLoading, refetch = _d.refetch;
    var virtualAccount = useMemo(function () {
        var _a;
        if (!merchants)
            return null;
        var merchantsMeta = (_a = merchants[0]) === null || _a === void 0 ? void 0 : _a.meta;
        return JSON.parse(merchantsMeta || 'null');
    }, [merchants]);
    var handleCreateAccount = function () {
        setLoading(true);
        doCreate.mutate({
            _id: merchant._id,
            distributor_id: selectedDistributor === null || selectedDistributor === void 0 ? void 0 : selectedDistributor.distributor_id,
        }, {
            onSuccess: function () {
                refetch();
                toast({ status: 'success', description: 'Account created saved' });
                setLoading(false);
            },
            onError: function (_a) {
                var message = _a.message;
                toast({ status: 'error', description: message });
                setLoading(false);
            },
        });
    };
    return (_jsxs(Card, __assign({ mt: "1.5rem" }, { children: [_jsx(CardHeader, { heading: !merchants ? 'Generate Payback Account' : 'Payback Account', mb: "0.5rem" }, void 0), _jsx(CardBody, { children: !merchants && merchant.distributors ? (_jsxs(_Fragment, { children: [_jsx(Text, __assign({ textAlign: "center", mb: "1rem" }, { children: "Create a Second Account number to make payments with transfer" }), void 0), (selectedDistributor === null || selectedDistributor === void 0 ? void 0 : selectedDistributor.distributor_id) && (_jsx(Button, __assign({ mt: 3, colorScheme: "primary", onClick: function () { return handleCreateAccount(); }, w: "full", isLoading: loading, disabled: loading || fetchingAccounts }, { children: "Create Second Account" }), void 0))] }, void 0)) : (_jsxs(VStack, __assign({ alignItems: "flex-start" }, { children: [_jsxs(Box, { children: [_jsx(Text, __assign({ fontSize: "sm", color: "gray.600", fontWeight: "medium" }, { children: "Bank Name" }), void 0), _jsx(Text, { children: (_b = virtualAccount === null || virtualAccount === void 0 ? void 0 : virtualAccount.bank) === null || _b === void 0 ? void 0 : _b.name }, void 0)] }, void 0), _jsxs(Box, __assign({ w: "100%" }, { children: [_jsx(Text, __assign({ fontSize: "sm", color: "gray.600", fontWeight: "medium" }, { children: "Account No." }), void 0), _jsx(Flex, __assign({ alignItems: "center", justifyContent: "space-between" }, { children: _jsx(Text, { children: virtualAccount === null || virtualAccount === void 0 ? void 0 : virtualAccount.account_number }, void 0) }), void 0)] }), void 0), _jsxs(Box, { children: [_jsx(Text, __assign({ fontSize: "sm", color: "gray.600", fontWeight: "medium" }, { children: "Account Name" }), void 0), _jsx(Text, { children: virtualAccount === null || virtualAccount === void 0 ? void 0 : virtualAccount.account_name }, void 0)] }, void 0)] }), void 0)) }, void 0)] }), void 0));
};
