var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Flex, Heading } from '@chakra-ui/react';
import { DistributorPayments, MonthYearPicker } from '@obtainly/ui/web-shared';
import { formatCurrency, formatUnixTime } from '@obtainly/utils';
import { fromUnixTime, getUnixTime } from 'date-fns';
import { useAtom } from 'jotai';
import React from 'react';
import { Exporter } from 'src/core/components';
import { dateAtom } from 'src/core/store';
var exportColumns = [
    {
        label: 'Customer',
        accessor: 'description',
        selected: true,
        modifier: function (value, row) {
            var _a;
            var description = value;
            try {
                var json = JSON.parse(description);
                description = ((_a = json === null || json === void 0 ? void 0 : json.merchant) === null || _a === void 0 ? void 0 : _a.business_name) || '-';
            }
            catch (error) {
                description = '-';
            }
            return row.value < 0 ? 'Obtainly' : description;
        },
    },
    {
        label: 'Payment',
        accessor: 'value',
        selected: true,
        modifier: function (value) { return formatCurrency(value); },
    },
    {
        label: 'Date',
        accessor: 'created',
        selected: true,
        modifier: function (value) { return formatUnixTime(value); },
    },
];
export var Payments = function (_a) {
    var supplierId = _a.supplierId;
    var _b = React.useState([]), exportableData = _b[0], setExportableData = _b[1];
    var _c = useAtom(dateAtom), selectedDate = _c[0], setSelectedDate = _c[1];
    var payload = React.useMemo(function () {
        var date = fromUnixTime(selectedDate);
        var month = date.getMonth() + 1;
        var year = date.getFullYear();
        return {
            _id: supplierId,
            distributor_id: supplierId,
            month: month,
            year: year,
        };
    }, [supplierId, selectedDate]);
    return (_jsxs(_Fragment, { children: [_jsxs(Flex, __assign({ border: 0, alignItems: "center", justifyContent: "space-between" }, { children: [_jsx(Heading, __assign({ fontSize: "xl" }, { children: "Payments" }), void 0), _jsxs(Flex, __assign({ gridGap: 2 }, { children: [_jsx(MonthYearPicker, { value: selectedDate, onChange: setSelectedDate, isFullWidth: false }, void 0), _jsx(Exporter, { filename: "obtainly-payments-".concat(getUnixTime(new Date())), data: exportableData, columns: exportColumns, isDisabled: !(exportableData === null || exportableData === void 0 ? void 0 : exportableData.length) }, void 0)] }), void 0)] }), void 0), _jsx(DistributorPayments, { queryData: payload, setExportableData: setExportableData }, void 0)] }, void 0));
};
