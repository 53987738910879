import React from 'react';
import { useHistory } from 'react-router';
import { buildQueryString } from '@obtainly/utils';
export function useRouter(routeId, routes) {
    if (routeId === void 0) { routeId = ''; }
    if (routes === void 0) { routes = []; }
    var history = useHistory();
    var route = React.useCallback(function (route, params, query) {
        if (params === void 0) { params = {}; }
        var found = routes.find(function (_a) {
            var id = _a.id;
            return id === route;
        });
        route = found ? found.path : route;
        if (route && params && Object.keys(params).length) {
            Object.keys(params).forEach(function (key) {
                route = route.replace(new RegExp(":".concat(key), 'g'), params[key]);
            });
        }
        return query ? "".concat(route).concat(buildQueryString(query)) : route;
    }, [routes]);
    var getLink = function () {
        var params = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            params[_i] = arguments[_i];
        }
        if ((params === null || params === void 0 ? void 0 : params.length) === 3) {
            return route((params === null || params === void 0 ? void 0 : params[0]) || routeId, params === null || params === void 0 ? void 0 : params[1], params === null || params === void 0 ? void 0 : params[2]);
        }
        else if ((params === null || params === void 0 ? void 0 : params.length) === 2) {
            return route((params === null || params === void 0 ? void 0 : params[0]) || routeId, params === null || params === void 0 ? void 0 : params[1]);
        }
        else {
            return route((params === null || params === void 0 ? void 0 : params[0]) || routeId);
        }
    };
    var navigateMethod = function (method) {
        return function () {
            var params = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                params[_i] = arguments[_i];
            }
            history === null || history === void 0 ? void 0 : history[method](getLink.apply(void 0, params));
        };
    };
    var routerMethods = {
        go: navigateMethod('push'),
        replace: navigateMethod('replace'),
        goBack: function () { return history.goBack(); },
        switchParams: function () { return ({}); },
        switchQuery: function () { return ({}); },
        getLink: function () {
            var params = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                params[_i] = arguments[_i];
            }
            return getLink.apply(void 0, params);
        },
    };
    if (routeId) {
        routerMethods.switchParams = function (params) {
            history.push(route(routeId, params, null));
        };
        routerMethods.switchQuery = function (query) {
            history.push(route(routeId, null, query));
        };
    }
    return routerMethods;
}
