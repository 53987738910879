var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Button, HStack, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useDisclosure, } from '@chakra-ui/react';
import { useMutateModel, useQueryModel } from '@obtainly/http';
import { MailingListType } from '@obtainly/models';
import { ContentLoader, TableMaker } from 'src/core/components';
import { MailingListForm } from './MailingListForm';
export var MailingListTable = function (_a) {
    var mailList = _a.mailList, refetchList = _a.refetchList;
    var _b = React.useState(null), selected = _b[0], setSelected = _b[1];
    var _c = React.useState(null), showModal = _c[0], setShowModal = _c[1];
    var _d = useDisclosure(), isOpen = _d.isOpen, onOpen = _d.onOpen, onClose = _d.onClose;
    var finalRef = React.useRef(null);
    var deleteMailingList = useMutateModel('mailing_list.delete');
    var editList = React.useCallback(function (list) {
        setShowModal('update_mail');
        setSelected(list);
    }, []);
    var deleteList = React.useCallback(function (list) {
        setShowModal('delete_mail');
        setSelected(list);
        onOpen();
    }, [onOpen]);
    var handleDeleteMailList = React.useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!selected) return [3 /*break*/, 2];
                    return [4 /*yield*/, deleteMailingList.mutateAsync({ _id: selected._id || '' })];
                case 1:
                    _a.sent();
                    refetchList();
                    _a.label = 2;
                case 2:
                    onClose();
                    return [2 /*return*/];
            }
        });
    }); }, [deleteMailingList, onClose, refetchList, selected]);
    var columns = React.useMemo(function () {
        return [
            { Header: '#', Cell: function (_a) {
                    var row = _a.row;
                    return Number(row.id) + 1;
                } },
            {
                Header: 'Type',
                accessor: 'type',
            },
            {
                Header: 'Identifier',
                accessor: 'identifier',
                maxWidth: 100,
                Cell: function (_a) {
                    var _b, _c;
                    var cell = _a.cell, row = _a.row;
                    var identifiers = String(cell.value).split('|');
                    return identifiers.length > 1 ? (_jsxs(_Fragment, { children: [_jsx(RenderIdentifier, { type: MailingListType.Distributor, identifier: identifiers[0] || '' }, void 0), _jsx(RenderIdentifier, { type: MailingListType.Merchant, identifier: identifiers[1] || '' }, void 0)] }, void 0)) : (_jsx(RenderIdentifier, { type: ((_b = row === null || row === void 0 ? void 0 : row.original) === null || _b === void 0 ? void 0 : _b.type) || '', identifier: ((_c = row === null || row === void 0 ? void 0 : row.original) === null || _c === void 0 ? void 0 : _c.identifier) || '' }, void 0));
                },
            },
            {
                Header: 'Tag',
                accessor: 'tag',
            },
            {
                Header: 'Channel',
                accessor: 'channel',
            },
            {
                Header: 'Recipients',
                accessor: 'recipients',
            },
            {
                Header: 'Status',
                accessor: 'status',
            },
            {
                Header: 'Action',
                id: 'action',
                Cell: function (_a) {
                    var row = _a.row;
                    return (_jsxs(HStack, { children: [_jsx(Button, __assign({ size: "sm", onClick: function () { return editList(row.original); } }, { children: "Edit" }), void 0), _jsx(Button, __assign({ colorScheme: "red", variant: 'outline', size: "sm", onClick: function () { return deleteList(row.original); } }, { children: "Delete" }), void 0)] }, void 0));
                },
            },
        ];
    }, [deleteList, editList]);
    return (_jsxs(_Fragment, { children: [!(mailList === null || mailList === void 0 ? void 0 : mailList.length) && (_jsx(ContentLoader, { errorSummary: "No mailing list found", contentUnavailable: true }, void 0)), !!(mailList === null || mailList === void 0 ? void 0 : mailList.length) && (_jsx(TableMaker, { data: mailList.sort(function (a, b) { return a.tag.localeCompare(b.tag); }), columns: columns, tableProps: { isFit: false }, route: { id: 'mailing-list' } }, void 0)), _jsx(MailingListForm, { action: "update", isOpen: !!selected && showModal === 'update_mail', mailingList: selected, onCancel: function () { return setSelected(null); }, onSuccess: function () {
                    setSelected(null);
                    refetchList();
                } }, void 0), _jsxs(Modal, __assign({ finalFocusRef: finalRef, isOpen: isOpen && showModal === 'delete_mail', onClose: onClose }, { children: [_jsx(ModalOverlay, {}, void 0), _jsxs(ModalContent, { children: [_jsx(ModalHeader, { children: "Delete Mailing List" }, void 0), _jsx(ModalCloseButton, {}, void 0), _jsx(ModalBody, { children: _jsx(Text, { children: "Are you sure you want to delete this mail list? This action will permanently remove the list. This cannot be undone." }, void 0) }, void 0), _jsxs(ModalFooter, { children: [_jsx(Button, __assign({ mr: 3, onClick: onClose, variant: "ghost" }, { children: "Close" }), void 0), _jsx(Button, __assign({ onClick: handleDeleteMailList, isLoading: deleteMailingList.isLoading, colorScheme: "red" }, { children: "Delete" }), void 0)] }, void 0)] }, void 0)] }), void 0)] }, void 0));
};
var RenderIdentifier = function (_a) {
    var type = _a.type, identifier = _a.identifier;
    var isValidType = ['merchant', 'distributor'].includes(type);
    var _b = useQueryModel("".concat(type, ".read"), { _id: identifier }, { enabled: !!identifier && isValidType }), data = _b.data, isLoading = _b.isLoading;
    var identifier_name = React.useMemo(function () {
        var _a, _b;
        return !(data === null || data === void 0 ? void 0 : data.length) ? identifier : ((_a = data[0]) === null || _a === void 0 ? void 0 : _a.business_name) || ((_b = data[0]) === null || _b === void 0 ? void 0 : _b.name);
    }, [data, identifier]);
    return (_jsxs(_Fragment, { children: [_jsx(Text, { children: isLoading ? '...' : identifier_name }, void 0), isValidType && (_jsx(Text, __assign({ fontSize: "xs", color: "gray.700" }, { children: identifier }), void 0))] }, void 0));
};
