var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Text } from '@chakra-ui/layout';
import { ErrorMessage, useField } from 'formik';
import { FormField } from './FormField';
export var FormikField = function (props) {
    var _a = useField(props), field = _a[0], meta = _a[1], helper = _a[2];
    var formikProps = __assign(__assign({}, field), { isInvalid: !!meta.touched && !!meta.error });
    if (props.as === 'autocomplete') {
        formikProps.onBlur = function () {
            helper.setTouched(true);
        };
        formikProps.onChange = function (selected) {
            helper.setValue(selected);
        };
    }
    formikProps = __assign(__assign({}, formikProps), props);
    return (_jsxs(_Fragment, { children: [_jsx(FormField, __assign({}, formikProps), void 0), _jsx(Text, __assign({ fontSize: "sm", color: "red.600", mt: 1 }, { children: _jsx(ErrorMessage, { name: props.name, component: "span" }, void 0) }), void 0)] }, void 0));
};
export default FormikField;
