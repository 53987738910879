var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Text, Button, Box, Flex } from '@chakra-ui/react';
import { Menu, MenuButton, MenuList } from '@chakra-ui/menu';
import { FiSliders } from 'react-icons/fi';
var FilterMenuLabel = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return (_jsx(Text, __assign({ as: "label", fontSize: "md", display: "block", fontWeight: "medium", mb: 1 }, props, { children: children }), void 0));
};
var FilterMenuSection = function (_a) {
    var children = _a.children, label = _a.label;
    return (_jsxs(Box, __assign({ mb: 15 }, { children: [_jsx(FilterMenuLabel, { children: label }, void 0), children] }), void 0));
};
/**
 * Renders dropdown menu for table filters.
 */
var FilterMenu = /** @class */ (function (_super) {
    __extends(FilterMenu, _super);
    function FilterMenu() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FilterMenu.prototype.render = function () {
        var _a, _b, _c, _d, _e;
        return (_jsxs(Menu, __assign({ placement: "bottom-end" }, { children: [_jsx(MenuButton, __assign({ as: Button, size: "sm", isTruncated: true, variant: "outline", leftIcon: _jsx(FiSliders, { size: 16 }, void 0), textTransform: "capitalize", _expanded: { bg: 'white' } }, this.props.toggleButtonProps, { children: _jsxs(Flex, __assign({ alignItems: "center", gridGap: 2 }, { children: [_jsx(Text, { children: ((_a = this.props) === null || _a === void 0 ? void 0 : _a.toggleButtonContent) || 'Filter' }, void 0), ((_b = this.props) === null || _b === void 0 ? void 0 : _b.activeCount) ? (_jsxs(Text, __assign({ as: "span", color: "primary.600", fontWeight: "bold" }, { children: [' ', this.props.activeCount] }), void 0)) : null] }), void 0) }), void 0), _jsx(MenuList, __assign({ w: "300px" }, this.props.menuListProps, { zIndex: 90 }, { children: _jsxs(Box, __assign({ my: "15px", mx: "20px" }, { children: [(_c = this.props) === null || _c === void 0 ? void 0 : _c.children, _jsxs(Flex, __assign({ mt: 30, justifyContent: "space-between" }, { children: [((_d = this.props) === null || _d === void 0 ? void 0 : _d.resetButtonProps) ? (_jsx(Button, __assign({ size: "sm", variant: "outline", title: "Reset Filters" }, this.props.resetButtonProps, { children: "Reset" }), void 0)) : (_jsx("div", {}, void 0)), ((_e = this.props) === null || _e === void 0 ? void 0 : _e.refreshButtonProps) && (_jsx(Button, __assign({ size: "sm", variant: "solid", colorScheme: "primary", loadingText: "Refreshing" }, this.props.refreshButtonProps, { children: "Done" }), void 0))] }), void 0)] }), void 0) }), void 0)] }), void 0));
    };
    FilterMenu.Label = FilterMenuLabel;
    FilterMenu.Section = FilterMenuSection;
    return FilterMenu;
}(React.Component));
export { FilterMenu };
