var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useQueryModel } from '@obtainly/http';
import { basicSearch } from '@obtainly/utils';
import { useFilter, useQueryStringParse } from '@obtainly/hooks';
import { useRouter } from 'src/core/hooks';
import { Card, CardBody, ContentHeader, ContentLoader, Layout, SearchInput, } from 'src/core/components';
import { ConfigForm } from '../components';
import { Divider, VStack } from '@chakra-ui/react';
import { orderBy } from 'lodash';
export var Configs = function () {
    var router = useRouter('configs');
    var filters = useQueryStringParse(['search'], ['page']);
    var _a = useQueryModel('config.read_all', {}), data = _a.data, isLoading = _a.isLoading, refetch = _a.refetch;
    var configs = React.useMemo(function () {
        return (data === null || data === void 0 ? void 0 : data.length) ? data : [];
    }, [data]);
    var filteredConfigurations = useFilter(orderBy(configs, 'created', 'desc'), filters, {
        search: function (config) {
            return basicSearch(config.key, filters.search) ||
                basicSearch(config.value, filters.search);
        },
    });
    return (_jsxs(Layout, __assign({ meta: { title: 'Configurations' } }, { children: [_jsx(ContentHeader, { heading: "Configurations", border: 0 }, void 0), _jsxs(Card, { children: [_jsx(CardBody, { children: _jsx(SearchInput, { value: filters.search || '', onChange: function (value) {
                                return router.switchQuery(__assign(__assign({}, filters), { search: value }));
                            }, placeholder: "Search configs", isDisabled: !(configs === null || configs === void 0 ? void 0 : configs.length) }, void 0) }, void 0), _jsxs(CardBody, __assign({ pt: 2 }, { children: [_jsx(ContentLoader, { isLoading: isLoading }, void 0), !isLoading && (_jsxs(VStack, __assign({ align: "stretch", divider: _jsx(Divider, {}, void 0) }, { children: [_jsx(ConfigForm, { type: "create", refetch: refetch }, void 0), filteredConfigurations.map(function (config) {
                                        return (_jsx(ConfigForm, { type: "update", config: config, refetch: refetch }, config));
                                    })] }), void 0))] }), void 0)] }, void 0)] }), void 0));
};
