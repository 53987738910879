/**
 *
 * @param searchIn
 * @param searchFor
 */
export var basicSearch = function (searchIn, searchFor) {
    if (searchIn && typeof searchIn === 'string') {
        var regex = void 0;
        try {
            regex = new RegExp(searchFor, 'i');
        }
        catch (error) {
            regex = null;
        }
        return regex ? searchIn.search(regex) >= 0 : false;
    }
    return false;
};
