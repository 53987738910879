var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { FormLabel, Grid, Radio, RadioGroup } from '@chakra-ui/react';
import { useField } from 'formik';
import React from 'react';
export var FormikRadioGroup = function (props) {
    var label = props.label, options = props.options, onChange = props.onChange, gridProps = props.gridProps, rest = __rest(props, ["label", "options", "onChange", "gridProps"]);
    var field = useField(rest)[0];
    return (_jsxs(_Fragment, { children: [!!label && _jsx(FormLabel, __assign({ mb: 0 }, { children: label }), void 0), _jsx(RadioGroup, __assign({}, field, { id: props.name }, rest, { children: _jsx(Grid, __assign({ gap: 3, flexWrap: "wrap" }, gridProps, { children: options.map(function (_a) {
                        var label = _a.label, value = _a.value;
                        return (_jsx(Radio, __assign({}, field, { onChange: function (evt) {
                                var newValue = evt.target.value;
                                onChange ? onChange(newValue) : field.onChange(evt);
                            }, value: value, colorScheme: "primary" }, { children: label }), value));
                    }) }), void 0) }), void 0)] }, void 0));
};
