var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Flex, Text, VStack } from '@chakra-ui/react';
var PasswordValidation;
(function (PasswordValidation) {
    PasswordValidation["Length"] = "length";
    PasswordValidation["Letter"] = "letter";
    PasswordValidation["LowercaseCharacter"] = "lowercaseCharacter";
    PasswordValidation["UppercaseCharacter"] = "uppercaseCharacter";
    PasswordValidation["SpecialCharacter"] = "specialCharacter";
    PasswordValidation["Number"] = "number";
})(PasswordValidation || (PasswordValidation = {}));
var passwordValidationRegex = {
    letter: /[a-zA-Z]/,
    lowercaseCharacter: /[a-z]/,
    uppercaseCharacter: /[A-Z]/,
    specialCharacter: /\W/,
    number: /[0-9]/,
};
export var PasswordValidator = function (_a) {
    var password = _a.password, validations = _a.validations;
    return (_jsxs(VStack, __assign({ alignItems: "flex-start" }, { children: [_jsx(Text, __assign({ fontSize: "sm", color: "gray.800" }, { children: "Your password needs to be secure" }), void 0), Object.entries(validations).map(function (_a) {
                var rule = _a[0];
                return (_jsx(Validation, { rule: rule === PasswordValidation.Length
                        ? "At least 8 characters"
                        : rule === PasswordValidation.Letter
                            ? 'One letter'
                            : rule === PasswordValidation.LowercaseCharacter
                                ? 'One lowercase character'
                                : rule === PasswordValidation.UppercaseCharacter
                                    ? 'One uppercase character'
                                    : rule === PasswordValidation.SpecialCharacter
                                        ? 'One special character'
                                        : rule === PasswordValidation.Number
                                            ? 'One number'
                                            : '', isValid: rule === PasswordValidation.Length
                        ? password.trim().length >= 8
                        : rule === PasswordValidation.Letter
                            ? passwordValidationRegex.letter.test(password)
                            : rule === PasswordValidation.LowercaseCharacter
                                ? passwordValidationRegex.lowercaseCharacter.test(password)
                                : rule === PasswordValidation.UppercaseCharacter
                                    ? passwordValidationRegex.uppercaseCharacter.test(password)
                                    : rule === PasswordValidation.SpecialCharacter
                                        ? passwordValidationRegex.specialCharacter.test(password)
                                        : rule === PasswordValidation.Number
                                            ? passwordValidationRegex.number.test(password)
                                            : false }, rule));
            })] }), void 0));
};
var Validation = function (_a) {
    var rule = _a.rule, isValid = _a.isValid;
    return rule ? (_jsxs(Flex, __assign({ gap: "10px", alignItems: "center" }, { children: [_jsx(Box, { h: "8px", w: "8px", borderRadius: 999, bg: isValid ? 'success.500' : 'gray.300' }, void 0), _jsx(Text, __assign({ fontSize: "sm", color: "gray.700" }, { children: rule }), void 0)] }), void 0)) : null;
};
