export var TreeType;
(function (TreeType) {
    TreeType["City"] = "city";
    TreeType["Country"] = "country";
    TreeType["District"] = "district";
    TreeType["LGA"] = "local_government_area";
    TreeType["Province"] = "province";
    TreeType["Region"] = "region";
    TreeType["State"] = "state";
    TreeType["Town"] = "town";
})(TreeType || (TreeType = {}));
