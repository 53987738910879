var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-console */
import { Button } from '@chakra-ui/button';
import { Flex, VStack } from '@chakra-ui/layout';
import { Popover, PopoverBody, PopoverContent, PopoverTrigger, } from '@chakra-ui/popover';
import { useDisclosure } from '@chakra-ui/react';
import { useFilter, useQueryStringParse } from '@obtainly/hooks';
import { useQueryModel } from '@obtainly/http';
import { basicSearch, formatUnixTime } from '@obtainly/utils';
import { orderBy } from 'lodash';
import React from 'react';
import { FiMoreVertical } from 'react-icons/fi';
import { IoIosAdd } from 'react-icons/io';
import { Card, CardBody, ContentHeader, ContentLoader, Exporter, Layout, PageTabs, SearchInput, } from 'src/core/components';
import { useRouter } from 'src/core/hooks';
import { MerchantForm, MerchantsTable } from '../components';
export var merchantsExportableColumns = function (exclude) {
    var dateExportModifier = function (value) {
        return value ? String(formatUnixTime(value, 'dd MMM, yyyy - hh:mm a')) : '-';
    };
    var formatDistributors = function (distroMerchDict) {
        var _a;
        if (!distroMerchDict) {
            return '-';
        }
        var list = Object.values(distroMerchDict);
        return (_a = list === null || list === void 0 ? void 0 : list.map(function (distroMerch) { var _a; return (_a = distroMerch === null || distroMerch === void 0 ? void 0 : distroMerch.distributor) === null || _a === void 0 ? void 0 : _a.name; })) === null || _a === void 0 ? void 0 : _a.join(' | ');
    };
    var defaultColumns = [
        { label: 'Customer ID', accessor: '_id', selected: false },
        { label: 'Status', accessor: 'status', selected: true },
        { label: 'Business Name', accessor: 'business_name', selected: true },
        {
            label: 'Suppliers',
            accessor: 'distributors',
            selected: true,
            modifier: formatDistributors,
        },
        { label: 'First Name', accessor: 'firstname', selected: true },
        { label: 'Last Name', accessor: 'lastname', selected: true },
        { label: 'Phone', accessor: 'phone', selected: true },
        { label: 'Email', accessor: 'email', selected: true },
        {
            label: 'Joined on',
            accessor: 'created',
            selected: true,
            modifier: dateExportModifier,
        },
        {
            label: 'Last payment on',
            accessor: 'last_payment_on',
            selected: true,
            modifier: dateExportModifier,
        },
        {
            label: 'Last credit on',
            accessor: 'last_credit_on',
            selected: true,
            modifier: dateExportModifier,
        },
    ];
    return (exclude === null || exclude === void 0 ? void 0 : exclude.length)
        ? defaultColumns.filter(function (column) { return !exclude.includes(column.accessor); })
        : defaultColumns;
};
var PageTab;
(function (PageTab) {
    PageTab[PageTab["All"] = 0] = "All";
    PageTab[PageTab["Active"] = 1] = "Active";
    PageTab[PageTab["Inactive"] = 2] = "Inactive";
})(PageTab || (PageTab = {}));
export var Merchants = function () {
    var router = useRouter('merchants');
    var _a = useDisclosure(), isOpen = _a.isOpen, onOpen = _a.onOpen, onClose = _a.onClose;
    var _b = useQueryModel('merchant.read_all', {}), merchants = _b.data, isLoading = _b.isLoading;
    var filters = useQueryStringParse([], ['page']);
    var filteredMerchants = useFilter(orderBy(merchants, 'business_name', 'asc'), filters, {
        search: function (merchant) {
            return basicSearch(merchant.business_name, filters.search) ||
                basicSearch(merchant.firstname, filters.search) ||
                basicSearch(merchant.lastname, filters.search) ||
                basicSearch(merchant.email, filters.search) ||
                basicSearch(merchant.phone, filters.search);
        },
    });
    var activeFilters = Object.keys(filters);
    var activeTab = React.useMemo(function () {
        if (activeFilters.length === 1) {
            return filters.status === 'active'
                ? PageTab.Active
                : filters.status === 'inactive'
                    ? PageTab.Inactive
                    : PageTab.All;
        }
        return PageTab.All;
    }, [filters, activeFilters]);
    var handleTabChange = function (index) {
        if (index === PageTab.Active) {
            router.switchQuery({ status: 'active' });
        }
        else if (index === PageTab.Inactive) {
            router.switchQuery({ status: 'inactive' });
        }
        else {
            router.go();
        }
    };
    return (_jsxs(Layout, __assign({ meta: { title: 'Customers' } }, { children: [_jsx(ContentHeader, __assign({ heading: "Customers", border: 0 }, { children: _jsxs(Flex, __assign({ gridGap: 2 }, { children: [_jsxs(Popover, __assign({ placement: "bottom-end" }, { children: [_jsx(PopoverTrigger, { children: _jsx(Button, __assign({ leftIcon: _jsx(FiMoreVertical, {}, void 0), variant: "outline", size: "sm" }, { children: "More" }), void 0) }, void 0), _jsx(PopoverContent, __assign({ _focus: {
                                        border: 'none',
                                        borderWidth: '1px',
                                        borderStyle: 'solid',
                                        borderColor: 'gray.200',
                                    } }, { children: _jsx(PopoverBody, __assign({ p: "6" }, { children: _jsx(VStack, __assign({ spacing: 3, alignItems: "stretch", minH: "40" }, { children: _jsx(Exporter, { label: "Export", filename: "obtainly-merchants", data: filteredMerchants, columns: merchantsExportableColumns(), isDisabled: isLoading || !(filteredMerchants === null || filteredMerchants === void 0 ? void 0 : filteredMerchants.length) }, void 0) }), void 0) }), void 0) }), void 0)] }), void 0), _jsx(Button, __assign({ colorScheme: "primary", onClick: onOpen, leftIcon: _jsx(IoIosAdd, {}, void 0), size: "sm" }, { children: "Add Customer" }), void 0)] }), void 0) }), void 0), _jsxs(Card, { children: [_jsx(PageTabs, { index: activeTab, onChange: handleTabChange, tabs: [{ label: 'All' }, { label: 'Active' }, { label: 'Inactive' }] }, void 0), _jsx(CardBody, { children: _jsx(SearchInput, { value: filters.search || '', onChange: function (value) {
                                return router.switchQuery(__assign(__assign({}, filters), { search: value }));
                            }, placeholder: "Search customers", isDisabled: !(merchants === null || merchants === void 0 ? void 0 : merchants.length) }, void 0) }, void 0), _jsxs(CardBody, __assign({ p: 0, pt: 2 }, { children: [_jsx(ContentLoader, { isLoading: isLoading }, void 0), !isLoading && _jsx(MerchantsTable, { merchants: filteredMerchants || [] }, void 0)] }), void 0)] }, void 0), _jsx(MerchantForm, { isOpen: isOpen, onCancel: onClose }, void 0)] }), void 0));
};
