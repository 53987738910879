var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Button } from '@chakra-ui/button';
import { Flex } from '@chakra-ui/layout';
import { usePagination } from '@obtainly/hooks';
export var ListMaker = function (props) {
    var data = props.data, component = props.component;
    var _a = usePagination(data), result = _a.data, pageCount = _a.pageCount, perPage = _a.perPage, setPerPage = _a.setPerPage;
    return (_jsxs(_Fragment, { children: [(result === null || result === void 0 ? void 0 : result.length)
                ? result.map(function (item) {
                    return React.cloneElement(component, { key: item._id, item: item });
                })
                : null, pageCount > 1 && (_jsx(Flex, __assign({ justifyContent: "center", px: "20px" }, { children: _jsx(Button, __assign({ my: 4, isFullWidth: true, onClick: function () { return setPerPage(perPage + 10); } }, { children: "See More" }), void 0) }), void 0))] }, void 0));
};
