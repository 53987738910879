var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Container, Box } from '@chakra-ui/layout';
import { Helmet } from 'react-helmet';
import { Header } from 'src/core/components';
import { InstallPrompt } from './InstallPrompt';
export var Layout = function (props) {
    var _a;
    var slim = props.slim, hideHeader = props.hideHeader, children = props.children, bodyProps = props.bodyProps, rest = __rest(props, ["slim", "hideHeader", "children", "bodyProps"]);
    return (_jsxs(_Fragment, { children: [_jsx(InstallPrompt, {}, void 0), _jsxs(Box, __assign({ overflowY: "auto", bg: "gray.50" }, rest, { children: [_jsxs(Helmet, { children: [_jsx("meta", { charSet: "utf-8" }, void 0), _jsxs("title", { children: [((_a = props === null || props === void 0 ? void 0 : props.meta) === null || _a === void 0 ? void 0 : _a.title) || 'Dashboard', " - Obtainly"] }, void 0)] }, void 0), _jsx(Container, __assign({ maxW: "container.lg" }, { children: _jsxs(Box, __assign({ height: "100vh" }, { children: [!hideHeader && _jsx(Header, {}, void 0), _jsx(Box, __assign({ pb: "100px", mx: "auto", maxWidth: slim ? '700px' : '100%' }, bodyProps, { children: children }), void 0)] }), void 0) }), void 0)] }), void 0)] }, void 0));
};
