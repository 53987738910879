var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { getUnixTime, startOfDay, subDays } from 'date-fns';
import { Flex } from '@chakra-ui/layout';
import { useQueryModel } from '@obtainly/http';
import { basicSearch, capitalizeString } from '@obtainly/utils';
import { useFilter, useQueryStringParse } from '@obtainly/hooks';
import { useRouter } from 'src/core/hooks';
import { Card, CardBody, ContentHeader, ContentLoader, Exporter, Layout, PageTabs, SearchInput, } from 'src/core/components';
import { NotificationsTable } from '../components';
var DateRange;
(function (DateRange) {
    DateRange["Today"] = "today";
    DateRange["Yesterday"] = "yesterday";
    DateRange["Last_Week"] = "last_week";
    DateRange["Thirty_Days"] = "30_days";
    DateRange["Ninety_Days"] = "90_days";
})(DateRange || (DateRange = {}));
export var Notifications = function () {
    var router = useRouter('notifications');
    var columns = [
        {
            label: '_id',
            accessor: '_id',
        },
        {
            label: 'Tag',
            accessor: 'tag',
            selected: true,
        },
        {
            label: 'Message',
            accessor: 'message',
            selected: true,
        },
        {
            label: 'Meta',
            accessor: 'meta',
            modifier: function (value) {
                return JSON.stringify(JSON.parse(value), null, 2);
            },
            selected: true,
        },
        {
            label: 'Distributor Id',
            accessor: 'distributor_id',
            selected: true,
        },
        {
            label: 'Merchant Id',
            accessor: 'merchant_id',
            selected: true,
        },
        {
            label: 'Status',
            accessor: 'status',
            selected: true,
        },
    ];
    var filters = useQueryStringParse([], ['page']);
    var activeFilters = Object.keys(filters);
    var fromDate = React.useMemo(function () {
        var now = new Date();
        var past = now;
        if (activeFilters.length === 1) {
            past =
                filters.from_date === DateRange.Ninety_Days
                    ? subDays(now, 90)
                    : filters.from_date === DateRange.Thirty_Days
                        ? subDays(now, 30)
                        : filters.from_date === DateRange.Last_Week
                            ? subDays(now, 7)
                            : filters.from_date === DateRange.Yesterday
                                ? subDays(now, 1)
                                : now;
        }
        return getUnixTime(startOfDay(past));
    }, [activeFilters, filters]);
    var _a = useQueryModel('notification.read_from', {
        from_timestamp: fromDate,
    }, { enabled: !!fromDate }), data = _a.data, isLoading = _a.isLoading;
    var notifications = React.useMemo(function () {
        return data || [];
    }, [data]);
    var filteredNotifications = useFilter(notifications, filters, {
        search: function (notification) {
            return basicSearch(notification.tag, filters.search) ||
                basicSearch(notification.message, filters.search);
        },
        from_date: function () { return true; },
    });
    var activeTab = React.useMemo(function () {
        if (activeFilters.length === 1) {
            return (Object.entries(DateRange).findIndex(function (_a) {
                var value = _a[1];
                return value === filters.from_date;
            }) || 0);
        }
        return 0;
    }, [filters, activeFilters]);
    var handleTabChange = function (index) {
        var dateRangeValues = Object.values(DateRange);
        if (dateRangeValues[index]) {
            router.switchQuery({ from_date: dateRangeValues[index] });
        }
        else {
            router.go();
        }
    };
    return (_jsxs(Layout, __assign({ meta: { title: 'Notifications' } }, { children: [_jsx(ContentHeader, __assign({ heading: "Notifications", border: 0 }, { children: _jsx(Flex, __assign({ gridGap: 2 }, { children: _jsx(Exporter, { filename: "obtainly-notifications-".concat(getUnixTime(new Date())), data: filteredNotifications, columns: columns, isDisabled: !(filteredNotifications === null || filteredNotifications === void 0 ? void 0 : filteredNotifications.length) }, void 0) }), void 0) }), void 0), _jsxs(Card, { children: [_jsx(PageTabs, { index: activeTab, onChange: handleTabChange, tabs: Object.entries(DateRange).map(function (_a) {
                            var value = _a[1];
                            return capitalizeString(value.split('_').join(' '));
                        }) }, void 0), _jsx(CardBody, { children: _jsx(SearchInput, { value: filters.search || '', onChange: function (value) {
                                return router.switchQuery(__assign(__assign({}, filters), { search: value }));
                            }, placeholder: "Search notifications", isDisabled: !(notifications === null || notifications === void 0 ? void 0 : notifications.length) }, void 0) }, void 0), _jsxs(CardBody, __assign({ p: 0, pt: 2 }, { children: [_jsx(ContentLoader, { isLoading: isLoading }, void 0), !isLoading && (_jsx(NotificationsTable, { notifications: filteredNotifications || [] }, void 0))] }), void 0)] }, void 0)] }), void 0));
};
