var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, CloseButton, Code, Flex, } from '@chakra-ui/react';
import { useMutateModel } from '@obtainly/http';
import { Card, CardBody, ContentHeader, ContentLoader, Layout, } from 'src/core/components';
import { ConnectButton } from '../components';
export var Connect = function () {
    var _a = React.useState(true), showDialog = _a[0], setShowDialog = _a[1];
    var _b = React.useState({}), responseObject = _b[0], setResponseObject = _b[1];
    var _c = useMutateModel('ledger_tag.mono_callback'), data = _c.data, isLoading = _c.isLoading, mutate = _c.mutate;
    var toggleDialog = function () { return setShowDialog(!showDialog); };
    var sendPayload = function (payload) {
        setResponseObject(payload);
        mutate({ payload: JSON.stringify(payload) });
    };
    return (_jsxs(Layout, __assign({ meta: { title: 'Mono Connect' } }, { children: [_jsx(ContentHeader, __assign({ heading: "Mono Connect", border: 0 }, { children: _jsx(Flex, __assign({ gridGap: 2 }, { children: _jsx(ConnectButton, { onSuccess: sendPayload }, void 0) }), void 0) }), void 0), _jsx(Card, { children: _jsxs(CardBody, { children: [_jsxs(Box, { children: ["Payload: ", _jsx(Code, { children: JSON.stringify(responseObject) }, void 0)] }, void 0), _jsx(ContentLoader, { isLoading: isLoading }, void 0), !isLoading && data && showDialog ? (_jsxs(Alert, __assign({ status: "success", borderRadius: "md", mt: 3 }, { children: [_jsx(AlertIcon, {}, void 0), _jsxs(Box, __assign({ flex: "1" }, { children: [_jsx(AlertTitle, { children: "Success!" }, void 0), _jsxs(AlertDescription, __assign({ display: "block" }, { children: ["Response has been saved successfully.", _jsx(Code, { children: JSON.stringify(data) }, void 0)] }), void 0)] }), void 0), _jsx(CloseButton, { onClick: toggleDialog, position: "absolute", right: "8px", top: "8px" }, void 0)] }), void 0)) : null] }, void 0) }, void 0)] }), void 0));
};
