var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, } from '@chakra-ui/modal';
import { Box, Button, Text, VStack } from '@chakra-ui/react';
import { formatCurrency } from '@obtainly/utils';
import { Form, Formik } from 'formik';
import React from 'react';
import { FormGroup, FormikField } from 'src/core/components';
import * as Yup from 'yup';
var fundingRejectionFormSchema = Yup.object({
    reason: Yup.string()
        .required('Reason is required.')
        .min(10, 'Reason must be at least 10 characters.'),
});
export var FundingRejectionForm = function (props) {
    var funding = props.funding;
    var initialValues = {
        reason: '',
    };
    var onSubmit = function (values, meta) {
        props
            .onSubmitReason(values.reason)
            .then(function () {
            meta.resetForm();
            props.onCancel();
        })
            .finally(function () {
            meta.setSubmitting(false);
        });
    };
    return (_jsxs(Modal, __assign({ isOpen: props.isOpen, onClose: props.onCancel }, { children: [_jsx(ModalOverlay, {}, void 0), _jsx(ModalContent, { children: _jsx(Formik, __assign({ initialValues: initialValues, validationSchema: fundingRejectionFormSchema, onSubmit: onSubmit, enableReinitialize: true, validateOnMount: true }, { children: function (_a) {
                        var _b, _c;
                        var formik = __rest(_a, []);
                        return (_jsxs(Form, __assign({ onSubmit: formik.handleSubmit }, { children: [_jsxs(ModalHeader, { children: ["Reject Funding - #", funding.ref] }, void 0), _jsx(ModalCloseButton, {}, void 0), _jsx(ModalBody, { children: _jsxs(VStack, __assign({ spacing: 5, alignItems: "stretch" }, { children: [_jsx(Box, { children: _jsxs(Text, { children: ["You are about to reject funding request of", ' ', formatCurrency((funding === null || funding === void 0 ? void 0 : funding.amount) || 0, {
                                                            decimals: 0,
                                                            showCurrency: true,
                                                        }), ' ', "made by ", (_b = funding === null || funding === void 0 ? void 0 : funding.merchant) === null || _b === void 0 ? void 0 : _b.business_name, " under supplier", ' ', (_c = funding === null || funding === void 0 ? void 0 : funding.distributor) === null || _c === void 0 ? void 0 : _c.name] }, void 0) }, void 0), _jsx(FormGroup, __assign({ label: "Rejection Reason" }, { children: _jsx(FormikField, { as: "textarea", name: "reason", placeholder: "State reason why customer is denied credit" }, void 0) }), void 0)] }), void 0) }, void 0), _jsxs(ModalFooter, { children: [_jsx(Button, __assign({ size: "sm", variant: "outline", mr: 3, onClick: props.onCancel }, { children: "Cancel" }), void 0), _jsx(Button, __assign({ size: "sm", type: "submit", colorScheme: "primary", isLoading: formik.isSubmitting, isDisabled: formik.isSubmitting || !formik.dirty || !formik.isValid }, { children: "Reject Request" }), void 0)] }, void 0)] }), void 0));
                    } }), void 0) }, void 0)] }), void 0));
};
