import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { TableMaker } from '@obtainly/ui/web-desktop';
import { formatCurrency, formatUnixTime, updateStatus } from '@obtainly/utils';
import { Text } from '@chakra-ui/react';
import { CodeBox, ContentLoader, FundingRequestStatusBadge, FundingRequestTypeBadge, } from '@obtainly/ui/web-shared';
import { AddComment } from './AddComment';
function parseMeta(meta) {
    var result = '';
    try {
        result = JSON.stringify(meta, null, 2);
    }
    catch (e) {
        result = '';
    }
    return result;
}
export var FundingReportTable = function (_a) {
    var fundingRequests = _a.fundingRequests;
    var columns = React.useMemo(function () {
        return [
            { Header: '#', Cell: function (_a) {
                    var row = _a.row;
                    return Number(row.id) + 1;
                } },
            {
                Header: 'Ref',
                accessor: 'ref',
                Cell: function (_a) {
                    var cell = _a.cell;
                    return cell.value;
                },
            },
            {
                Header: 'Customer',
                accessor: 'name_of_customer',
                Cell: function (_a) {
                    var cell = _a.cell;
                    return cell.value;
                },
            },
            {
                Header: 'Status',
                accessor: 'status',
                Cell: function (_a) {
                    var _b, _c;
                    var row = _a.row;
                    return (_jsx(FundingRequestStatusBadge, { status: ((_b = row.original.meta) === null || _b === void 0 ? void 0 : _b.status) !== 'paid'
                            ? (_c = row.original.meta) === null || _c === void 0 ? void 0 : _c.status
                            : updateStatus(row.original.status), funding: row.original }, void 0));
                },
            },
            {
                Header: 'Customer Type',
                accessor: 'type_of_credit',
                Cell: function (_a) {
                    var cell = _a.cell;
                    return (_jsx(FundingRequestTypeBadge, { type: cell.value }, void 0));
                },
            },
            {
                Header: 'Month of Disbursement',
                accessor: 'disbursement_month',
                Cell: function (_a) {
                    var cell = _a.cell;
                    return cell.value;
                },
            },
            {
                Header: 'Principal',
                accessor: 'principal',
                Cell: function (_a) {
                    var cell = _a.cell;
                    return formatCurrency(Number(cell.value));
                },
                props: { isNumeric: true },
            },
            {
                Header: 'Interest rate',
                accessor: 'interest_rate',
                Cell: function (_a) {
                    var cell = _a.cell;
                    return (cell.value ? cell.value.toFixed(2) : '-');
                },
                props: { isNumeric: true },
            },
            {
                Header: 'Plan Duration (Days)',
                accessor: 'planned_duration_days',
                Cell: function (_a) {
                    var cell = _a.cell;
                    return cell.value;
                },
                props: { isNumeric: true },
            },
            {
                Header: 'Actual Duration (Days)',
                accessor: 'actual_duration_days',
                // remove fixed since value is coming as an integer
                Cell: function (_a) {
                    var cell = _a.cell;
                    return (cell.value ? cell.value : '-');
                },
                props: { isNumeric: true },
            },
            {
                Header: 'Interest Amount',
                accessor: 'interest',
                props: { isNumeric: true },
            },
            {
                Header: 'Amount Due',
                accessor: 'amount_due',
                Cell: function (_a) {
                    var cell = _a.cell;
                    return formatCurrency(Number(cell.value));
                },
                props: { isNumeric: true },
            },
            {
                Header: 'Amount Collected',
                accessor: 'amount_collected',
                Cell: function (_a) {
                    var cell = _a.cell;
                    return formatCurrency(Number(cell.value));
                },
                props: { isNumeric: true },
            },
            {
                Header: 'Outstanding',
                accessor: 'amount_outstanding',
                Cell: function (_a) {
                    var cell = _a.cell;
                    return formatCurrency(Number(cell.value));
                },
                props: { isNumeric: true },
            },
            {
                Header: 'Disbursement Date',
                accessor: 'release_date',
                Cell: function (_a) {
                    var cell = _a.cell;
                    return (_jsx(Text, { children: formatUnixTime(cell.value, 'dd MMM, yyyy') }, void 0));
                },
                props: { isNumeric: true },
            },
            {
                Header: 'Due Date',
                accessor: 'due_date',
                Cell: function (_a) {
                    var cell = _a.cell;
                    return (_jsx(Text, { children: formatUnixTime(cell.value, 'dd MMM, yyyy') }, void 0));
                },
                props: { isNumeric: true },
            },
            {
                Header: 'Pay Back Date',
                accessor: 'collection_date',
                Cell: function (_a) {
                    var cell = _a.cell;
                    return _jsx(Text, { children: formatUnixTime(cell.value, 'dd MMM, yyyy') }, void 0) || '-';
                },
                props: { isNumeric: true },
            },
            {
                Header: 'Pay Back Month',
                accessor: 'collection_month',
                Cell: function (_a) {
                    var cell = _a.cell;
                    return cell.value || '-';
                },
            },
            {
                Header: 'Meta',
                accessor: 'meta',
                Cell: function (_a) {
                    var cell = _a.cell;
                    return _jsx(CodeBox, { value: parseMeta(cell.value) }, void 0);
                },
            },
            {
                Header: 'Comment',
                accessor: '_id',
                Cell: function (_a) {
                    var _b;
                    var cell = _a.cell;
                    return (_jsx(AddComment, { id: cell.row.original.meta._id, comment: cell.row.original.meta.meta
                            ? (_b = JSON.parse(cell.row.original.meta.meta)) === null || _b === void 0 ? void 0 : _b.comment
                            : '' }, void 0));
                },
            },
        ];
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(ContentLoader, { contentUnavailable: !(fundingRequests === null || fundingRequests === void 0 ? void 0 : fundingRequests.length), errorMessage: "No credit found" }, void 0), (fundingRequests === null || fundingRequests === void 0 ? void 0 : fundingRequests.length) ? (_jsx(TableMaker, { data: fundingRequests, columns: columns, tableProps: { isFit: true }, pageSize: 8 }, void 0)) : null] }, void 0));
};
