export var FundingRequestStatus;
(function (FundingRequestStatus) {
    FundingRequestStatus["Pending"] = "pending";
    FundingRequestStatus["Approved"] = "approved";
    FundingRequestStatus["Rejected"] = "rejected";
    FundingRequestStatus["Active"] = "active";
    FundingRequestStatus["Paid"] = "paid";
    FundingRequestStatus["Early"] = "Early Pay Back";
    FundingRequestStatus["Late"] = "Late Pay Back";
    FundingRequestStatus["Perfect"] = "Perfect Pay Back";
})(FundingRequestStatus || (FundingRequestStatus = {}));
export var FundingRequestType;
(function (FundingRequestType) {
    FundingRequestType["Fixed"] = "fixed";
    FundingRequestType["Flexible"] = "flexible";
})(FundingRequestType || (FundingRequestType = {}));
export var FundingRequestLogTag;
(function (FundingRequestLogTag) {
    FundingRequestLogTag["State"] = "STATE";
    FundingRequestLogTag["LenderAgreement"] = "lender_agreement";
})(FundingRequestLogTag || (FundingRequestLogTag = {}));
