var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Text } from '@chakra-ui/layout';
import { SimpleGrid } from '@chakra-ui/react';
import { useAtom } from 'jotai';
import React from 'react';
import { Card, CardBody, CardHeader } from 'src/core/components';
import { distributorsAtom } from 'src/core/store/distributor';
export var MerchantDetails = function (_a) {
    var merchant = _a.merchant, isFunding = _a.isFunding;
    var selectedDistributor = useAtom(distributorsAtom)[0].active;
    var details = React.useMemo(function () {
        var _a;
        var formattedLedgerTag = (selectedDistributor === null || selectedDistributor === void 0 ? void 0 : selectedDistributor.distributor_id)
            ? Object.entries((merchant === null || merchant === void 0 ? void 0 : merchant.ledger_tags) || {}).filter(function (_a) {
                var _b;
                var key = _a[0];
                var _c = (_b = String(key)) === null || _b === void 0 ? void 0 : _b.split('|'), tag_id = _c[0], distributor_id = _c[1];
                return (tag_id === 'virtual_account' &&
                    (selectedDistributor === null || selectedDistributor === void 0 ? void 0 : selectedDistributor.distributor_id) === distributor_id);
            })
            : [];
        var ledgerTag = formattedLedgerTag.length
            ? formattedLedgerTag[0][1]
            : '{}';
        var virtualAccount = JSON.parse((ledgerTag === null || ledgerTag === void 0 ? void 0 : ledgerTag.value) || '{}');
        var mainDetails = [
            { label: 'Customer phone', value: merchant.phone },
            {
                label: 'Customer name',
                value: "".concat(merchant.firstname, " ").concat(merchant.lastname),
            },
            { label: 'Business name', value: merchant.business_name },
            {
                label: 'Business address',
                value: merchant.address,
            },
            {
                label: 'Business email',
                value: merchant.email,
            },
        ];
        return isFunding
            ? mainDetails
            : __spreadArray(__spreadArray([], mainDetails, true), [
                {
                    label: 'Bank Name',
                    value: ((_a = virtualAccount === null || virtualAccount === void 0 ? void 0 : virtualAccount.bank) === null || _a === void 0 ? void 0 : _a.name) || '-',
                },
                {
                    label: 'Bank Account Name',
                    value: (virtualAccount === null || virtualAccount === void 0 ? void 0 : virtualAccount.account_name) || '-',
                },
                {
                    label: 'Bank Account Number',
                    value: (virtualAccount === null || virtualAccount === void 0 ? void 0 : virtualAccount.account_number) || '-',
                },
            ], false);
    }, [merchant, selectedDistributor, isFunding]);
    return (_jsxs(Card, { children: [_jsx(CardHeader, { heading: "Customer Details" }, void 0), _jsx(CardBody, __assign({ pt: 0 }, { children: _jsx(SimpleGrid, __assign({ templateColumns: "1fr", mt: 3, spacing: 3, alignItems: "stretch" }, { children: details &&
                        details.map(function (detail) { return (_jsxs(Box, { children: [_jsx(Text, __assign({ fontSize: "sm", fontWeight: "medium", color: "gray.600" }, { children: detail.label }), void 0), _jsx(Text, { children: detail.value }, void 0)] }, detail.label)); }) }), void 0) }), void 0)] }, void 0));
};
