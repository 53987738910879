var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Container, Flex, Box, Heading, Text, Image } from '@chakra-ui/react';
import NoCloudIcon from 'src/core/assets/images/icons/no-cloud.svg';
import EmptyFolderIcon from 'src/core/assets/images/icons/empty-folder-gray.svg';
import SecurityIcon from 'src/core/assets/images/icons/unauthorized.svg';
/**
 * Display error message with icon
 *
 */
export var ErrorBox = function (_a) {
    var type = _a.type, my = _a.my, props = __rest(_a, ["type", "my"]);
    var content = type ? defaults[type] : defaults.default;
    content = __assign(__assign({}, content), props);
    return (_jsx(Container, { children: _jsx(Flex, __assign({ justifyContent: "center", my: my || 0 }, { children: _jsxs(Box, __assign({ maxW: "500px", textAlign: "center" }, { children: [_jsx(Image, { src: content.icon, margin: "0 auto" }, void 0), !!content.title && (_jsx(Heading, __assign({ as: "h6", fontSize: "xl", fontWeight: "medium", mt: 3, mb: 1, "data-testid": "title" }, { children: content.title }), void 0)), _jsx(Text, __assign({ as: "p", color: "gray.600", mt: !content.title ? 3 : 0, "data-testid": "summary" }, { children: content.summary }), void 0), !!props.reference && (_jsxs(Text, __assign({ as: "code", color: "gray.500", fontSize: "sm" }, { children: ["Ref: ", props.reference] }), void 0))] }), void 0) }), void 0) }, void 0));
};
var defaults = {
    network: {
        icon: NoCloudIcon,
        title: "You're offline",
        summary: "Seems like there's something wrong with your internet connection. Please reconnect and try again.",
    },
    suspense: {
        icon: NoCloudIcon,
        title: "Ah! Something has gone wrong.",
        summary: "If refreshing your browser doesn't fix the problem, kindly contact the tech team.",
    },
    authorization: {
        icon: SecurityIcon,
        title: "Unauthorized Access!",
        summary: "You don't have the permission to access this page. That's all we know.",
    },
    default: {
        icon: EmptyFolderIcon,
    },
};
