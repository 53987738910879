var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Tr as ChakraTr, } from '@chakra-ui/table';
import { useTheme } from '@chakra-ui/react';
export var Tr = function (props) {
    var colors = useTheme().colors;
    var children = props.children, isActive = props.isActive, isFlagged = props.isFlagged, css = props.css, rest = __rest(props, ["children", "isActive", "isFlagged", "css"]);
    var backgroundColor = isActive
        ? colors.primary[50]
        : isFlagged
            ? colors.critical[50]
            : colors.white;
    var hoverBackgroundColor = isActive
        ? colors.primary[50]
        : isFlagged
            ? colors.critical[50]
            : colors.gray[50];
    return (_jsx(ChakraTr, __assign({ css: __assign({ td: {
                backgroundColor: backgroundColor,
                transition: 'all 300ms',
            }, '&:hover td': {
                backgroundColor: hoverBackgroundColor,
            } }, css) }, rest, { children: children }), void 0));
};
