var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Th as ChakraTh, } from '@chakra-ui/table';
import { Flex, Text, VStack } from '@chakra-ui/layout';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { useTheme } from '@chakra-ui/system';
import { TableContext } from './Table';
export var Th = function (_a) {
    var sortDir = _a.sortDir, isSortable = _a.isSortable, children = _a.children, isSticky = _a.isSticky, props = __rest(_a, ["sortDir", "isSortable", "children", "isSticky"]);
    var colors = useTheme().colors;
    var tableProps = React.useContext(TableContext);
    return (_jsx(ChakraTh, __assign({ px: "20px", borderStyle: "solid", borderColor: "gray.100", borderWidth: tableProps.hasBorders ? '0 1px 1px 1px' : '0 0 1px 0', position: isSticky ? 'sticky' : 'static', left: 0, zIndex: isSticky ? 1 : 0, cursor: "default", css: {
            width: tableProps.isFit ? '1%' : 'inherit',
            whiteSpace: tableProps.isFit ? 'nowrap' : 'normal',
            '&:first-of-type': { borderLeft: 0 },
            '&:last-of-type': { borderRight: 0 },
        } }, props, { children: _jsxs(Flex, __assign({ alignItems: "center", py: !isSortable ? '10px' : '0' }, { children: [_jsx(Text, __assign({ color: sortDir ? 'gray.700' : 'gray.500', fontWeight: "medium", fontSize: "12px", textTransform: "uppercase", letterSpacing: 0.4, lineHeight: 0, width: "100%", textAlign: (props === null || props === void 0 ? void 0 : props.textAlign)
                        ? props === null || props === void 0 ? void 0 : props.textAlign
                        : props.isNumeric
                            ? 'right'
                            : 'start' }, { children: children }), void 0), isSortable && (_jsxs(VStack, __assign({ ml: 2, spacing: "-6px" }, { children: [_jsx(FiChevronUp, { size: 14, color: sortDir === 'desc' ? colors.gray[800] : colors.gray[400] }, void 0), _jsx(FiChevronDown, { size: 14, color: sortDir === 'asc' ? colors.gray[800] : colors.gray[400] }, void 0)] }), void 0))] }), void 0) }), void 0));
};
