export var ComplaintTag;
(function (ComplaintTag) {
    ComplaintTag["Shortages"] = "shortages";
    ComplaintTag["Expiry"] = "expiry";
    ComplaintTag["Damages"] = "damages";
    ComplaintTag["General"] = "general";
})(ComplaintTag || (ComplaintTag = {}));
export var ComplaintMetaTag;
(function (ComplaintMetaTag) {
    ComplaintMetaTag["Product"] = "PRODUCT";
    ComplaintMetaTag["Note"] = "NOTE";
})(ComplaintMetaTag || (ComplaintMetaTag = {}));
