var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TabPanel, TabPanels } from '@chakra-ui/react';
import React from 'react';
import { useQueryStringParse } from '@obtainly/hooks';
import { PageTabs } from '@obtainly/ui/web-desktop';
import { Card, MerchantPayments, MerchantVerification, MerchantVerificationStatus, } from '@obtainly/ui/web-shared';
import { useAtom } from 'jotai';
import { useRouter } from 'src/core/hooks';
import { authAtom } from 'src/core/store';
import { distributorsAtom } from 'src/core/store/distributor';
import { MerchantCreditConfig } from './MerchantCreditConfig';
import { MerchantNarration } from './MerchantNarration';
var PageTab;
(function (PageTab) {
    PageTab[PageTab["Profile"] = 0] = "Profile";
    PageTab[PageTab["Credit"] = 1] = "Credit";
    PageTab[PageTab["Payment"] = 2] = "Payment";
    PageTab[PageTab["Narration"] = 3] = "Narration";
})(PageTab || (PageTab = {}));
export var MerchantPageContent = function (_a) {
    var merchant = _a.merchant, refetch = _a.refetch;
    var selectedDistributor = useAtom(distributorsAtom)[0].active;
    var _b = useAtom(authAtom), activeDate = _b[0].activeDate, setAuth = _b[1];
    var router = useRouter(merchant._id);
    var filters = useQueryStringParse(['tab']);
    var activeFilters = Object.keys(filters);
    var activeTab = React.useMemo(function () {
        if (activeFilters.length === 1) {
            return filters.tab === 'credit'
                ? PageTab.Credit
                : filters.tab === 'profile'
                    ? PageTab.Profile
                    : filters.tab === 'narration'
                        ? PageTab.Narration
                        : PageTab.Payment;
        }
        return PageTab.Profile;
    }, [filters, activeFilters]);
    var handleTabChange = function (index) {
        if (index === PageTab.Credit) {
            router.switchQuery({ tab: 'credit' });
        }
        else if (index === PageTab.Profile) {
            router.switchQuery({ tab: 'profile' });
        }
        else if (index === PageTab.Payment) {
            router.switchQuery({ tab: 'payment' });
        }
        else if (index === PageTab.Narration) {
            router.switchQuery({ tab: 'narration' });
        }
        else {
            router.go();
        }
    };
    var getTabs = Object.entries(PageTab).map(function (_a, index) {
        var value = _a[1];
        switch (index) {
            case PageTab.Credit:
                return 'Credit Limit';
            case PageTab.Profile:
                return 'KYC Profile';
            case PageTab.Narration:
                return 'Narration';
            default:
                return value;
        }
    });
    return (_jsx(Card, { children: _jsx(PageTabs, __assign({ index: activeTab, onChange: handleTabChange, tabs: getTabs }, { children: _jsxs(TabPanels, __assign({ tabIndex: activeTab }, { children: [_jsxs(TabPanel, __assign({ p: 0 }, { children: [_jsx(MerchantVerificationStatus, { merchantId: merchant._id, isAdmin: true }, void 0), _jsx(MerchantVerification, { isAdmin: true, merchantId: merchant._id }, void 0)] }), void 0), _jsx(TabPanel, { children: _jsx(MerchantCreditConfig, { merchantId: merchant._id }, void 0) }, void 0), _jsx(TabPanel, { children: _jsx(MerchantPayments, { merchantId: merchant._id, distributors: merchant.distributors, distributorId: selectedDistributor === null || selectedDistributor === void 0 ? void 0 : selectedDistributor.distributor_id, date: activeDate, setDate: function (newDate) {
                                return setAuth(function (state) { return (__assign(__assign({}, state), { activeDate: newDate })); });
                            } }, void 0) }, void 0), _jsx(TabPanel, { children: _jsx(MerchantNarration, { merchantId: merchant._id, distributorId: selectedDistributor === null || selectedDistributor === void 0 ? void 0 : selectedDistributor.distributor_id, merchant: merchant, refetch: refetch }, void 0) }, void 0)] }), void 0) }), void 0) }, void 0));
};
