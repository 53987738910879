var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Flex, Heading, Text, VStack } from '@chakra-ui/react';
import { useToast } from '@obtainly/hooks';
import { useMutateModel, useQueryModel } from '@obtainly/http';
import { CardBody } from '@obtainly/ui/web-shared';
import { FormGroup, FormikField } from '@obtainly/ui/web-shared/FormField';
import { Form, Formik } from 'formik';
import React, { useMemo } from 'react';
import { RiCheckboxCircleFill } from 'react-icons/ri';
import { FormikSelect } from 'src/core/components/FormField/FormikSelect';
import * as Yup from 'yup';
var addPayoutAccountSchema = Yup.object({
    account_number: Yup.string()
        .matches(/^[0-9]+$/, function (field) { return "".concat(field.label, " must contain only digits"); })
        .length(10, function (field) { return "".concat(field.label, " must be 10 digits"); })
        .required()
        .label('Account number'),
    bank_code: Yup.string()
        .matches(/[^$]/, function (field) { return "".concat(field.label, " currently unavailable"); })
        .required()
        .label('Bank'),
    commit: Yup.boolean(),
});
export var AddPayoutAccount = function (props) {
    var toast = useToast().toast;
    var _a = useQueryModel('ledger_tag.paystack_get_bank_list', {}, { cacheTime: 0 }), bankLists = _a.data, loadingBanks = _a.isLoading;
    var _b = React.useState(false), verifying = _b[0], setVerifying = _b[1];
    var _c = useMutateModel('distributor.add_payout_account'), payoutData = _c.data, mutatePayout = _c.mutate, isLoading = _c.isLoading;
    var initialValues = {
        account_number: '',
        bank_code: '',
        bvn: '*',
        commit: false,
    };
    var onSubmit = function (values) {
        mutatePayout(__assign(__assign({}, values), { _id: props.supplierId, commit: true }), {
            onSuccess: function () {
                toast({
                    title: 'Payout account created',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
                props.refetch();
            },
            onError: function (error) {
                toast({
                    title: 'Error creating payout account',
                    description: error.message,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            },
        });
    };
    function handleAccountValidityCheck(values) {
        if (values.account_number.length !== 10)
            return null;
        setVerifying(true);
        mutatePayout(__assign(__assign({}, values), { _id: props.supplierId }), {
            onError: function (error) {
                toast({
                    title: 'Unable to verify payout account',
                    description: error.message,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            },
            onSettled: function () { return setVerifying(false); },
        });
    }
    var accountName = useMemo(function () {
        if (payoutData === null || payoutData === void 0 ? void 0 : payoutData.meta) {
            var meta = JSON.parse(payoutData.meta);
            return (meta === null || meta === void 0 ? void 0 : meta.account_name) || null;
        }
        return null;
    }, [payoutData]);
    return (_jsx(Box, { children: _jsxs(CardBody, __assign({ px: "0" }, { children: [_jsx(Heading, __assign({ mb: "1rem", fontSize: "xl", textAlign: "center" }, { children: "Add Payout Account" }), void 0), _jsx(Formik, __assign({ initialValues: initialValues, validationSchema: addPayoutAccountSchema, onSubmit: onSubmit, enableReinitialize: true, validateOnMount: true, validateOnChange: true }, { children: function (_a) {
                        var _b;
                        var values = _a.values, formik = __rest(_a, ["values"]);
                        return (_jsx(Form, __assign({ onSubmit: formik.handleSubmit }, { children: _jsxs(VStack, __assign({ spacing: 3, align: "stretch" }, { children: [_jsx(FormGroup, __assign({ label: "Bank" }, { children: _jsx(FormikSelect, { name: "bank_code", options: bankLists && !loadingBanks
                                                ? (_b = bankLists.data) === null || _b === void 0 ? void 0 : _b.map(function (bank) { return ({
                                                    label: bank.name,
                                                    value: bank.code,
                                                }); })
                                                : [] }, void 0) }), void 0), _jsxs(FormGroup, __assign({ label: "Account Number" }, { children: [_jsx(FormikField, { size: "sm", name: "account_number", placeholder: "Account Number", onBlur: function () { return handleAccountValidityCheck(values); }, onKeyPress: function (e) {
                                                    if (e.key === 'Enter') {
                                                        handleAccountValidityCheck(values);
                                                    }
                                                } }, void 0), _jsx(Box, __assign({ mt: "10px", fontSize: "sm" }, { children: verifying ? (_jsx(Text, __assign({ textDecoration: "italics" }, { children: "Verifying account name..." }), void 0)) : formik.isValid ? (!accountName ? (_jsxs(Text, { children: ["Unable to verify account", ' ', _jsx(Button, __assign({ variant: "link", onClick: function () { return handleAccountValidityCheck(values); } }, { children: "Try again" }), void 0)] }, void 0)) : (_jsxs(Flex, __assign({ align: "center", justify: "start", gap: "6px", color: "success.600" }, { children: [_jsx(Text, __assign({ alignContent: "start" }, { children: _jsx(RiCheckboxCircleFill, {}, void 0) }), void 0), _jsx(Text, __assign({ fontWeight: "semibold" }, { children: accountName }), void 0)] }), void 0))) : null }), void 0)] }), void 0), accountName ? (_jsx(Button, __assign({ size: "sm", type: "submit", colorScheme: "primary", isLoading: loadingBanks || isLoading, isDisabled: loadingBanks ||
                                            isLoading ||
                                            !formik.dirty ||
                                            !formik.isValid }, { children: "Add Payout Account" }), void 0)) : null] }), void 0) }), void 0));
                    } }), void 0)] }), void 0) }, void 0));
};
