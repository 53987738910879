var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { http } from '@obtainly/http';
import { useState } from 'react';
import { useMutation } from 'react-query';
export var useFileUploadMutation = function () {
    var _a = useState(), progress = _a[0], setProgress = _a[1];
    var mutation = useMutation(function (_a) {
        var uploadUrl = _a.uploadUrl, file = _a.file, fileData = _a.fileData;
        var data = new FormData();
        data.append('file', file);
        for (var key in fileData) {
            data.append(key, fileData[key]);
        }
        return http
            .post(uploadUrl, data, {
            onUploadProgress: function (ev) {
                return setProgress(Math.round((ev.loaded * 100) / ev.total));
            },
        })
            .then(function (_a) {
            var data = _a.data;
            return data;
        })
            .finally(function () { return setProgress(0); });
    });
    return __assign(__assign({}, mutation), { progress: progress });
};
