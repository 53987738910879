var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Badge, Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useQueryModel } from '@obtainly/http';
import { SettlementStatus, } from '@obtainly/models';
import { TableMaker } from '@obtainly/ui/web-desktop';
import { formatUnixTime } from '@obtainly/utils';
import { CodeBox, ContentLoader } from '@obtainly/ui/web-shared';
import RetrySettlement from './RetrySettlement';
export var SettlementsTable = function (_a) {
    var settlements = _a.settlements;
    var _b = useState(null), activeSettlement = _b[0], setActiveSettlement = _b[1];
    var columns = React.useMemo(function () {
        return [
            { Header: '#', Cell: function (_a) {
                    var row = _a.row;
                    return Number(row.id) + 1;
                } },
            {
                Header: 'Inbound Ref.',
                accessor: 'inbound_ref',
            },
            {
                Header: 'Inbound Meta',
                accessor: 'inbound_meta',
                Cell: function (_a) {
                    var row = _a.row;
                    return (_jsx(CodeBox, { value: row.original.inbound_meta }, void 0));
                },
            },
            {
                Header: 'Outbound Ref.',
                accessor: 'outbound_ref',
            },
            {
                Header: 'Outbound Meta',
                accessor: 'outbound_meta',
                Cell: function (_a) {
                    var row = _a.row;
                    return (_jsx(CodeBox, { value: row.original.outbound_meta }, void 0));
                },
            },
            {
                Header: 'Value',
                accessor: 'value',
            },
            {
                Header: 'Meta',
                accessor: 'meta',
                Cell: function (_a) {
                    var row = _a.row;
                    return (_jsx(CodeBox, { value: parseMeta(row.original.meta) }, void 0));
                },
            },
            {
                Header: 'Date',
                accessor: 'created',
                Cell: function (_a) {
                    var cell = _a.cell;
                    return formatUnixTime(cell.value);
                },
            },
            {
                Header: 'Status',
                accessor: 'status',
                Cell: function (_a) {
                    var cell = _a.cell;
                    return (_jsx(Badge, __assign({ colorScheme: cell.value === SettlementStatus.Done ? 'orange' : 'gray' }, { children: cell.value }), void 0));
                },
            },
            {
                Header: 'Logs',
                Cell: function (_a) {
                    var row = _a.row;
                    return (_jsx(Button, __assign({ px: 0, size: "sm", variant: "ghost", bg: "transparent", _hover: { bg: 'transparent' }, _active: { outline: 'none' }, _focus: { outline: 'none' }, onClick: function () { return setActiveSettlement(row.original); } }, { children: "View Logs" }), void 0));
                },
            },
            {
                Header: 'Action',
                Cell: function (_a) {
                    var row = _a.row;
                    if (row.original.status === SettlementStatus.Pending) {
                        return (_jsx(RetrySettlement, { id: row.original._id, inbound_ref: row.original.inbound_ref }, void 0));
                    }
                    else {
                        return null;
                    }
                },
            },
        ];
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(ContentLoader, { contentUnavailable: !(settlements === null || settlements === void 0 ? void 0 : settlements.length), errorMessage: "No settlements found" }, void 0), (settlements === null || settlements === void 0 ? void 0 : settlements.length) ? (_jsx(TableMaker, { data: settlements, columns: columns, tableProps: { isFit: true }, pageSize: 8 }, void 0)) : null, _jsx(SettlementLogs, { settlement: activeSettlement, onClose: function () {
                    setActiveSettlement(null);
                } }, void 0)] }, void 0));
};
var SettlementLogs = function (_a) {
    var settlement = _a.settlement, onClose = _a.onClose;
    var _b = useQueryModel('settlement_log.read_by_settlement_tag', { settlement_id: settlement === null || settlement === void 0 ? void 0 : settlement._id, tag: 'STATE' }, { enabled: !!(settlement === null || settlement === void 0 ? void 0 : settlement._id) }), logs = _b.data, isLoading = _b.isLoading;
    var columns = React.useMemo(function () {
        return [
            {
                Header: '#',
                Cell: function (_a) {
                    var row = _a.row;
                    return Number(row.id) + 1;
                },
            },
            {
                Header: 'Tag',
                accessor: 'tag',
            },
            {
                Header: 'Value',
                accessor: 'value',
            },
            {
                Header: 'Date',
                accessor: 'created',
                Cell: function (_a) {
                    var cell = _a.cell;
                    return formatUnixTime(cell.value);
                },
            },
        ];
    }, []);
    return (_jsxs(Modal, __assign({ isOpen: !!settlement, onClose: onClose }, { children: [_jsx(ModalOverlay, {}, void 0), _jsxs(ModalContent, { children: [_jsx(ModalHeader, { children: "Settlement logs" }, void 0), _jsx(ModalCloseButton, {}, void 0), _jsxs(ModalBody, __assign({ px: 0, py: 0 }, { children: [_jsx(ContentLoader, { isLoading: isLoading, contentUnavailable: !(logs === null || logs === void 0 ? void 0 : logs.length), errorMessage: "No settlement logs found" }, void 0), !isLoading && (logs === null || logs === void 0 ? void 0 : logs.length) ? (_jsx(_Fragment, { children: _jsx(TableMaker, { data: logs, columns: columns, tableProps: { isFit: true } }, void 0) }, void 0)) : null] }), void 0)] }, void 0)] }), void 0));
};
function parseMeta(meta) {
    var result = '';
    try {
        result = JSON.stringify(JSON.parse(meta), null, 2);
    }
    catch (e) {
        result = '';
    }
    return result;
}
