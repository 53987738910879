import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { VisuallyHiddenInput } from '@chakra-ui/react';
import { useToast } from '@obtainly/hooks';
import { getFileName, getFileType, uploadableFileTypes } from '@obtainly/utils';
import { forwardRef, useState } from 'react';
import { FileSelectorPreview } from './FileSelectorPreview';
export var FileSelector = forwardRef(function (props, ref) {
    var onSelect = props.onSelect, multiple = props.multiple;
    var toast = useToast().toast;
    var _a = useState(function () { return new Map(); }), files = _a[0], setFiles = _a[1];
    var handleFileInputChange = function (event) {
        event.preventDefault();
        var files = event.target.files;
        var noOfFiles = files === null || files === void 0 ? void 0 : files.length;
        if (!noOfFiles)
            return;
        var fileDataList = new Map();
        var readError = new Map();
        for (var _i = 0, files_1 = files; _i < files_1.length; _i++) {
            var file = files_1[_i];
            if (!file)
                continue;
            var type = getFileType(file);
            var fileName = getFileName(file);
            if (!uploadableFileTypes.has(type)) {
                readError.set(fileName, 'Please select a valid image or PDF file.');
            }
            else if (file.size > 4024000) {
                readError.set(fileName, 'Max Upload size is 4MB only.');
            }
            else {
                fileDataList.set(fileName, file);
            }
        }
        if (readError.size > 1) {
            toast({ description: 'Some files could not be added', status: 'error' });
        }
        if (readError.size === 1) {
            toast({ description: Array.from(readError.values())[0], status: 'error' });
        }
        setFiles(fileDataList);
    };
    var onClose = function () {
        return setFiles(function () {
            return new Map();
        });
    };
    return (_jsxs(_Fragment, { children: [_jsx(VisuallyHiddenInput, { type: "file", className: "hidden-input", accept: "image/jpg, image/jpeg, image/png, application/pdf", onChange: handleFileInputChange, ref: ref, multiple: multiple }, void 0), _jsx(FileSelectorPreview, { isOpen: !!files.size, files: files, onClose: onClose, errors: new Map(), onSelectFiles: function (selectedFiles) {
                    onSelect(selectedFiles);
                    onClose();
                } }, void 0)] }, void 0));
});
FileSelector.displayName = 'FileSelector';
