var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { HStack, Button, Box, Container, Text } from '@chakra-ui/react';
export var InstallPrompt = function () {
    var _a = React.useState(), promptObj = _a[0], setPromptObj = _a[1];
    var _b = React.useState(), visible = _b[0], setVisible = _b[1];
    var handleInstall = function () { return __awaiter(void 0, void 0, void 0, function () {
        var deferredPrompt, outcome, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, promptObj.prompt()];
                case 1:
                    deferredPrompt = _a.sent();
                    outcome = deferredPrompt // dismissed | accepted
                    .outcome;
                    if (outcome === 'accepted') {
                        setVisible(false);
                    }
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    console.log(e_1); //eslint-disable-line
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    // TO-DO
    // detect app is already installed
    // prompt to open app
    // --- implementation ---
    // set related apps in manifest.json
    // get installed related apps and do comparison
    // window.navigator.getInstalledRelatedApps()
    // lastly, open app
    // window.open(window.location.href, '_blank')
    React.useEffect(function () {
        if ('serviceWorker' in navigator) {
            window.addEventListener('beforeinstallprompt', function (promptObject) {
                // show button
                setPromptObj(promptObject);
                setVisible(true);
            });
            window.addEventListener('appinstalled', function () {
                // TODO: send analytics event to indicate successful install
                // app was installed, no need to show prompt
                setPromptObj(null);
            });
            navigator.serviceWorker.getRegistration().then(function (reg) {
                return __awaiter(this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!reg) return [3 /*break*/, 2];
                                // update sw
                                return [4 /*yield*/, reg.update()];
                            case 1:
                                // update sw
                                _a.sent();
                                _a.label = 2;
                            case 2: return [2 /*return*/];
                        }
                    });
                });
            });
        }
        if (window.matchMedia('(display-mode: standalone)').matches) {
            // app was launched in standalone mode (pwa-mode)
            // don't show prompt
            setVisible(false);
        }
        return function () {
            window.removeEventListener('beforeinstallprompt', function () { return null; });
            window.removeEventListener('appinstalled', function () { return null; });
        };
    }, []);
    return (_jsx(_Fragment, { children: !!promptObj && visible && (_jsx(Box, __assign({ bgColor: "gray.200" }, { children: _jsx(Container, __assign({ maxW: "container.lg", py: 3 }, { children: _jsxs(HStack, __assign({ justify: "space-between", w: "100%", gridGap: 2 }, { children: [_jsx(Text, __assign({ fontSize: "sm" }, { children: "Install Obtainly app for free. It's fast and takes less storage on your device." }), void 0), _jsx(Box, { children: _jsx(Button, __assign({ onClick: handleInstall, colorScheme: "primary", size: "sm" }, { children: "Install App" }), void 0) }, void 0)] }), void 0) }), void 0) }), void 0)) }, void 0));
};
