var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Button } from '@chakra-ui/button';
import { GridItem, Grid } from '@chakra-ui/layout';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useMutateModel } from '@obtainly/http';
import { FormikField, FormGroup } from 'src/core/components';
import { useToast } from 'src/core/hooks';
import { authAtom, useAtom, userAtom } from 'src/core/store';
var profileFormSchema = Yup.object({
    firstname: Yup.string().required('First name field is required.'),
    lastname: Yup.string().required('Last name field is required.'),
});
export var AdministratorProfileForm = function () {
    var toast = useToast().toast;
    var user = useAtom(userAtom)[0];
    var _a = useAtom(authAtom), setAuth = _a[1];
    var doUpdate = useMutateModel('administrator.update');
    var initialValues = {
        _id: user._id,
        role: user.role,
        user_id: user.user_id,
        status: user.status,
        email: user.email,
        firstname: user.firstname,
        lastname: user.lastname,
    };
    var onSubmit = function (values) {
        doUpdate.mutate(values, {
            onSuccess: function () {
                toast({ status: 'success', description: 'Profile saved' });
                setAuth(function (state) { return (__assign(__assign({}, state), { refreshedAt: new Date() })); });
            },
            onError: function (message) {
                toast({ status: 'error', description: message });
            },
        });
    };
    return (_jsx(_Fragment, { children: _jsx(Formik, __assign({ initialValues: initialValues, validationSchema: profileFormSchema, onSubmit: onSubmit, enableReinitialize: true, validateOnMount: true }, { children: function (formik) { return (_jsxs(Form, __assign({ onSubmit: formik.handleSubmit }, { children: [_jsxs(Grid, __assign({ gridGap: "10px", templateColumns: ['1fr', '1fr 1fr'] }, { children: [_jsx(GridItem, __assign({ colSpan: [1, 2] }, { children: _jsx(FormGroup, __assign({ label: "Email" }, { children: _jsx(FormikField, { name: "email", isDisabled: true }, void 0) }), void 0) }), void 0), _jsx(FormGroup, __assign({ label: "First name" }, { children: _jsx(FormikField, { name: "firstname" }, void 0) }), void 0), _jsx(FormGroup, __assign({ label: "Last name" }, { children: _jsx(FormikField, { name: "lastname" }, void 0) }), void 0)] }), void 0), _jsx(Button, __assign({ mt: 2, type: "submit", colorScheme: "primary", isDisabled: doUpdate.isLoading || !formik.dirty || !formik.isValid, isLoading: doUpdate.isLoading }, { children: "Update Profile" }), void 0)] }), void 0)); } }), void 0) }, void 0));
};
