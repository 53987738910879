import React from 'react';
export function useQuantityInput(params) {
    var value = params.value || 0;
    var mpu = params.mpu || 1;
    var mxpu = params.mxpu || null;
    var caseCount = params.caseCount || 1;
    var _a = React.useState('unit'), localUnit = _a[0], setLocalUnit = _a[1];
    var _b = React.useState(params.value), localValue = _b[0], setLocalValue = _b[1];
    var isUnit = localUnit === 'unit';
    var minValue = isUnit ? mpu : mpu / caseCount;
    var maxValue = mxpu ? (isUnit ? mxpu : mxpu / caseCount) : null;
    var interval = minValue || 1;
    if (params.interval) {
        interval = isUnit ? params.interval : params.interval / caseCount;
    }
    /**
     * Sync props.unit to localUnit
     */
    React.useEffect(function () {
        setLocalUnit((params === null || params === void 0 ? void 0 : params.unit) || 'unit');
    }, [params.unit]);
    /**
     * Sync props.value to localValue
     */
    React.useEffect(function () {
        if (!isNaN(value)) {
            var _value = localUnit === 'unit' ? value : value / caseCount;
            setLocalValue(parseFloat(_value.toFixed(1)));
        }
        else {
            setLocalValue(0);
        }
    }, [value, localUnit, caseCount, minValue, maxValue]);
    var commitChange = function (value) {
        var _a, _b, _c;
        var isInUnit = localUnit === 'unit';
        var units = isInUnit ? value : value * caseCount;
        var minUnits = isInUnit ? minValue : minValue * caseCount;
        var maxUnits = maxValue
            ? isInUnit
                ? maxValue
                : maxValue * caseCount
            : null;
        if (value && (value < minValue || (maxValue && value > maxValue))) {
            setLocalValue(Number((maxValue || minValue).toFixed(isInUnit ? 0 : 1)));
            (_a = params.onChange) === null || _a === void 0 ? void 0 : _a.call(params, Number((_b = (maxUnits || minUnits)) === null || _b === void 0 ? void 0 : _b.toFixed()));
            params.onError(maxValue
                ? "Quantity must be between ".concat(minValue, " - ").concat(maxValue)
                : "Quantity must not be less than ".concat(minValue));
        }
        else {
            (_c = params.onChange) === null || _c === void 0 ? void 0 : _c.call(params, Number(units.toFixed()));
        }
    };
    var increment = function (event) {
        event.preventDefault();
        var newValue = Number(localValue) + Number(interval);
        commitChange(maxValue && newValue > maxValue ? maxValue : newValue);
    };
    var decrement = function (event) {
        event.preventDefault();
        var newValue = Number(localValue) - Number(interval);
        commitChange(newValue < minValue ? minValue : newValue);
    };
    var handleChange = function (_a) {
        var target = _a.target;
        var value;
        try {
            value = eval(target.value);
        }
        catch (_b) {
            value = target.value;
        }
        commitChange(typeof value === 'string' ? value.replace(/\D/g, '') : value);
    };
    var onInputChange = function (event) {
        setLocalValue(event.target.value);
    };
    var onInputBlur = function (event) {
        // regex for mathematical expression
        var regex = /(?:(?:^|[-+_*/])(?:\s*-?\d+(\.\d+)?(?:[eE][+-]?\d+)?\s*))+$/;
        if (regex.test(event.target.value))
            handleChange(event);
    };
    return {
        value: localValue,
        minValue: minValue,
        maxValue: maxValue,
        unit: localUnit,
        setUnit: setLocalUnit,
        increment: increment,
        decrement: decrement,
        onInputChange: onInputChange,
        onInputBlur: onInputBlur,
    };
}
