var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Flex } from '@chakra-ui/layout';
import { getUnixTime } from 'date-fns';
import { formatCurrency } from '@obtainly/utils';
import { ContentHeader, Exporter, Layout, MonthYearPicker, } from 'src/core/components';
import { dateAtom, useAtom } from 'src/core/store';
import { AdministratorReconciliation } from '../components';
export var ReconciliationReport = function () {
    var _a = React.useState([]), exportableData = _a[0], setExportableData = _a[1];
    var columns = [
        {
            label: 'Supplier',
            accessor: 'party.name',
        },
        {
            label: 'Pay-in (Customers)',
            accessor: 'distributor.pay_in.total',
            modifier: function (value) {
                return formatCurrency(value) || '0.00';
            },
            selected: true,
        },
        {
            label: 'IN',
            accessor: 'processor.pay_in.total',
            modifier: function (value) {
                return formatCurrency(value) || '0.00';
            },
            selected: true,
        },
        {
            label: 'Pay-Out (Supplier)',
            accessor: 'distributor.pay_out.total',
            modifier: function (value) {
                return formatCurrency(value) || '0.00';
            },
            selected: true,
        },
        {
            label: 'Pay-Out (Obtainly charge)',
            accessor: 'obtainly.pay_in.total',
            modifier: function (value) {
                return formatCurrency(value) || '0.00';
            },
            selected: true,
        },
        {
            label: 'Paystack charge',
            accessor: 'obtainly.charges.total',
            modifier: function (value) {
                return formatCurrency(value) || '0.00';
            },
            selected: true,
        },
        {
            label: 'OUT',
            accessor: 'processor.pay_out.total',
            modifier: function (value) {
                return formatCurrency(value) || '0.00';
            },
            selected: true,
        },
    ];
    var _b = useAtom(dateAtom), selectedDate = _b[0], setSelectedDate = _b[1];
    return (_jsxs(Layout, __assign({ meta: { title: 'Supplier Reconciliation' } }, { children: [_jsx(ContentHeader, __assign({ heading: "Supplier Reconciliation", border: 0 }, { children: _jsxs(Flex, __assign({ gridGap: 2 }, { children: [_jsx(MonthYearPicker, { value: selectedDate, onChange: setSelectedDate, isFullWidth: false }, void 0), _jsx(Exporter, { filename: "obtainly-distributor-reconciliation-report-".concat(getUnixTime(new Date())), data: exportableData, columns: columns, isDisabled: !exportableData.length }, void 0)] }), void 0) }), void 0), _jsx(AdministratorReconciliation, { showHeader: false, setExportableData: function (data) { return setExportableData(data); } }, void 0)] }), void 0));
};
