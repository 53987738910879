var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
export var ProtectedRoute = function (_a) {
    var component = _a.component, isAuthenticated = _a.isAuthenticated, isFirstLogin = _a.isFirstLogin, rest = __rest(_a, ["component", "isAuthenticated", "isFirstLogin"]);
    var Component = component;
    return (_jsx(Route, __assign({}, rest, { render: function (props) {
            if (!isAuthenticated) {
                return _jsx(Redirect, { to: "/login" }, void 0);
            }
            else {
                if (isFirstLogin) {
                    if (rest.path !== '/update_password') {
                        return _jsx(Redirect, { to: "/update_password" }, void 0);
                    }
                    else {
                        return _jsx(Component, __assign({}, props), void 0);
                    }
                }
                else {
                    if (rest.path === '/update_password') {
                        return _jsx(Redirect, { to: "/" }, void 0);
                    }
                    else {
                        return _jsx(Component, __assign({}, props), void 0);
                    }
                }
            }
        } }), void 0));
};
