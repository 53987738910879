import { FundingReport, FundingRequest, FundingRequests } from './pages';
export var fundingRequestRoutes = [
    {
        id: 'funding-requests',
        path: '/funding-requests',
        component: FundingRequests,
        requiresAuth: true,
        exact: true,
    },
    {
        id: 'funding-request',
        path: '/funding-request/:id',
        component: FundingRequest,
        requiresAuth: true,
        exact: true,
    },
    {
        id: 'funding-requests-reports',
        path: '/funding-requests/reports',
        component: FundingReport,
        requiresAuth: true,
        exact: true,
    },
];
export * from './components';
