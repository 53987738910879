var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Badge, Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text, } from '@chakra-ui/react';
import React, { useState } from 'react';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { useQueryModel } from '@obtainly/http';
import { NotificationStatus, } from '@obtainly/models';
import { TableMaker } from '@obtainly/ui/web-desktop';
import { CodeBox, ContentLoader } from '@obtainly/ui/web-shared';
import { formatUnixTime } from '@obtainly/utils';
export var NotificationsTable = function (_a) {
    var notifications = _a.notifications;
    var _b = useState(null), activeNotification = _b[0], setActiveNotification = _b[1];
    var _c = useState(false), expandedMeta = _c[0], setExpandedMeta = _c[1];
    var columns = React.useMemo(function () {
        return [
            { Header: '#', Cell: function (_a) {
                    var row = _a.row;
                    return Number(row.id) + 1;
                } },
            {
                id: 'meta',
                Header: function () { return (_jsxs(Flex, __assign({ title: expandedMeta ? 'Collapse All Meta' : 'Expand All Meta', onClick: function () { return setExpandedMeta(!expandedMeta); }, alignItems: "center", cursor: "pointer", gridGap: 2, as: "span" }, { children: [_jsx(Text, __assign({ as: "span" }, { children: "Meta" }), void 0), expandedMeta ? _jsx(FiEyeOff, {}, void 0) : _jsx(FiEye, {}, void 0)] }), void 0)); },
                Cell: function (_a) {
                    var row = _a.row;
                    return (_jsx(CodeBox, { value: JSON.stringify(JSON.parse(String(row.original.meta) || '{}'), null, 2), forceExpanded: expandedMeta }, void 0));
                },
            },
            {
                Header: 'tag',
                accessor: 'tag',
                Cell: function (_a) {
                    var cell = _a.cell;
                    return (_jsx(Badge, __assign({ textTransform: "lowercase" }, { children: cell.value }), void 0));
                },
            },
            {
                Header: 'message',
                accessor: 'message',
                Cell: function (_a) {
                    var cell = _a.cell;
                    return (_jsx(Text, __assign({ minW: "150px", maxW: "400px", whiteSpace: "break-spaces" }, { children: cell.value }), void 0));
                },
            },
            {
                Header: 'Date',
                accessor: 'created',
                Cell: function (_a) {
                    var cell = _a.cell;
                    return formatUnixTime(cell.value);
                },
            },
            {
                Header: 'Status',
                accessor: 'status',
                Cell: function (_a) {
                    var cell = _a.cell;
                    return (_jsx(Badge, __assign({ colorScheme: cell.value === NotificationStatus.Created ? 'orange' : 'gray' }, { children: cell.value }), void 0));
                },
            },
            {
                Header: 'Logs',
                Cell: function (_a) {
                    var row = _a.row;
                    return (_jsx(Button, __assign({ px: 0, size: "sm", variant: "ghost", bg: "transparent", _hover: { bg: 'transparent' }, _active: { outline: 'none' }, _focus: { outline: 'none' }, onClick: function () { return setActiveNotification(row.original); } }, { children: "View Logs" }), void 0));
                },
            },
        ];
    }, [expandedMeta]);
    return (_jsxs(_Fragment, { children: [_jsx(ContentLoader, { contentUnavailable: !(notifications === null || notifications === void 0 ? void 0 : notifications.length), errorMessage: "No notifications found" }, void 0), (notifications === null || notifications === void 0 ? void 0 : notifications.length) ? (_jsx(TableMaker, { data: notifications, columns: columns, tableProps: { isFit: true } }, void 0)) : null, _jsx(NotificationLogs, { notification: activeNotification, onClose: function () {
                    setActiveNotification(null);
                } }, void 0)] }, void 0));
};
var NotificationLogs = function (_a) {
    var notification = _a.notification, onClose = _a.onClose;
    var _b = useQueryModel('notification_log.read_by_notification', { notification_id: notification === null || notification === void 0 ? void 0 : notification._id }, { enabled: !!(notification === null || notification === void 0 ? void 0 : notification._id) }), logs = _b.data, isLoading = _b.isLoading;
    var columns = React.useMemo(function () {
        return [
            {
                Header: '#',
                Cell: function (_a) {
                    var row = _a.row;
                    return Number(row.id) + 1;
                },
            },
            {
                Header: 'Tag',
                accessor: 'tag',
            },
            {
                Header: 'Value',
                accessor: 'value',
            },
            {
                Header: 'Date',
                accessor: 'created',
                Cell: function (_a) {
                    var cell = _a.cell;
                    return formatUnixTime(cell.value);
                },
            },
        ];
    }, []);
    return (_jsxs(Modal, __assign({ isOpen: !!notification, onClose: onClose }, { children: [_jsx(ModalOverlay, {}, void 0), _jsxs(ModalContent, { children: [_jsx(ModalHeader, { children: "Notification logs" }, void 0), _jsx(ModalCloseButton, {}, void 0), _jsxs(ModalBody, __assign({ px: 0, py: 0 }, { children: [_jsx(ContentLoader, { isLoading: isLoading, contentUnavailable: !(logs === null || logs === void 0 ? void 0 : logs.length), errorMessage: "No notification logs found" }, void 0), !isLoading && (logs === null || logs === void 0 ? void 0 : logs.length) ? (_jsx(_Fragment, { children: _jsx(TableMaker, { data: logs, columns: columns, tableProps: { isFit: true } }, void 0) }, void 0)) : null] }), void 0)] }, void 0)] }), void 0));
};
