export var formatCurrency = function (amount, settings) {
    var _a, _b;
    if (isNaN(amount))
        return false;
    var options = {
        minimumFractionDigits: (_a = settings === null || settings === void 0 ? void 0 : settings.decimals) !== null && _a !== void 0 ? _a : 2,
        maximumFractionDigits: (_b = settings === null || settings === void 0 ? void 0 : settings.decimals) !== null && _b !== void 0 ? _b : 2,
    };
    if (settings === null || settings === void 0 ? void 0 : settings.compact) {
        options.notation = 'compact';
        options.compactDisplay = 'short';
        options.minimumFractionDigits = 0;
    }
    if (settings === null || settings === void 0 ? void 0 : settings.showCurrency) {
        options.style = 'currency';
        options.currency = 'NGN';
    }
    return new Intl.NumberFormat('en-NG', options).format(amount);
};
