import React from 'react';
var defaultOptions = {
    preventDefault: true,
    delay: 300,
};
export function useLongPress(onLongPress, onClick, options) {
    if (options === void 0) { options = defaultOptions; }
    var _a = React.useState(false), longPressTriggered = _a[0], setLongPressTriggered = _a[1];
    var target = React.useRef();
    var timeout = React.useRef();
    var start = React.useCallback(function (event) {
        if (options.preventDefault && event.target) {
            event.target.addEventListener('touchend', preventDefault, {
                passive: false,
            });
            target.current = event.target;
        }
        timeout.current = setTimeout(function () {
            onLongPress(event);
            setLongPressTriggered(true);
        }, options.delay);
    }, [onLongPress, options]);
    var clear = React.useCallback(function (_, shouldTriggerClick) {
        if (shouldTriggerClick === void 0) { shouldTriggerClick = true; }
        timeout.current && clearTimeout(timeout.current);
        shouldTriggerClick && !longPressTriggered && onClick();
        setLongPressTriggered(false);
        if (options.preventDefault && target.current) {
            target.current.removeEventListener('touchend', preventDefault);
        }
    }, [options.preventDefault, onClick, longPressTriggered]);
    return {
        onMouseDown: function (e) { return start(e); },
        onTouchStart: function (e) { return start(e); },
        onMouseUp: function (e) { return clear(e); },
        onMouseLeave: function (e) { return clear(e, false); },
        onTouchEnd: function (e) { return clear(e); },
    };
}
function isTouchEvent(event) {
    return 'touches' in event;
}
function preventDefault(event) {
    if (!isTouchEvent(event))
        return;
    if (event.touches.length < 2 && event.preventDefault) {
        event.preventDefault();
    }
}
