import { Configs } from './pages';
export var configRoutes = [
    {
        id: 'configs',
        path: '/configs',
        component: Configs,
        requiresAuth: true,
        exact: true,
    },
];
export * from './components';
