var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Spinner, Textarea } from '@chakra-ui/react';
import { useMutateModel } from '@obtainly/http';
import React, { useEffect } from 'react';
export var AddComment = function (_a) {
    var id = _a.id, comment = _a.comment;
    var _b = React.useState(''), newComment = _b[0], setComment = _b[1];
    useEffect(function () {
        setComment(comment);
    }, [comment]);
    var addComment = useMutateModel('funding_request.update_comment');
    function handleCommentUpdate() {
        addComment.mutate({ _id: id, comment: newComment });
    }
    return (_jsxs(Box, __assign({ pos: "relative" }, { children: [_jsx(Textarea, { minW: "200px", w: "100%", h: "24px", border: "1px", borderColor: "ButtonHighlight", placeholder: "Add a comment", value: newComment, onChange: function (e) { return setComment(e.target.value); }, onBlur: handleCommentUpdate }, void 0), addComment.isLoading && (_jsx(Spinner, { pos: "absolute", bottom: "0", right: "0.5", size: "xs" }, void 0))] }), void 0));
};
