var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useRef, useState } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { useClickOutside } from '@obtainly/hooks';
export var CodeBox = function (_a) {
    var value = _a.value, forceExpanded = _a.forceExpanded;
    var _b = useState(false), expanded = _b[0], setExpanded = _b[1];
    var containerRef = useRef(null);
    useClickOutside(containerRef, function () {
        setExpanded(false);
    });
    var finalExpanded = forceExpanded || expanded;
    return value ? (_jsx(Box, __assign({ position: "relative", py: finalExpanded ? '20px' : '0' }, { children: _jsxs(Box, __assign({ ref: containerRef, border: "1px solid", bg: finalExpanded ? 'white' : 'transparent', boxShadow: finalExpanded ? 'sm' : 'none', height: finalExpanded ? 'auto' : '40px', borderColor: "gray.100", borderRadius: "md", overflow: "hidden", top: 0, left: 0, position: expanded && !finalExpanded
                ? 'absolute'
                : expanded
                    ? 'absolute'
                    : 'static', maxW: finalExpanded ? '400px' : '150px', zIndex: finalExpanded ? 1 : 0 }, { children: [_jsxs(Flex, __assign({ px: "10px", position: "absolute", width: "100%", bg: finalExpanded
                        ? 'transparent'
                        : 'linear-gradient(180deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.7) 100%)', height: "40px", borderRadius: "md", alignItems: "center", justifyContent: "space-between" }, { children: [_jsx(Text, {}, void 0), _jsx(Text, __assign({ fontSize: "11px", bg: "rgba(255, 255, 255, 0.9)", border: "1px solid", borderColor: "gray.50", px: "4px", color: "gray.700", boxShadow: finalExpanded ? 'md' : 'none', cursor: "pointer", onClick: function () {
                                setExpanded(!expanded);
                            } }, { children: finalExpanded ? 'Collapse' : 'Expand' }), void 0)] }), void 0), _jsx(Box, __assign({ fontSize: "xs", color: "gray.700", fontFamily: "monospace", maxH: finalExpanded ? '250px' : 'auto', px: "10px", py: "10px", overflow: finalExpanded ? 'auto' : '"hidden"' }, { children: _jsx("pre", { children: _jsx("code", { children: value }, void 0) }, void 0) }), void 0)] }), void 0) }), void 0)) : null;
};
