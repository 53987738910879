var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Checkbox, Flex, Text } from '@chakra-ui/react';
import { useMutateModel, useQueryModel } from '@obtainly/http';
import { MerchantDocumentStatus, } from '@obtainly/models';
import { FileItem, FileUploader } from '@obtainly/ui/web-shared';
import { useEffect, useMemo, useState } from 'react';
import { useToast } from '@obtainly/hooks';
import { RadioCardGroup } from './FormField';
export var MerchantDocument = function (_a) {
    var _b;
    var merchant = _a.merchant, tag = _a.tag, label = _a.label, merchantDocument = _a.merchantDocument, isAdmin = _a.isAdmin, onUpdate = _a.onUpdate, type = _a.type, options = _a.options, question = _a.question;
    var toast = useToast().toast;
    var _c = useState(false), verified = _c[0], setVerified = _c[1];
    var _d = useState(null), newFile = _d[0], setNewFile = _d[1];
    var upload = useQueryModel('upload.read', {
        _id: merchantDocument === null || merchantDocument === void 0 ? void 0 : merchantDocument.value,
    }, {
        enabled: !!(merchantDocument === null || merchantDocument === void 0 ? void 0 : merchantDocument.value) &&
            merchantDocument.type === 'upload' &&
            !((_b = merchantDocument === null || merchantDocument === void 0 ? void 0 : merchantDocument.upload) === null || _b === void 0 ? void 0 : _b.length),
    }).data;
    var fileUrl = useMemo(function () {
        var _a, _b, _c;
        var path = ((_b = (_a = merchantDocument === null || merchantDocument === void 0 ? void 0 : merchantDocument.upload) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.path) || ((_c = upload === null || upload === void 0 ? void 0 : upload[0]) === null || _c === void 0 ? void 0 : _c.path);
        if (!path)
            return;
        return "".concat(process.env.REACT_APP_IMAGE_PREFIX).concat(path);
    }, [merchantDocument, upload]);
    var defaultMutationOptions = {
        onSuccess: function () {
            onUpdate === null || onUpdate === void 0 ? void 0 : onUpdate();
        },
        onError: function (error) {
            var description = typeof error === 'string'
                ? error
                : (error === null || error === void 0 ? void 0 : error.message) || 'An unexpected error has occurred!';
            toast({
                status: 'error',
                description: description,
            });
        },
    };
    var createMerchantDocMutation = useMutateModel('merchant_document.create');
    var updateMerchantDocMutation = useMutateModel('merchant_document.update');
    var deleteMerchantDocMutation = useMutateModel('merchant_document.delete');
    var isLoading = createMerchantDocMutation.isLoading || updateMerchantDocMutation.isLoading;
    var uploadDocument = function (uploadId) {
        return new Promise(function (resolve, reject) {
            if (merchantDocument) {
                // update
                updateMerchantDocMutation.mutate({
                    _id: merchantDocument._id,
                    tag: merchantDocument.tag,
                    value: uploadId,
                    status: isAdmin
                        ? MerchantDocumentStatus.Verified
                        : MerchantDocumentStatus.Unverified,
                    merchant_id: merchantDocument.merchant_id,
                    type: merchantDocument.type,
                }, {
                    onSuccess: function () {
                        onUpdate === null || onUpdate === void 0 ? void 0 : onUpdate();
                        setVerified(!!isAdmin);
                        toast({
                            status: 'success',
                            description: isAdmin
                                ? "".concat(label, " marked as verified")
                                : 'Document file updated',
                        });
                        setNewFile(null);
                        resolve();
                    },
                    onError: function (error) {
                        defaultMutationOptions.onError(error);
                        reject();
                    },
                });
            }
            else {
                // create
                createMerchantDocMutation.mutate({
                    tag: tag,
                    value: uploadId,
                    status: isAdmin
                        ? MerchantDocumentStatus.Verified
                        : MerchantDocumentStatus.Unverified,
                    merchant_id: merchant._id || '',
                    type: 'upload',
                }, {
                    onSuccess: function () {
                        onUpdate === null || onUpdate === void 0 ? void 0 : onUpdate();
                        setVerified(!!isAdmin);
                        toast({
                            status: 'success',
                            description: isAdmin
                                ? "".concat(label, " marked as verified")
                                : 'Document file updated',
                        });
                        setNewFile(null);
                        resolve();
                    },
                    onError: function (error) {
                        defaultMutationOptions.onError(error);
                        reject();
                    },
                });
            }
        });
    };
    var submitDocumentValue = function (value, newStatus) {
        if ((merchantDocument === null || merchantDocument === void 0 ? void 0 : merchantDocument.value) === value) {
            return;
        }
        var status = newStatus
            ? newStatus
            : isAdmin
                ? MerchantDocumentStatus.Verified
                : MerchantDocumentStatus.Unverified;
        var isVerified = status === MerchantDocumentStatus.Verified;
        if (merchantDocument) {
            updateMerchantDocMutation.mutate({
                _id: merchantDocument._id,
                value: value,
                type: merchantDocument.type,
                merchant_id: merchantDocument.merchant_id,
                tag: merchantDocument.tag,
                status: status,
            }, __assign(__assign({}, defaultMutationOptions), { onSuccess: function () {
                    setVerified(isVerified);
                    onUpdate === null || onUpdate === void 0 ? void 0 : onUpdate();
                    toast({
                        status: 'success',
                        description: "".concat(label, " has been updated"),
                    });
                } }));
        }
        else {
            createMerchantDocMutation.mutate({
                tag: tag,
                value: value,
                status: status,
                merchant_id: merchant._id || '',
                type: 'string',
            }, __assign(__assign({}, defaultMutationOptions), { onSuccess: function () {
                    setVerified(isVerified);
                    onUpdate === null || onUpdate === void 0 ? void 0 : onUpdate();
                    toast({
                        status: 'success',
                        description: "".concat(label, " was set successfully"),
                    });
                } }));
        }
    };
    var verifyDocument = function () {
        if (!merchantDocument) {
            toast({
                status: 'error',
                description: 'Invalid document',
            });
            return;
        }
        updateMerchantDocMutation.mutate({
            _id: merchantDocument._id,
            value: merchantDocument.value,
            type: merchantDocument.type,
            merchant_id: merchantDocument.merchant_id,
            tag: merchantDocument.tag,
            status: MerchantDocumentStatus.Verified,
        }, __assign(__assign({}, defaultMutationOptions), { onSuccess: function () {
                onUpdate === null || onUpdate === void 0 ? void 0 : onUpdate();
                setVerified(true);
                toast({
                    status: 'success',
                    description: "".concat(label, " marked as verified"),
                });
            } }));
    };
    var undoVerifyDocument = function () {
        if (!merchantDocument) {
            toast({
                status: 'error',
                description: 'Invalid document',
            });
            return;
        }
        updateMerchantDocMutation.mutate({
            _id: merchantDocument._id,
            value: merchantDocument.value,
            type: merchantDocument.type,
            merchant_id: merchantDocument.merchant_id,
            tag: merchantDocument.tag,
            status: MerchantDocumentStatus.Unverified,
        }, __assign(__assign({}, defaultMutationOptions), { onSuccess: function () {
                onUpdate === null || onUpdate === void 0 ? void 0 : onUpdate();
                setVerified(false);
                toast({
                    status: 'success',
                    description: "".concat(label, " marked as unverified"),
                });
            } }));
    };
    var deleteDocument = function () {
        return new Promise(function (resolve, reject) {
            deleteMerchantDocMutation.mutate({
                _id: merchantDocument === null || merchantDocument === void 0 ? void 0 : merchantDocument._id,
            }, {
                onSuccess: function () {
                    defaultMutationOptions.onSuccess();
                    resolve();
                },
                onError: function (error) {
                    defaultMutationOptions.onError(error);
                    reject();
                },
            });
        });
    };
    useEffect(function () {
        setVerified((merchantDocument === null || merchantDocument === void 0 ? void 0 : merchantDocument.status) === MerchantDocumentStatus.Verified);
    }, [merchantDocument]);
    return (_jsxs(Box, __assign({ w: "full" }, { children: [type === 'radio' ? (_jsx(Box, __assign({ w: "full", mb: "6px" }, { children: _jsx(RadioCardGroup, { value: merchantDocument === null || merchantDocument === void 0 ? void 0 : merchantDocument.value, onChange: submitDocumentValue, options: options, isDisabled: isLoading }, void 0) }), void 0)) : type === 'checkbox' ? (_jsx(Box, __assign({ mb: "4px" }, { children: _jsx(Checkbox, __assign({ defaultChecked: false, isDisabled: isLoading || (!isAdmin && verified), isChecked: (merchantDocument === null || merchantDocument === void 0 ? void 0 : merchantDocument.value) === 'true', onChange: function (event) {
                        var isChecked = event.currentTarget.checked;
                        if (isChecked) {
                            submitDocumentValue(String(isChecked), MerchantDocumentStatus.Unverified);
                        }
                        else {
                            deleteDocument().then(function () {
                                setVerified(false);
                            });
                        }
                    } }, { children: question }), void 0) }), void 0)) : (_jsxs(_Fragment, { children: [!!fileUrl && !newFile && (_jsx(FileItem, { dataUrl: fileUrl, label: label, onChange: function (file) { return setNewFile(file); }, onDelete: isAdmin ||
                            (merchantDocument === null || merchantDocument === void 0 ? void 0 : merchantDocument.status) === MerchantDocumentStatus.Unverified
                            ? deleteDocument
                            : undefined }, void 0)), !!newFile && (_jsx(FileUploader, { file: newFile, onUpload: uploadDocument, tag: tag, label: label, onRemove: function () { return setNewFile(null); }, onUploadError: defaultMutationOptions.onError, autoStart: true }, void 0))] }, void 0)), !!merchantDocument && isAdmin ? (_jsx(Flex, __assign({ mb: "10px", gap: "10px", alignItems: "center" }, { children: verified ? (_jsxs(_Fragment, { children: [_jsx(Flex, __assign({ alignItems: "center", gap: "6px" }, { children: _jsxs(Text, __assign({ fontSize: "sm", color: "success.600" }, { children: [label, " has been verified"] }), void 0) }), void 0), _jsx(Button, __assign({ size: "sm", variant: "link", colorScheme: "critical", onClick: undoVerifyDocument, isLoading: updateMerchantDocMutation.isLoading, isDisabled: updateMerchantDocMutation.isLoading }, { children: "Undo Verify Document" }), void 0)] }, void 0)) : (_jsx(Button, __assign({ size: "sm", colorScheme: "success", onClick: verifyDocument, isLoading: updateMerchantDocMutation.isLoading, isDisabled: updateMerchantDocMutation.isLoading }, { children: "Verify Document" }), void 0)) }), void 0)) : verified ? (_jsx(_Fragment, { children: _jsx(Flex, __assign({ mb: "10px", alignItems: "center", gap: "6px" }, { children: _jsxs(Text, __assign({ fontSize: "sm", color: "success.600" }, { children: [label, " has been verified"] }), void 0) }), void 0) }, void 0)) : null] }), void 0));
};
