var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Badge } from '@chakra-ui/react';
import { InflowStatus } from '@obtainly/models';
import { TableMaker } from '@obtainly/ui/web-desktop';
import { formatUnixTime } from '@obtainly/utils';
import { CodeBox, ContentLoader } from '@obtainly/ui/web-shared';
import { useMemo } from 'react';
import { ExecuteInflow } from './ExecuteInflow';
export var InflowsTable = function (_a) {
    var inflows = _a.inflows;
    var columns = useMemo(function () {
        return [
            { Header: '#', Cell: function (_a) {
                    var row = _a.row;
                    return Number(row.id) + 1;
                } },
            {
                Header: 'Date',
                accessor: 'created',
                Cell: function (_a) {
                    var cell = _a.cell;
                    return formatUnixTime(cell.value);
                },
            },
            {
                Header: 'Amount',
                accessor: 'amount',
            },
            {
                Header: 'Merchant',
                accessor: 'merchant',
                Cell: function (_a) {
                    var _b;
                    var cell = _a.cell;
                    return (_b = cell.value) === null || _b === void 0 ? void 0 : _b.business_name;
                },
            },
            {
                Header: 'Distributor',
                accessor: 'distributor',
                Cell: function (_a) {
                    var _b;
                    var cell = _a.cell;
                    return (_b = cell.value) === null || _b === void 0 ? void 0 : _b.name;
                },
            },
            {
                Header: 'Ref.',
                accessor: 'reference',
            },
            {
                Header: 'Is Credit',
                accessor: 'is_credit',
                Cell: function (_a) {
                    var cell = _a.cell;
                    return (cell.value ? 'Yes' : 'No');
                },
            },
            {
                Header: 'From Payback',
                accessor: 'from_payback',
                Cell: function (_a) {
                    var cell = _a.cell;
                    return (cell.value ? 'Yes' : 'No');
                },
            },
            {
                Header: 'Meta',
                accessor: 'meta',
                Cell: function (_a) {
                    var cell = _a.cell;
                    return (_jsx(CodeBox, { value: cell.value }, void 0));
                },
            },
            {
                Header: 'Status',
                accessor: 'status',
                Cell: function (_a) {
                    var cell = _a.cell;
                    return (_jsx(Badge, __assign({ colorScheme: cell.value === InflowStatus.Pending ? 'orange' : 'gray' }, { children: cell.value }), void 0));
                },
            },
            {
                Header: 'Action',
                Cell: function (_a) {
                    var row = _a.row;
                    if (row.original.status === InflowStatus.Pending) {
                        return _jsx(ExecuteInflow, { id: row.original._id }, void 0);
                    }
                    else {
                        return null;
                    }
                },
            },
        ];
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(ContentLoader, { contentUnavailable: !(inflows === null || inflows === void 0 ? void 0 : inflows.length), errorMessage: "No inflows found" }, void 0), (inflows === null || inflows === void 0 ? void 0 : inflows.length) ? (_jsx(TableMaker, { data: inflows, columns: columns, tableProps: { isFit: true }, pageSize: 8 }, void 0)) : null] }, void 0));
};
