var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Badge, Flex, Text } from '@chakra-ui/layout';
import { FundingRequestStatus, FundingRequestType, } from '@obtainly/models';
import { getFundingOverdueState } from '@obtainly/utils';
import React from 'react';
import { FiActivity } from 'react-icons/fi';
import { RiTimer2Line } from 'react-icons/ri';
export var FundingRequestStatusBadge = function (_a) {
    var status = _a.status, funding = _a.funding, rest = __rest(_a, ["status", "funding"]);
    var colorScheme = status === FundingRequestStatus.Pending
        ? 'warning'
        : status === FundingRequestStatus.Approved ||
            status === FundingRequestStatus.Perfect
            ? 'success'
            : funding && getFundingOverdueState(funding).isOverdue
                ? 'critical'
                : status === FundingRequestStatus.Active ||
                    status === FundingRequestStatus.Early
                    ? 'info'
                    : status === FundingRequestStatus.Rejected ||
                        status === FundingRequestStatus.Late
                        ? 'critical'
                        : 'gray';
    return (_jsxs(Flex, __assign({ gridGap: 1 }, { children: [_jsx(Badge, __assign({ display: "flex", alignItems: "center", colorScheme: colorScheme }, rest, { children: _jsx(FiActivity, {}, void 0) }), void 0), _jsx(Text, __assign({ fontSize: "sm", fontWeight: "medium", color: "".concat(colorScheme, ".700"), textTransform: "capitalize" }, { children: funding && getFundingOverdueState(funding).isOverdue
                    ? 'overdue'
                    : status }), void 0)] }), void 0));
};
export var FundingRequestTypeBadge = function (_a) {
    var type = _a.type, rest = __rest(_a, ["type"]);
    var colorScheme = type === FundingRequestType.Flexible ? 'warning' : 'gray';
    return (_jsxs(Flex, __assign({ gridGap: 1 }, { children: [_jsx(Badge, __assign({ display: "flex", alignItems: "center", colorScheme: colorScheme }, rest, { children: _jsx(RiTimer2Line, {}, void 0) }), void 0), _jsx(Text, __assign({ fontSize: "sm", fontWeight: "medium", color: "".concat(colorScheme, ".700"), textTransform: "capitalize" }, { children: type }), void 0)] }), void 0));
};
