var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { fromUnixTime } from 'date-fns';
import { useQueryModel } from '@obtainly/http';
import { formatCurrency, formatUnixTime } from '@obtainly/utils';
import { Card, CardBody, CardHeader, ContentLoader, TableMaker, } from 'src/core/components';
import { dateAtom, useAtom } from 'src/core/store';
export var AdministratorDailySettlements = function (_a) {
    var setExportableData = _a.setExportableData, props = __rest(_a, ["setExportableData"]);
    var selectedDate = useAtom(dateAtom)[0];
    var payload = React.useMemo(function () {
        var date = fromUnixTime(selectedDate);
        var month = date.getMonth() + 1;
        var year = date.getFullYear();
        return {
            month: month,
            year: year,
        };
    }, [selectedDate]);
    var _b = useQueryModel('administrator.statement', payload, { enabled: !!payload.month && !!payload.year }), data = _b.data, isLoading = _b.isLoading;
    // aggregate all settlements from various distributors
    var settlements = React.useMemo(function () {
        var list = [];
        data === null || data === void 0 ? void 0 : data.forEach(function (tranx) {
            var all = tranx.all, distributor = tranx.distributor;
            all.list_by_day.forEach(function (settlement) {
                list.push(__assign(__assign({}, settlement), { distributor: distributor }));
            });
        });
        return list;
    }, [data]);
    var columns = React.useMemo(function () {
        return [
            { Header: '#', Cell: function (_a) {
                    var row = _a.row;
                    return Number(row.id) + 1;
                } },
            {
                Header: 'Date',
                Cell: function (_a) {
                    var cell = _a.cell;
                    return formatUnixTime(cell.value, 'd MMM yyy');
                },
                accessor: 'timestamp',
            },
            {
                Header: 'Distributor',
                accessor: 'distributor.name',
            },
            {
                Header: 'Opening Unsettled',
                Cell: function (_a) {
                    var cell = _a.cell, row = _a.row;
                    return formatCurrency(cell.value - row.original.value) || '0.00';
                },
                props: { isNumeric: true },
                accessor: 'balance',
                id: 'opening_unsettled',
            },
            {
                Header: 'Customer Payment',
                Cell: function (_a) {
                    var cell = _a.cell;
                    return formatCurrency(cell.value) || '-';
                },
                props: { isNumeric: true },
                accessor: 'payments.total',
                id: 'payment',
            },
            {
                Header: 'Settlement',
                Cell: function (_a) {
                    var cell = _a.cell;
                    return formatCurrency(Math.abs(cell.value)) || '-';
                },
                props: { isNumeric: true },
                accessor: 'processed.total',
                id: 'settlement',
            },
            {
                Header: 'Closing Unsettled',
                Cell: function (_a) {
                    var cell = _a.cell;
                    return formatCurrency(cell.value);
                },
                props: { isNumeric: true },
                accessor: 'balance',
                id: 'closing_unsettled',
            },
        ];
    }, []);
    React.useEffect(function () {
        setExportableData && setExportableData(settlements);
    }, [settlements, setExportableData]);
    return (_jsxs(Card, { children: [_jsx(ContentLoader, { isLoading: isLoading, contentUnavailable: !(settlements === null || settlements === void 0 ? void 0 : settlements.length), errorSummary: "No settlements found" }, void 0), !isLoading && (settlements === null || settlements === void 0 ? void 0 : settlements.length) ? (_jsxs(_Fragment, { children: [props.showHeader && (_jsx(CardHeader, { border: 0, borderRadius: 0, heading: "Daily Settlements", borderTop: "1px solid", borderColor: "gray.100" }, void 0)), _jsx(CardBody, __assign({ p: 0, pt: 2 }, { children: _jsx(TableMaker, { data: settlements.filter(function (_a) {
                                var value = _a.value;
                                return value !== 0;
                            }) || [], columns: columns, tableProps: { isFit: true } }, void 0) }), void 0)] }, void 0)) : null] }, void 0));
};
AdministratorDailySettlements.defaultProps = {
    showHeader: true,
};
