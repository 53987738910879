var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Stack, Box, Flex, Button, IconButton, Spinner } from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import { FiTrash2 } from 'react-icons/fi';
import * as Yup from 'yup';
import { useToast } from 'src/core/hooks';
import { useMutateModel } from '@obtainly/http';
import { FormikField } from 'src/core/components';
export var ConfigForm = function (_a) {
    var _b;
    var type = _a.type, config = _a.config, refetch = _a.refetch;
    var toast = useToast().toast;
    var createConfigMutation = useMutateModel('config.create');
    var updateConfigMutation = useMutateModel('config.update');
    var deleteConfigMutation = useMutateModel('config.delete');
    var isDeleting = deleteConfigMutation.isLoading;
    var formConfig = {
        initialValues: {
            key: (config === null || config === void 0 ? void 0 : config.key) || '',
            value: (_b = config === null || config === void 0 ? void 0 : config.value) !== null && _b !== void 0 ? _b : '',
        },
        validationSchema: Yup.object({
            key: Yup.string().required('Key field is required.'),
            value: Yup.string().required('Value field is required.'),
        }),
        onSubmit: function (values, _a) {
            var setSubmitting = _a.setSubmitting, resetForm = _a.resetForm;
            setSubmitting(true);
            var finalValues = __assign({}, values);
            if (type === 'update') {
                finalValues._id = config === null || config === void 0 ? void 0 : config._id;
                updateConfigMutation.mutate(finalValues, {
                    onSuccess: function () {
                        setSubmitting(false);
                        toast({
                            description: "".concat(values.key, " value successfully updated."),
                            status: 'success',
                        });
                        refetch === null || refetch === void 0 ? void 0 : refetch();
                    },
                    onError: function (error) {
                        setSubmitting(false);
                        toast({ description: error.message, status: 'error' });
                        throw error;
                    },
                });
            }
            else {
                createConfigMutation.mutate(finalValues, {
                    onSuccess: function () {
                        setSubmitting(false);
                        toast({
                            description: "".concat(values.key, " value successfully created."),
                            status: 'success',
                        });
                        refetch === null || refetch === void 0 ? void 0 : refetch();
                        resetForm();
                    },
                    onError: function (error) {
                        setSubmitting(false);
                        toast({ description: error === null || error === void 0 ? void 0 : error.message, status: 'error' });
                        throw error;
                    },
                });
            }
        },
    };
    var deleteConfig = function (config) {
        deleteConfigMutation.mutate({ _id: config._id }, {
            onSuccess: function () {
                toast({
                    description: "".concat(config === null || config === void 0 ? void 0 : config.key, " has been deleted."),
                    status: 'success',
                });
                refetch === null || refetch === void 0 ? void 0 : refetch();
            },
            onError: function (error) {
                toast({ description: error.message, status: 'error' });
            },
        });
    };
    return (_jsx(Formik, __assign({ enableReinitialize: true, initialValues: formConfig.initialValues, validationSchema: formConfig.validationSchema, onSubmit: formConfig.onSubmit }, { children: function (_a) {
            var handleSubmit = _a.handleSubmit, formik = __rest(_a, ["handleSubmit"]);
            return (_jsx(Form, __assign({ onSubmit: handleSubmit }, { children: _jsxs(Stack, __assign({ direction: ['column', 'column', 'row'], justifyContent: "stretch" }, { children: [_jsx(Box, __assign({ flex: 2, mb: [2, 0] }, { children: _jsx(FormikField, { size: "sm", name: "key", type: "text", placeholder: type === 'create' ? 'New Key *' : 'Key *' }, void 0) }), void 0), _jsx(Box, { children: _jsx(FormikField, { size: "sm", name: "value", type: "text", placeholder: type === 'create' ? 'New Value *' : 'Value *' }, void 0) }, void 0), _jsx(Box, { children: _jsxs(Flex, { children: [_jsx(Button, __assign({ type: "submit", size: "sm", variant: "outline", isDisabled: formik.isSubmitting ||
                                            !formik.dirty ||
                                            !formik.isValid ||
                                            isDeleting, width: "100%", isLoading: formik.isSubmitting }, { children: type === 'create' ? 'Add' : 'Update' }), void 0), type === 'update' && (_jsx(_Fragment, { children: isDeleting ? (_jsx(Flex, __assign({ ml: 2 }, { children: _jsx(Spinner, { size: "sm", color: "primary.600" }, void 0) }), void 0)) : (_jsx(IconButton, { "aria-label": "Delete", size: "sm", variant: "ghost", ml: 2, colorScheme: "critical", icon: _jsx(FiTrash2, { size: 16 }, void 0), isDisabled: formik.isSubmitting || isDeleting, onClick: function () { return !!config && deleteConfig(config); } }, void 0)) }, void 0))] }, void 0) }, void 0)] }), void 0) }), void 0));
        } }), void 0));
};
ConfigForm.defaultProps = {
    type: 'create',
};
