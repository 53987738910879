import { FundingRequestStatus } from '@obtainly/models';
import { differenceInDays, isAfter } from 'date-fns';
import { fromUnixTime } from './date';
export var INTEREST_RATE = 2.5;
export var MIN_CREDIT_AMOUNT = 100;
export function getFundingRequestPayback(amount, interestRate) {
    if (interestRate === void 0) { interestRate = INTEREST_RATE; }
    var interest = (amount * interestRate) / 100;
    var payback = interest + amount;
    return { interest: interest, payback: payback };
}
export function getFundingOverdueState(funding) {
    var output = {
        isOverdue: false,
        dueInDays: null,
    };
    if (!(funding === null || funding === void 0 ? void 0 : funding.expired_at) ||
        ![FundingRequestStatus.Active].includes(funding.status)) {
        return output;
    }
    output.isOverdue = isAfter(new Date(), fromUnixTime(funding.expired_at));
    output.dueInDays = differenceInDays(fromUnixTime(funding.expired_at), new Date());
    return output;
}
export var updateStatus = function (status) {
    switch (status) {
        case 'early':
            return 'Early Pay Back';
        case 'perfect':
            return 'Perfect Pay Back';
        case 'late':
            return 'Late Pay Back';
        case 'N/A':
            return 'Pending';
        default:
            return status;
    }
};
