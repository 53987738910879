export var MailingListType;
(function (MailingListType) {
    MailingListType["Merchant"] = "merchant";
    MailingListType["Distributor"] = "distributor";
    MailingListType["Any"] = "any";
    MailingListType["DistributorMerchant"] = "distributor|merchant";
})(MailingListType || (MailingListType = {}));
export var MailingListChannel;
(function (MailingListChannel) {
    MailingListChannel["SMS"] = "sms";
    MailingListChannel["Email"] = "email";
})(MailingListChannel || (MailingListChannel = {}));
export var MailingListTag;
(function (MailingListTag) {
    MailingListTag["Order"] = "order_notification";
    MailingListTag["OrderChange"] = "order_change_notification";
    MailingListTag["Payment"] = "payment_notification";
    MailingListTag["Error"] = "error_notification";
    MailingListTag["CreditReceived"] = "credit_request_received";
    MailingListTag["CreditRejected"] = "credit_request_rejected";
    MailingListTag["CreditApproved"] = "credit_request_approved";
    MailingListTag["CreditActive"] = "credit_request_active";
    MailingListTag["CreditPartialPayment"] = "credit_request_partial_payment";
    MailingListTag["CreditPaid"] = "credit_request_paid";
    MailingListTag["CreditDueToday"] = "credit_request_due_today";
    MailingListTag["CreditOverdue"] = "credit_request_overdue";
    MailingListTag["CreditDueInDays"] = "credit_request_due_in_days";
    MailingListTag["CreditDueInWeeks"] = "credit_request_due_in_weeks";
    MailingListTag["CreditCheckDueInWeeks"] = "credit_check_due_in_weeks";
    MailingListTag["CreditCheckDueInDays"] = "credit_check_due_in_days";
    MailingListTag["CreditCheckDueToday"] = "credit_check_due_today";
    MailingListTag["MerchantSignup"] = "merchant_signup";
    MailingListTag["CreditRetention"] = "credit_retention";
    MailingListTag["PaymentRetention"] = "payment_retention";
    MailingListTag["CustomerPayment"] = "customer_payment_notification";
    MailingListTag["MissingMailingList"] = "missing_mailing_list";
    MailingListTag["InflowNotification"] = "inflow_notification";
    MailingListTag["UsePaybackAccount"] = "use_payback_account";
})(MailingListTag || (MailingListTag = {}));
