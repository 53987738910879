export var PromotionStatus;
(function (PromotionStatus) {
    PromotionStatus["Active"] = "active";
    PromotionStatus["Inactive"] = "inactive";
})(PromotionStatus || (PromotionStatus = {}));
export var PromotionTrigger;
(function (PromotionTrigger) {
    PromotionTrigger["Quantity"] = "QTY";
    PromotionTrigger["Price"] = "PRI";
})(PromotionTrigger || (PromotionTrigger = {}));
