var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-console */
import { Button, ButtonGroup } from '@chakra-ui/button';
import { Box, useToast } from '@chakra-ui/react';
import { useMutateModel } from '@obtainly/http';
import { ContentLoader } from '@obtainly/ui/web-shared';
import { FormGroup, FormikField } from '@obtainly/ui/web-shared/FormField';
import { Form, Formik } from 'formik';
import { useMemo } from 'react';
import * as Yup from 'yup';
var narrationFormSchema = Yup.object({
    narration: Yup.string().required('Narration field is required.').max(30),
});
export var MerchantNarration = function (_a) {
    var _b, _c;
    var merchantId = _a.merchantId, distributorId = _a.distributorId, merchant = _a.merchant, refetch = _a.refetch;
    var toast = useToast();
    var merchant_tags = useMemo(function () {
        if (!(merchant === null || merchant === void 0 ? void 0 : merchant.merchant_tags))
            return null;
        return merchant === null || merchant === void 0 ? void 0 : merchant.merchant_tags[distributorId];
    }, [distributorId, merchant === null || merchant === void 0 ? void 0 : merchant.merchant_tags]);
    var defaultNarration = useMemo(function () {
        var narration = "Obtainly payment from ".concat(merchant === null || merchant === void 0 ? void 0 : merchant.business_name);
        if (narration.length > 30) {
            return narration.slice(0, 27) + '...';
        }
        return narration;
    }, [merchant]);
    var initialValues = {
        narration: ((_b = merchant_tags === null || merchant_tags === void 0 ? void 0 : merchant_tags.payment_narration) === null || _b === void 0 ? void 0 : _b.value) || defaultNarration,
    };
    var payload = useMemo(function () {
        return {
            merchant_id: merchantId,
            distributor_id: distributorId,
            tag: 'payment_narration',
        };
    }, [merchantId, distributorId]);
    var deletePayload = useMemo(function () {
        var _a;
        return {
            _id: (_a = merchant_tags === null || merchant_tags === void 0 ? void 0 : merchant_tags.payment_narration) === null || _a === void 0 ? void 0 : _a._id,
        };
    }, [(_c = merchant_tags === null || merchant_tags === void 0 ? void 0 : merchant_tags.payment_narration) === null || _c === void 0 ? void 0 : _c._id]);
    var _d = useMutateModel('merchant_tag.upsert'), isUpserting = _d.isLoading, upsertMerchantTag = __rest(_d, ["isLoading"]);
    var _e = useMutateModel('merchant_tag.delete'), isDeleting = _e.isLoading, deleteMerchantTag = __rest(_e, ["isLoading"]);
    var onSubmit = function (values) {
        upsertMerchantTag.mutate(__assign(__assign({}, payload), { value: values.narration }));
        refetch();
        toast({
            title: 'Narration updated',
            status: 'success',
            duration: 3000,
            isClosable: true,
        });
    };
    var handleReset = function () {
        deleteMerchantTag.mutate(deletePayload);
        refetch();
        toast({
            title: 'Narration reset',
            status: 'success',
            duration: 3000,
            isClosable: true,
        });
    };
    return (_jsx(Box, { children: distributorId ? (_jsx(Formik, __assign({ enableReinitialize: true, initialValues: initialValues, validationSchema: narrationFormSchema, onSubmit: onSubmit, validateOnMount: true }, { children: function (formik) { return (_jsxs(Form, __assign({ onSubmit: formik.handleSubmit }, { children: [_jsx(FormGroup, __assign({ label: "Narration" }, { children: _jsx(FormikField, { name: "narration", as: "textarea", placeholder: "Enter narration", maxLength: 30 }, void 0) }), void 0), _jsxs(ButtonGroup, __assign({ mt: 2, display: "flex", justifyContent: "space-between" }, { children: [_jsx(Button, __assign({ onClick: handleReset, isDisabled: isDeleting || !formik.isValid, isLoading: isDeleting }, { children: "Reset" }), void 0), _jsx(Button, __assign({ type: "submit", colorScheme: "primary", isDisabled: isUpserting || !formik.isValid, isLoading: isUpserting, loadingText: "Updating..." }, { children: "Update Narration" }), void 0)] }), void 0)] }), void 0)); } }), void 0)) : (_jsx(ContentLoader, { contentUnavailable: true, errorMessage: "Select a supplier" }, void 0)) }, void 0));
};
