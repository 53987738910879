import React from 'react';
/**
 * Handle click outside event
 *
 * @param {HTMLElement} element - React Ref Elemet
 * @param {Function} callback - Callback function on click outside event
 */
export function useClickOutside(element, callback) {
    var callbackRef = React.useRef();
    callbackRef.current = callback;
    React.useEffect(function () {
        var handleClickOutside = function (event) {
            var _a;
            if (!((_a = element === null || element === void 0 ? void 0 : element.current) === null || _a === void 0 ? void 0 : _a.contains(event.target)) && callbackRef.current) {
                callbackRef.current();
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return function () {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [callbackRef, element]);
}
