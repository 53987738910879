export var NotificationTag;
(function (NotificationTag) {
    NotificationTag["Order"] = "in_app_order_notification";
    NotificationTag["OrderFor"] = "in_app_order_for_notification";
    NotificationTag["OrderChange"] = "in_app_order_change_notification";
    NotificationTag["Payment"] = "in_app_payment_notification";
    NotificationTag["Funding"] = "in_app_funding_request_notification";
})(NotificationTag || (NotificationTag = {}));
export var NotificationStatus;
(function (NotificationStatus) {
    NotificationStatus["Created"] = "created";
    NotificationStatus["Seen"] = "seen";
})(NotificationStatus || (NotificationStatus = {}));
