var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Flex, Text, IconButton, Input, useToast, } from '@chakra-ui/react';
import { FiMinus, FiPlus } from 'react-icons/fi';
import { useQuantityInput } from './useQuantityInput';
export var QuantityInput = function (_a) {
    var isDisabled = _a.isDisabled, isValueEditable = _a.isValueEditable, props = __rest(_a, ["isDisabled", "isValueEditable"]);
    var toast = useToast();
    var _b = useQuantityInput(__assign(__assign({}, props), { onError: function (message) {
            toast({ status: 'error', description: message, position: 'bottom' });
        } })), value = _b.value, minValue = _b.minValue, maxValue = _b.maxValue, unit = _b.unit, setUnit = _b.setUnit, increment = _b.increment, decrement = _b.decrement, onInputBlur = _b.onInputBlur, onInputChange = _b.onInputChange;
    var buttonProps = {
        w: '34px',
        size: 'sm',
        bg: 'white',
        border: '1px solid',
        borderColor: 'primary.100',
        variant: 'ghost',
        boxShadow: 'sm',
        colorScheme: 'primary',
    };
    return (_jsxs(Flex, __assign({ alignItems: "center", gridGap: 2 }, { children: [_jsx(IconButton, __assign({}, buttonProps, { icon: _jsx(FiMinus, { size: 20 }, void 0), onClick: decrement, "aria-label": "Decrement", "data-testid": "decrement", isDisabled: isDisabled || value <= minValue }), void 0), _jsxs(Flex, __assign({ h: "100%", position: "relative", background: isValueEditable ? '#fff' : 'gray.50', border: "1px solid", borderColor: "gray.100", borderRadius: "md" }, { children: [_jsx(Input, { px: "3px", pt: 0, size: "sm", height: "20px", borderRadius: "md", _focus: { outline: '0', boxShadow: 'none' }, value: value || '0', width: "5ch", textAlign: "center", border: 0, fontWeight: "bold", fontSize: "lg", onChange: onInputChange, onBlur: onInputBlur, readOnly: !isValueEditable || isDisabled, "data-testid": "value" }, void 0), _jsx(Text, __assign({ w: "100%", bottom: 0, cursor: "default", color: "gray.600", fontSize: "10px", textAlign: "center", position: "absolute", onClick: function () {
                            // disable switching if unit is set
                            !props.unit && setUnit(unit === 'unit' ? 'case' : 'unit');
                        } }, { children: "".concat(unit).concat(Number(value) !== 1 ? 's' : '') }), void 0)] }), void 0), _jsx(IconButton, __assign({}, buttonProps, { icon: _jsx(FiPlus, { size: 20 }, void 0), onClick: increment, "aria-label": "Decrement", "data-testid": "increment", isDisabled: isDisabled || (!!maxValue && value >= maxValue) }), void 0)] }), void 0));
};
QuantityInput.defaultProps = {
    mpu: 1,
    caseCount: 1,
    unit: 'case',
};
export default QuantityInput;
