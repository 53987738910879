import queryString from 'query-string';
import { getQueryParams } from '@obtainly/utils';
export function useQueryStringParse(include, exclude) {
    var expectedObject = {};
    var params = queryString.parse(window.location.search, {
        arrayFormat: 'bracket',
    });
    if (include === null || include === void 0 ? void 0 : include.length) {
        include === null || include === void 0 ? void 0 : include.forEach(function (key) {
            if (params[key]) {
                expectedObject[key] = params[key];
            }
        });
    }
    else {
        expectedObject = params;
    }
    if (exclude === null || exclude === void 0 ? void 0 : exclude.length) {
        exclude === null || exclude === void 0 ? void 0 : exclude.forEach(function (key) {
            if (expectedObject[key]) {
                delete expectedObject[key];
            }
        });
    }
    return expectedObject;
}
export function useQueryParams() {
    var params = getQueryParams();
    return new Proxy(params, {
        get: function (target, prop) {
            return target.get(prop);
        },
    });
}
