import { format, fromUnixTime as dateFnsFromUnixTime, intervalToDuration, formatDuration, sub, getUnixTime as dateFnsGetUnixTime, } from 'date-fns';
export var fromUnixTime = dateFnsFromUnixTime;
export var getUnixTime = dateFnsGetUnixTime;
export function formatUnixTime(timestamp, formatString) {
    if (formatString === void 0) { formatString = 'd MMM yyy, HH:mm'; }
    return timestamp
        ? format(dateFnsFromUnixTime(timestamp), formatString)
        : timestamp;
}
export function formatTimeDuration(seconds) {
    if (isNaN(seconds))
        return '';
    var date = sub(new Date(), { seconds: seconds });
    var duration = intervalToDuration({
        start: date,
        end: new Date(),
    });
    return formatDuration(duration, {
        format: duration.days && duration.days > 0
            ? ['days']
            : duration.hours && duration.hours > 0
                ? ['hours']
                : [],
    }).replace('days', 'working days');
}
export var months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
];
export var monthsLong = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];
