var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Center, Flex, Grid, GridItem, Image, Text, useDisclosure, } from '@chakra-ui/react';
import { Fragment, useRef, useState } from 'react';
import { RiEyeLine } from 'react-icons/ri';
import { FilePreviewer, FileSelector } from './';
import DefaultImageIcon from '../Image/img-icon.svg';
import PDFIcon from '../Image/pdf-icon.svg';
export var FileItem = function (props) {
    var dataUrl = props.dataUrl, label = props.label, onChange = props.onChange, onDelete = props.onDelete;
    var _a = useDisclosure(), isOpen = _a.isOpen, onClose = _a.onClose, onOpen = _a.onOpen;
    var fileSelectorRef = useRef(null);
    var _b = useState(false), deleting = _b[0], setDeleting = _b[1];
    var openFileExplorer = function () {
        var _a;
        (_a = fileSelectorRef.current) === null || _a === void 0 ? void 0 : _a.click();
    };
    var handleChange = function (fileSet) {
        if (fileSet.size) {
            onChange(Array.from(fileSet.values())[0]);
        }
    };
    var handleDelete = function () {
        setDeleting(true);
        onDelete === null || onDelete === void 0 ? void 0 : onDelete().finally(function () { return setDeleting(false); });
    };
    return (_jsxs(Fragment, { children: [_jsx(FilePreviewer, { isOpen: isOpen, onClose: onClose, data: dataUrl }, void 0), _jsx(FileSelector, { ref: fileSelectorRef, onSelect: handleChange, multiple: false }, void 0), _jsx(Box, __assign({ display: "block", borderRadius: "md", overflow: "hidden", bg: "gray.50", mb: "6px" }, { children: _jsxs(Grid, __assign({ gap: 2, templateColumns: "auto 1fr auto", templateRows: "1fr", alignItems: "center" }, { children: [_jsx(GridItem, { children: !!dataUrl && (_jsxs(Box, __assign({ position: "relative", cursor: "zoom-in", onClick: onOpen }, { children: [_jsx(Image, { boxSize: "60px", objectFit: "cover", src: dataUrl, fallbackSrc: dataUrl.slice(dataUrl.lastIndexOf('.')) === '.pdf'
                                            ? PDFIcon
                                            : DefaultImageIcon }, void 0), _jsx(Box, __assign({ position: "absolute", top: "0px", left: "0px", boxSize: "32px", bgGradient: "linear(to-tl, transparent, transparent, white)" }, { children: _jsx(Center, __assign({ boxSize: "18px", fontSize: "14px", color: "gray.900" }, { children: _jsx(RiEyeLine, {}, void 0) }), void 0) }), void 0)] }), void 0)) }, void 0), _jsxs(GridItem, { children: [_jsx(Text, __assign({ wordBreak: "break-all", noOfLines: 1 }, { children: label || dataUrl }), void 0), !!label && (_jsx(Text, __assign({ wordBreak: "break-all", noOfLines: 1, fontSize: "xs", color: "gray.700" }, { children: dataUrl.slice(-50) }), void 0))] }, void 0), _jsx(GridItem, __assign({ pr: "10px" }, { children: _jsxs(Flex, __assign({ justify: { lg: 'end', sm: 'start' }, gap: 2, flexWrap: "wrap" }, { children: [_jsx(Button, __assign({ size: "xs", colorScheme: "primary", onClick: openFileExplorer, disabled: deleting }, { children: "Change" }), void 0), !!onDelete && (_jsx(Button, __assign({ size: "xs", colorScheme: "critical", isLoading: deleting, disabled: deleting, onClick: handleDelete }, { children: "Delete" }), void 0))] }), void 0) }), void 0)] }), void 0) }), void 0)] }, void 0));
};
