var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Grid, SimpleGrid, Tab, TabList, TabPanel, TabPanels, Tabs, Text, } from '@chakra-ui/react';
import { useRouter } from '@obtainly/hooks';
import { useQueryModel } from '@obtainly/http';
import { Card, CardBody, CardHeader } from '@obtainly/ui/web-shared';
import React, { useMemo } from 'react';
import { useParams } from 'react-router';
import { ContentHeader, Layout } from 'src/core/components';
import { AddPayoutAccount, DistributorMerchants, DistributorStats, } from '../components';
import { Payments } from '../components/Payments';
export var Supplier = function () {
    var _a;
    var router = useRouter('supplier');
    var distributorId = useParams().id;
    var _b = useQueryModel('distributor.read', {
        _id: distributorId,
    }), distributors = _b.data, isLoading = _b.isLoading, refetch = _b.refetch;
    var distributor = useMemo(function () {
        return (distributors === null || distributors === void 0 ? void 0 : distributors[0]) || null;
    }, [distributors]);
    var onBack = function () { return router.goBack(); };
    var details = React.useMemo(function () {
        var _a;
        if (!distributor)
            return null;
        var accountDetails = (_a = distributor === null || distributor === void 0 ? void 0 : distributor.accounts) === null || _a === void 0 ? void 0 : _a.nuban;
        var meta = (accountDetails === null || accountDetails === void 0 ? void 0 : accountDetails.meta) ? JSON.parse(accountDetails === null || accountDetails === void 0 ? void 0 : accountDetails.meta) : null;
        if (!meta)
            return null;
        return [
            {
                label: 'Name',
                value: distributor.name,
            },
            {
                label: 'Bank Name',
                value: meta === null || meta === void 0 ? void 0 : meta.bank_name,
            },
            {
                label: 'Bank Account Name',
                value: meta === null || meta === void 0 ? void 0 : meta.account_name,
            },
            {
                label: 'Bank Account Number',
                value: meta === null || meta === void 0 ? void 0 : meta.account_number,
            },
        ];
    }, [distributor]);
    return (_jsxs(Layout, __assign({ meta: { title: 'Supplier' } }, { children: [_jsx(ContentHeader, { heading: "".concat((_a = distributor === null || distributor === void 0 ? void 0 : distributor.name) !== null && _a !== void 0 ? _a : (isLoading ? '...' : 'Customer')), onBack: onBack, border: 0 }, void 0), _jsx(DistributorStats, { distributorId: distributorId }, void 0), _jsxs(Grid, __assign({ mt: "1rem", w: "full", gridTemplateColumns: ['1fr', '300px 41.875rem'], gap: "20px", overflowX: "hidden" }, { children: [_jsx(Box, { children: _jsxs(Card, { children: [details ? _jsx(CardHeader, { heading: "Supplier Details" }, void 0) : null, _jsx(CardBody, __assign({ pt: 0 }, { children: _jsx(SimpleGrid, __assign({ templateColumns: "1fr", mt: 3, spacing: 3, alignItems: "stretch" }, { children: details ? (details.map(function (detail) { return (_jsxs(Box, { children: [_jsx(Text, __assign({ fontSize: "sm", fontWeight: "medium", color: "gray.600" }, { children: detail.label }), void 0), _jsx(Text, { children: detail.value }, void 0)] }, detail.label)); })) : (_jsx(AddPayoutAccount, { refetch: refetch, supplierId: distributorId }, void 0)) }), void 0) }), void 0)] }, void 0) }, void 0), _jsxs(Tabs, __assign({ bg: "white", pt: 3, rounded: "md" }, { children: [_jsxs(TabList, __assign({ px: 4 }, { children: [_jsx(Tab, { children: "Customers" }, void 0), _jsx(Tab, { children: "Payments" }, void 0)] }), void 0), _jsxs(TabPanels, { children: [_jsx(TabPanel, { children: _jsx(Box, __assign({ w: "full", overflowX: "scroll" }, { children: distributor ? (_jsx(DistributorMerchants, { distributor: distributor }, void 0)) : null }), void 0) }, void 0), _jsx(TabPanel, { children: _jsx(Box, { children: _jsx(Payments, { supplierId: distributorId }, void 0) }, void 0) }, void 0)] }, void 0)] }), void 0)] }), void 0)] }), void 0));
};
