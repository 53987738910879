import React from 'react';
/**
 * Paginate array of data
 *
 * @param data
 * @param page - current page
 * @param perPage
 */
export var paginate = function (data, page, perPage) {
    if (page === void 0) { page = 1; }
    if (perPage === void 0) { perPage = 10; }
    var indexOfLastData = page * perPage;
    var indexOfFirstData = indexOfLastData - perPage;
    return data.slice(indexOfFirstData, indexOfLastData);
};
export function usePagination(data, _perPage) {
    if (_perPage === void 0) { _perPage = 10; }
    var _a = React.useState(1), page = _a[0], setPage = _a[1];
    var _b = React.useState(_perPage), perPage = _b[0], setPerPage = _b[1];
    var result = React.useMemo(function () {
        return (data === null || data === void 0 ? void 0 : data.length) ? paginate(data, page, perPage) : [];
    }, [page, data, perPage]);
    return {
        data: result,
        pageCount: (data === null || data === void 0 ? void 0 : data.length) ? Math.ceil(data.length / perPage) : 0,
        page: page,
        setPage: setPage,
        perPage: perPage,
        setPerPage: setPerPage,
    };
}
