var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Td as ChakraTd, } from '@chakra-ui/table';
import { TableContext } from './Table';
export var Td = function (_a) {
    var children = _a.children, isSticky = _a.isSticky, props = __rest(_a, ["children", "isSticky"]);
    var tableProps = React.useContext(TableContext);
    return (_jsx(ChakraTd, __assign({ py: tableProps.isMini ? '6px' : '12px', px: "20px", bg: "white", borderStyle: "solid", borderColor: "gray.100", borderWidth: tableProps.hasBorders ? '1px' : '0 0 1px 0', position: isSticky ? 'sticky' : 'static', left: 0, zIndex: isSticky ? 1 : 0, transition: "background 300ms", css: {
            width: tableProps.isFit ? '1%' : 'inherit',
            whiteSpace: tableProps.isFit ? 'nowrap' : 'normal',
            '&:first-of-type': { borderLeft: 0 },
            '&:last-of-type': { borderRight: 0 },
        } }, props, { children: children }), void 0));
};
