var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { TableMaker } from '@obtainly/ui/web-desktop';
import { formatCurrency, formatUnixTime } from '@obtainly/utils';
import { Text } from '@chakra-ui/react';
import { CodeBox, ContentLoader, FundingRequestStatusBadge, FundingRequestTypeBadge, } from '@obtainly/ui/web-shared';
import FundingRequestActions from './FundingRequestActions';
function parseMeta(meta) {
    var result = '';
    try {
        result = JSON.stringify(JSON.parse(meta), null, 2);
    }
    catch (e) {
        result = '';
    }
    return result;
}
export var FundingRequestsTable = function (_a) {
    var fundingRequests = _a.fundingRequests;
    var columns = React.useMemo(function () {
        return [
            { Header: '#', Cell: function (_a) {
                    var row = _a.row;
                    return Number(row.id) + 1;
                } },
            {
                Header: 'Status',
                accessor: 'status',
                Cell: function (_a) {
                    var row = _a.row;
                    return (_jsx(FundingRequestStatusBadge, { status: row.original.status, funding: row.original }, void 0));
                },
            },
            {
                Header: 'Type',
                accessor: 'type',
                Cell: function (_a) {
                    var row = _a.row;
                    return (_jsx(FundingRequestTypeBadge, { type: row.original.type }, void 0));
                },
            },
            {
                Header: 'Date',
                accessor: 'created',
                Cell: function (_a) {
                    var cell = _a.cell;
                    return (_jsxs(_Fragment, { children: [_jsx(Text, { children: formatUnixTime(cell.value, 'dd MMM, yyyy') }, void 0), _jsx(Text, __assign({ fontSize: "xs", color: "gray.700" }, { children: formatUnixTime(cell.value, 'hh:MM a') }), void 0)] }, void 0));
                },
            },
            {
                Header: 'Ref',
                accessor: 'ref',
                Cell: function (_a) {
                    var cell = _a.cell;
                    return (_jsxs(Text, __assign({ maxW: "180px", isTruncated: true }, { children: ["#", cell.value] }), void 0));
                },
            },
            {
                Header: 'Customer',
                accessor: 'merchant',
                Cell: function (_a) {
                    var _b;
                    var cell = _a.cell;
                    return (_jsx(Text, __assign({ maxW: "180px", isTruncated: true }, { children: ((_b = cell.value) === null || _b === void 0 ? void 0 : _b.business_name) || '-' }), void 0));
                },
            },
            {
                Header: 'Distributor',
                accessor: 'distributor',
                Cell: function (_a) {
                    var _b;
                    var cell = _a.cell;
                    return (_jsx(Text, __assign({ maxW: "180px", isTruncated: true }, { children: ((_b = cell.value) === null || _b === void 0 ? void 0 : _b.name) || '-' }), void 0));
                },
            },
            {
                Header: 'Amount',
                accessor: 'amount',
                Cell: function (_a) {
                    var cell = _a.cell;
                    return formatCurrency(cell.value);
                },
                props: { isNumeric: true },
            },
            {
                Header: 'Interest rate',
                accessor: 'interest',
                props: { isNumeric: true },
            },
            {
                Header: 'Due Date',
                accessor: 'expired_at',
                Cell: function (_a) {
                    var cell = _a.cell;
                    return cell.value ? (_jsxs(_Fragment, { children: [_jsx(Text, { children: formatUnixTime(cell.value, 'dd MMM, yyyy') }, void 0), _jsx(Text, __assign({ fontSize: "xs", color: "gray.700" }, { children: formatUnixTime(cell.value, 'hh:MM a') }), void 0)] }, void 0)) : ('-');
                },
            },
            {
                Header: 'Paid At',
                accessor: 'paid_at',
                Cell: function (_a) {
                    var cell = _a.cell;
                    return cell.value ? (_jsxs(_Fragment, { children: [_jsx(Text, { children: formatUnixTime(cell.value, 'dd MMM, yyyy') }, void 0), _jsx(Text, __assign({ fontSize: "xs", color: "gray.700" }, { children: formatUnixTime(cell.value, 'hh:MM a') }), void 0)] }, void 0)) : ('-');
                },
            },
            {
                Header: 'Meta',
                accessor: 'meta',
                Cell: function (_a) {
                    var row = _a.row;
                    return (_jsx(CodeBox, { value: parseMeta(row.original.meta) }, void 0));
                },
            },
            {
                Header: 'Action',
                accessor: '_id',
                Cell: function (_a) {
                    var row = _a.row;
                    return (_jsx(FundingRequestActions, { fundingRequest: row.original }, void 0));
                },
            },
        ];
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(ContentLoader, { contentUnavailable: !(fundingRequests === null || fundingRequests === void 0 ? void 0 : fundingRequests.length), errorMessage: "No credit found" }, void 0), (fundingRequests === null || fundingRequests === void 0 ? void 0 : fundingRequests.length) ? (_jsx(TableMaker, { data: fundingRequests, columns: columns, tableProps: { isFit: true }, pageSize: 8 }, void 0)) : null] }, void 0));
};
