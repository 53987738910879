export var Modal = {
    parts: ['dialog', 'modal', 'content', 'header', 'footer', 'closeButton'],
    baseStyle: {
        dialog: {
            width: '90% !important',
        },
        header: {
            borderBottom: '1px solid',
            borderColor: 'gray.100',
            fontSize: 'lg',
            fontWeight: 'semibold',
        },
        closeButton: {
            top: '12px',
        },
        body: {
            padding: '24px',
            maxHeight: '60vh',
            overflowY: 'auto',
        },
        footer: {
            borderTop: '1px solid',
            borderColor: 'gray.100',
        },
    },
};
