var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useOnline } from '@obtainly/hooks';
import { ErrorBox, RouteSwitcher, SuspenseLoader, } from '@obtainly/ui/web-shared';
import { useAtom, authAtom } from 'src/core/store';
import { useToast } from 'src/core/hooks';
import { AppInit } from './AppInit';
export var App = function (props) {
    var _a = useToast(), toast = _a.toast, closeAllToasts = _a.closeAllToasts;
    var online = useOnline(function () { return closeAllToasts(); }, function () {
        toast({
            status: 'error',
            description: "You're not connected to the internet.",
            isClosable: true,
        });
    });
    var auth = useAtom(authAtom)[0];
    return (_jsx(BrowserRouter, { children: _jsxs("div", __assign({ className: "app" }, { children: [_jsx(AppInit, { isOnline: online }, void 0), !online ? (_jsx(ErrorBox, { type: "network", my: 150 }, void 0)) : (_jsx("main", { children: auth.isLoading ? (_jsx(SuspenseLoader, {}, void 0)) : (_jsx(RouteSwitcher, { isAuthenticated: !!auth.token, routes: props.routes }, void 0)) }, void 0))] }), void 0) }, void 0));
};
