var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { useToast as useChakraToast } from '@chakra-ui/react';
var defaultOptions = {
    position: 'bottom',
};
export function useToast() {
    var chakraToast = useChakraToast();
    var toast = React.useCallback(function (options) {
        chakraToast(__assign(__assign({}, defaultOptions), options));
    }, [chakraToast]);
    var closeAllToasts = React.useCallback(function () {
        chakraToast.closeAll();
    }, [chakraToast]);
    return { toast: toast, closeAllToasts: closeAllToasts };
}
