var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useTheme } from '@chakra-ui/react';
import { debounce } from 'lodash';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { basicSearch } from '@obtainly/utils';
export var Autocomplete = function (_a) {
    var size = _a.size, isAsync = _a.isAsync, isInvalid = _a.isInvalid, props = __rest(_a, ["size", "isAsync", "isInvalid"]);
    var colors = useTheme().colors;
    var customStyles = {
        container: function (styles) { return (__assign(__assign({}, styles), { width: '100%' })); },
        control: function (styles) { return (__assign(__assign({}, styles), { background: props.isDisabled ? colors.gray['50'] : '#fff', border: "1px solid ".concat(isInvalid ? colors.critical['600'] : colors.gray['200']), borderWidth: isInvalid ? '2px !important' : '1px !important', borderRadius: size === 'sm' ? '6px' : '6px', paddingTop: size === 'sm' ? '0' : '2px', paddingLeft: size === 'sm' ? '3px' : '8px', transition: 'all 0.3s', minHeight: size === 'sm' ? '26px' : '42px', height: size === 'sm' ? '34px' : '42px', minWidth: '180px', ':hover': __assign(__assign({}, styles[':hover']), { border: "1px solid ".concat(colors.gray['400']), cursor: 'default' }), ':focus': __assign(__assign({}, styles[':focus']), { border: "1px solid ".concat(colors.primary['600']), borderWidth: '1px' }) })); },
        dropdownIndicator: function (styles) { return (__assign(__assign({}, styles), { svg: __assign(__assign({}, styles['svg']), { color: colors.gray['400'], width: '18px', height: '18px', position: 'relative', right: '1px' }) })); },
        indicatorSeparator: function (styles) { return (__assign(__assign({}, styles), { display: 'none' })); },
        input: function (styles) { return (__assign(__assign({}, styles), { color: colors.black })); },
        noOptionsMessage: function (styles) { return (__assign(__assign({}, styles), { color: colors.gray['600'] })); },
        menu: function (styles) { return (__assign(__assign({}, styles), { border: "1px solid ".concat(colors.gray['200']), borderRadius: size === 'sm' ? '6px' : '6px', boxShadow: 'none', padding: '8px' })); },
        multiValue: function (styles) { return (__assign(__assign({}, styles), { backgroundColor: colors.gray['50'], margin: '0 2px 0' })); },
        multiValueLabel: function (styles) { return (__assign(__assign({}, styles), { padding: '3px 3px 2px' })); },
        option: function (styles, _a) {
            var isDisabled = _a.isDisabled, isFocused = _a.isFocused, isSelected = _a.isSelected;
            return (__assign(__assign({}, styles), { backgroundColor: isDisabled
                    ? null
                    : isSelected
                        ? colors.gray['100']
                        : isFocused
                            ? colors.gray['50']
                            : null, borderRadius: '6px', marginBottom: '4px', color: isDisabled ? '#ccc' : colors.black, cursor: isDisabled ? 'not-allowed' : 'pointer', ':active': __assign(__assign({}, styles[':active']), { backgroundColor: !isDisabled &&
                        (isSelected ? colors.primary['600'] : colors.gray['50']) }) }));
        },
        placeholder: function (styles) { return (__assign(__assign({}, styles), { color: colors.gray['600'], 
            /** Adjust vertical position */
            top: '48%' })); },
        singleValue: function (styles) { return (__assign(__assign({}, styles), { color: colors.black, 
            /** Adjust vertical position */
            top: '48%' })); },
        valueContainer: function (styles) { return (__assign(__assign({}, styles), { flexWrap: 'nowrap', padding: '1px 8px' })); },
    };
    var styleProps = {
        theme: function (theme) { return (__assign(__assign({}, theme), { colors: __assign(__assign({}, theme.colors), { primary: colors.primary['600'] }) })); },
        styles: customStyles,
    };
    var options = props.options, asyncProps = __rest(props, ["options"]);
    var selectedOption = React.useMemo(function () {
        return options
            ? options.find(function (option) { return (option === null || option === void 0 ? void 0 : option.value) === (props === null || props === void 0 ? void 0 : props.value); })
            : null;
    }, [options, props === null || props === void 0 ? void 0 : props.value]);
    return isAsync ? (_jsx(AsyncSelect, __assign({}, asyncProps, styleProps, { value: selectedOption, loadOptions: debounce(function (query, callback) {
            var _a, _b;
            if (((_b = (_a = query === null || query === void 0 ? void 0 : query.trim) === null || _a === void 0 ? void 0 : _a.call(query)) === null || _b === void 0 ? void 0 : _b.length) > 2) {
                var results = (options === null || options === void 0 ? void 0 : options.length)
                    ? options.filter(function (option) {
                        return basicSearch(option.label, query);
                    })
                    : [];
                callback(results);
            }
        }, 250) }), void 0)) : (_jsx(Select, __assign({}, props, styleProps, { value: selectedOption }), void 0));
};
// {...props} {...styleProps}
export default Autocomplete;
