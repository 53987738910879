var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Flex, Text } from '@chakra-ui/layout';
import { Stat, StatGroup, StatLabel, StatNumber } from '@chakra-ui/stat';
import { fromUnixTime } from 'date-fns';
import React, { useMemo } from 'react';
import { FiPercent } from 'react-icons/fi';
import { useQueryModel } from '@obtainly/http';
import { formatCurrency } from '@obtainly/utils';
import { Card, CardBody, CardHeader, ContentLoader, TableMaker, } from 'src/core/components';
import { dateAtom, useAtom } from 'src/core/store';
export var AdministratorReconciliation = function (_a) {
    var setExportableData = _a.setExportableData, props = __rest(_a, ["setExportableData"]);
    var selectedDate = useAtom(dateAtom)[0];
    var payload = React.useMemo(function () {
        var date = fromUnixTime(selectedDate);
        var month = date.getMonth() + 1;
        var year = date.getFullYear();
        return {
            month: month,
            year: year,
        };
    }, [selectedDate]);
    var _b = useQueryModel('administrator.reconciliation', payload, { enabled: !!payload.year && !!payload.month }), data = _b.data, isLoading = _b.isLoading;
    var _c = useQueryModel('ledger_tag.paystack_check_balance', {
        context: 'regular',
    }), paystackPayload = _c.data, loadingPaystackBalance = _c.isLoading;
    var _d = useQueryModel('ledger_tag.paystack_check_balance', {
        context: 'payback',
    }), paystackPayback = _d.data, loadingPaybackBalance = _d.isLoading;
    var paystackBalance = useMemo(function () {
        var _a, _b, _c;
        var balanceObject = ((_a = paystackPayload === null || paystackPayload === void 0 ? void 0 : paystackPayload.data) === null || _a === void 0 ? void 0 : _a[0])
            ? __assign(__assign({}, (_b = paystackPayload === null || paystackPayload === void 0 ? void 0 : paystackPayload.data) === null || _b === void 0 ? void 0 : _b[0]), { balance: ((_c = paystackPayload === null || paystackPayload === void 0 ? void 0 : paystackPayload.data) === null || _c === void 0 ? void 0 : _c[0].balance) / 100 }) : null;
        return balanceObject;
    }, [paystackPayload]);
    var paystackPaybackBalance = useMemo(function () {
        var _a, _b, _c;
        var balanceObject = ((_a = paystackPayback === null || paystackPayback === void 0 ? void 0 : paystackPayback.data) === null || _a === void 0 ? void 0 : _a[0])
            ? __assign(__assign({}, (_b = paystackPayback === null || paystackPayback === void 0 ? void 0 : paystackPayback.data) === null || _b === void 0 ? void 0 : _b[0]), { balance: ((_c = paystackPayback === null || paystackPayback === void 0 ? void 0 : paystackPayback.data) === null || _c === void 0 ? void 0 : _c[0].balance) / 100 }) : null;
        return balanceObject;
    }, [paystackPayback]);
    var reconciliations = React.useMemo(function () {
        if (data === null || data === void 0 ? void 0 : data.all.length) {
            return data.all;
        }
        return [];
    }, [data]);
    var columns = React.useMemo(function () {
        return [
            { Header: '#', Cell: function (_a) {
                    var row = _a.row;
                    return Number(row.id) + 1;
                } },
            {
                Header: 'Supplier',
                accessor: 'party.name',
            },
            {
                Header: 'Pay-in (Supplier)',
                Cell: function (_a) {
                    var cell = _a.cell;
                    return formatCurrency(cell.value) || '0.00';
                },
                props: { isNumeric: true },
                accessor: 'processor.pay_in.total',
            },
            {
                Header: 'Pay-Out (Supplier)',
                Cell: function (_a) {
                    var cell = _a.cell;
                    return formatCurrency(cell.value) || '0.00';
                },
                props: { isNumeric: true },
                accessor: 'distributor.pay_out.total',
            },
            {
                Header: 'Charges',
                Cell: function (_a) {
                    var cell = _a.cell;
                    return formatCurrency(cell.value) || '-';
                },
                props: { isNumeric: true },
                accessor: 'obtainly.charges.total',
                id: 'payment',
            },
        ];
    }, []);
    var stats = React.useMemo(function () {
        return [
            {
                icon: _jsx(FiPercent, {}, void 0),
                iconColor: 'success.600',
                label: 'Pay In',
                value: data === null || data === void 0 ? void 0 : data.summary.processor.pay_in.total,
            },
            {
                icon: _jsx(FiPercent, {}, void 0),
                iconColor: 'critical.600',
                label: 'Payout Out',
                value: data === null || data === void 0 ? void 0 : data.summary.distributor.pay_out.total,
            },
            {
                icon: _jsx(FiPercent, {}, void 0),
                label: 'Charges',
                value: data === null || data === void 0 ? void 0 : data.summary.obtainly.charges.total,
            },
            {
                icon: _jsx(FiPercent, {}, void 0),
                label: 'Balance',
                value: loadingPaystackBalance ? '...' : paystackBalance === null || paystackBalance === void 0 ? void 0 : paystackBalance.balance,
            },
            {
                icon: _jsx(FiPercent, {}, void 0),
                label: 'Payback Bal.',
                value: loadingPaybackBalance ? '...' : paystackPaybackBalance === null || paystackPaybackBalance === void 0 ? void 0 : paystackPaybackBalance.balance,
            },
        ];
    }, [
        data,
        loadingPaystackBalance,
        paystackBalance,
        loadingPaybackBalance,
        paystackPaybackBalance,
    ]);
    React.useEffect(function () {
        setExportableData && setExportableData(reconciliations);
    }, [reconciliations, setExportableData]);
    return (_jsxs(Card, { children: [_jsx(ContentLoader, { isLoading: isLoading, contentUnavailable: !(reconciliations === null || reconciliations === void 0 ? void 0 : reconciliations.length), errorSummary: "No reconciliations found" }, void 0), !isLoading && (reconciliations === null || reconciliations === void 0 ? void 0 : reconciliations.length) ? (_jsxs(_Fragment, { children: [props.showHeader && (_jsx(CardHeader, { border: 0, borderRadius: 0, heading: "Supplier Reconciliation", borderTop: "1px solid", borderColor: "gray.100" }, void 0)), _jsx(CardBody, { children: _jsx(StatGroup, __assign({ display: "grid", gridGap: [6, 2], gridTemplateColumns: ['repeat(2, 1fr)', 'repeat(5, 1fr)'] }, { children: stats.map(function (stat) { return (_jsxs(Stat, { children: [_jsxs(StatNumber, { children: ["NGN ", formatCurrency(stat.value || 0, { compact: true })] }, void 0), _jsx(StatLabel, __assign({ fontWeight: "regular", color: "gray.700" }, { children: _jsxs(Flex, __assign({ alignItems: "center", gridGap: 1 }, { children: [_jsx(Box, __assign({ color: stat.iconColor || 'gray.600' }, { children: stat.icon }), void 0), _jsx(Text, { children: stat.label }, void 0)] }), void 0) }), void 0)] }, stat.label)); }) }), void 0) }, void 0), _jsx(CardBody, __assign({ p: 0, pt: 2 }, { children: _jsx(TableMaker, { data: reconciliations.filter(function (_a) {
                                var value = _a.value;
                                return value !== 0;
                            }) || [], columns: columns, tableProps: { isFit: true } }, void 0) }), void 0)] }, void 0)) : null] }, void 0));
};
AdministratorReconciliation.defaultProps = {
    showHeader: true,
};
