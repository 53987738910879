var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from '@chakra-ui/button';
import { Text, VStack } from '@chakra-ui/layout';
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, } from '@chakra-ui/modal';
import { ErrorMessage, Form, Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { useMutateModel } from '@obtainly/http';
import { MailingListChannel, MailingListTag, MailingListType, } from '@obtainly/models';
import { capitalizeString } from '@obtainly/utils';
import { FormGroup, FormikField, FormikRadioGroup } from 'src/core/components';
import { FormikSelect } from 'src/core/components/FormField/FormikSelect';
import { useToast } from 'src/core/hooks';
import { MailingListIdentifierAutocomplete } from './MailingListIdentifierAutocomplete';
var mailingListFormSchema = Yup.object({
    type: Yup.string().required('Type is required'),
    identifier: Yup.string().required('Identifier is required'),
    tag: Yup.string().required('Tag is required'),
    channel: Yup.string().required('Channel is required'),
    recipients: Yup.string().required('Recipients is required'),
});
export var MailingListForm = function (props) {
    var _a;
    var toast = useToast().toast;
    var action = props.action, mailingList = props.mailingList;
    var MailingListTagLabels = (_a = {},
        _a[MailingListTag.Order] = 'Order',
        _a[MailingListTag.OrderChange] = 'Order Change',
        _a[MailingListTag.Payment] = 'Payment',
        _a[MailingListTag.Error] = 'Error',
        _a[MailingListTag.CreditReceived] = 'Credit Received',
        _a[MailingListTag.CreditRejected] = 'Credit Rejected',
        _a[MailingListTag.CreditApproved] = 'Credit Approved',
        _a[MailingListTag.CreditActive] = 'Credit Active',
        _a[MailingListTag.CreditPartialPayment] = 'Credit Partial Payment',
        _a[MailingListTag.CreditPaid] = 'Credit Paid',
        _a[MailingListTag.CreditDueToday] = 'Credit Due Today',
        _a[MailingListTag.CreditOverdue] = 'Credit Overdue',
        _a[MailingListTag.CreditDueInDays] = 'Credit Due-In-Days',
        _a[MailingListTag.CreditDueInWeeks] = 'Credit Due-In-Weeks',
        _a[MailingListTag.MerchantSignup] = 'Merchant Signup',
        _a[MailingListTag.CreditRetention] = 'Credit Retention',
        _a[MailingListTag.PaymentRetention] = 'Payment Retention',
        _a[MailingListTag.CustomerPayment] = 'Customer Payment',
        _a[MailingListTag.MissingMailingList] = 'Missing Mailing List',
        _a[MailingListTag.InflowNotification] = 'Inflow Notification',
        _a[MailingListTag.CreditCheckDueToday] = 'Credit Check Due Today',
        _a[MailingListTag.CreditCheckDueInDays] = 'Credit Check Due-In-Days',
        _a[MailingListTag.CreditCheckDueInWeeks] = 'Credit Check Due-In-Weeks',
        _a[MailingListTag.UsePaybackAccount] = 'Use Payback Account',
        _a);
    var typeOptions = Object.entries(MailingListType).map(function (_a) {
        var label = _a[0], value = _a[1];
        return ({
            value: value,
            label: label === 'DistributorMerchant' ? 'Distributor & Merchant' : label,
        });
    });
    var createMailingList = useMutateModel('mailing_list.create');
    var updateMailingList = useMutateModel('mailing_list.update');
    var initialValues = {
        type: (mailingList === null || mailingList === void 0 ? void 0 : mailingList.type) || MailingListType.Any,
        status: (mailingList === null || mailingList === void 0 ? void 0 : mailingList.status) || 'active',
        identifier: (mailingList === null || mailingList === void 0 ? void 0 : mailingList.identifier) || '',
        tag: (mailingList === null || mailingList === void 0 ? void 0 : mailingList.tag) || MailingListTag.Order,
        channel: (mailingList === null || mailingList === void 0 ? void 0 : mailingList.channel) || MailingListChannel.Email,
        recipients: (mailingList === null || mailingList === void 0 ? void 0 : mailingList.recipients) || '',
    };
    var onSubmit = function (values) {
        if (action === 'create') {
            createMailingList.mutate(values, {
                onSuccess: function () {
                    var _a;
                    toast({ status: 'success', description: 'List created' });
                    (_a = props.onSuccess) === null || _a === void 0 ? void 0 : _a.call(props);
                },
                onError: function (_a) {
                    var message = _a.message;
                    toast({ status: 'error', description: message });
                },
            });
        }
        else {
            updateMailingList.mutate(__assign(__assign({}, values), { _id: mailingList === null || mailingList === void 0 ? void 0 : mailingList._id }), {
                onSuccess: function () {
                    var _a;
                    toast({ status: 'success', description: 'List saved' });
                    (_a = props.onSuccess) === null || _a === void 0 ? void 0 : _a.call(props);
                },
                onError: function (_a) {
                    var message = _a.message;
                    toast({ status: 'error', description: message });
                },
            });
        }
    };
    return (_jsxs(Modal, __assign({ isOpen: props.isOpen, onClose: props.onCancel }, { children: [_jsx(ModalOverlay, {}, void 0), _jsx(ModalContent, { children: _jsx(Formik, __assign({ initialValues: initialValues, validationSchema: mailingListFormSchema, onSubmit: onSubmit, enableReinitialize: true, validateOnMount: true }, { children: function (_a) {
                        var values = _a.values, touched = _a.touched, errors = _a.errors, formik = __rest(_a, ["values", "touched", "errors"]);
                        return (_jsxs(Form, __assign({ onSubmit: formik.handleSubmit }, { children: [_jsxs(ModalHeader, { children: [action === 'create' ? 'Add' : 'Update', " Mailing List"] }, void 0), _jsx(ModalCloseButton, {}, void 0), _jsx(ModalBody, { children: _jsxs(VStack, __assign({ spacing: 5, align: "stretch" }, { children: [action === 'update' && (_jsx(FormGroup, __assign({ label: "Status" }, { children: _jsx(FormikRadioGroup, { name: "status", options: [
                                                        { value: 'inactive', label: 'Inactive' },
                                                        { value: 'active', label: 'Active' },
                                                    ] }, void 0) }), void 0)), _jsx(FormGroup, __assign({ label: "Type" }, { children: _jsx(FormikRadioGroup, { name: "type", options: typeOptions, onChange: function (value) {
                                                        formik.setFieldValue('type', value);
                                                        formik.setFieldValue('identifier', '');
                                                    }, gridProps: {
                                                        gridTemplateColumns: 'repeat(2, 1fr)',
                                                    } }, void 0) }), void 0), [
                                                MailingListType.Distributor,
                                                MailingListType.Merchant,
                                                MailingListType.DistributorMerchant,
                                            ].includes(values.type) ? (_jsxs(FormGroup, __assign({ label: "Identifier (".concat(values.type
                                                    .split('|')
                                                    .map(function (v) { return capitalizeString(v); })
                                                    .join(' & '), ")"), isRequired: true }, { children: [_jsx(MailingListIdentifierAutocomplete, { size: "sm", name: "identifier", value: values.identifier, mailingListType: values.type, onBlur: function () {
                                                            formik.setFieldTouched('identifier', true);
                                                        }, onChange: function (selected) {
                                                            formik.setFieldValue('identifier', selected.value);
                                                        }, isInvalid: touched.identifier && errors.identifier ? true : false }, void 0), _jsx(Text, __assign({ fontSize: "sm", color: "red.600", mt: 1 }, { children: _jsx(ErrorMessage, { name: "identifier", component: "span" }, void 0) }), void 0)] }), void 0)) : (_jsx(FormGroup, __assign({ label: "Identfier", isRequired: true }, { children: _jsx(FormikField, { name: "identifier", size: "sm" }, void 0) }), void 0)), _jsx(FormGroup, __assign({ label: "Tag" }, { children: _jsx(FormikSelect, { name: "tag", options: Object.entries(MailingListTag).map(function (_a) {
                                                        var value = _a[1];
                                                        return ({
                                                            value: value,
                                                            label: MailingListTagLabels[value],
                                                        });
                                                    }) }, void 0) }), void 0), _jsx(FormGroup, __assign({ label: "Channel" }, { children: _jsx(FormikRadioGroup, { name: "channel", options: Object.entries(MailingListChannel).map(function (_a) {
                                                        var label = _a[0], value = _a[1];
                                                        return ({ value: value, label: label });
                                                    }) }, void 0) }), void 0), _jsx(FormGroup, __assign({ label: "Recipients" }, { children: _jsx(FormikField, { as: "textarea", size: "sm", name: "recipients" }, void 0) }), void 0)] }), void 0) }, void 0), _jsxs(ModalFooter, { children: [_jsx(Button, __assign({ size: "sm", variant: "outline", mr: 3, onClick: props.onCancel }, { children: "Cancel" }), void 0), _jsxs(Button, __assign({ size: "sm", type: "submit", colorScheme: "primary", isLoading: createMailingList.isLoading || updateMailingList.isLoading, isDisabled: createMailingList.isLoading ||
                                                updateMailingList.isLoading ||
                                                !formik.dirty ||
                                                !formik.isValid }, { children: [action === 'create' ? 'Add' : 'Update', " List"] }), void 0)] }, void 0)] }), void 0));
                    } }), void 0) }, void 0)] }), void 0));
};
MailingListForm.defaultProps = {
    action: 'create',
};
