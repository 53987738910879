var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Center, Flex, Grid, GridItem, Image, Progress, Text, } from '@chakra-ui/react';
import { useFileUploadMutation } from '@obtainly/hooks';
import { getFileName, getFileType } from '@obtainly/utils';
import { Fragment, useEffect, useState } from 'react';
import { RiEyeLine } from 'react-icons/ri';
import DefaultImageIcon from '../Image/img-icon.svg';
import PDFIcon from '../Image/pdf-icon.svg';
export var FileUploader = function (props) {
    var file = props.file, tag = props.tag, label = props.label, autoStart = props.autoStart, onUpload = props.onUpload, onUploadError = props.onUploadError, onRemove = props.onRemove;
    var _a = useState(false), uploadError = _a[0], setUploadError = _a[1];
    var _b = useState(false), isUploading = _b[0], setIsUploading = _b[1];
    var _c = useState(''), fileDataUrl = _c[0], setFileDataUrl = _c[1];
    var fileName = getFileName(file);
    var fileType = getFileType(file);
    var _d = useFileUploadMutation(), mutateFileUpload = _d.mutate, progress = _d.progress;
    var startUpload = function () {
        setIsUploading(true);
        setUploadError(false);
        mutateFileUpload({
            file: file,
            fileData: {
                title: "".concat(tag, "-").concat(fileName),
                overwrite: 'true',
                tag: tag,
            },
            uploadUrl: "".concat(process.env.REACT_APP_API_URL, "/upload/upload"),
        }, {
            onSuccess: function (_a) {
                var _b;
                var data = _a.data, message = _a.message;
                try {
                    if (data) {
                        onUpload(data)
                            .then(function () { return onRemove === null || onRemove === void 0 ? void 0 : onRemove(); })
                            .finally(function () { return setIsUploading(false); });
                    }
                    else {
                        throw new Error(message);
                    }
                }
                catch (error) {
                    setUploadError(true);
                    onUploadError === null || onUploadError === void 0 ? void 0 : onUploadError((_b = error === null || error === void 0 ? void 0 : error.message) !== null && _b !== void 0 ? _b : 'Failed to upload');
                    setIsUploading(false);
                }
            },
            onError: function (_a) {
                var message = _a.message;
                setUploadError(true);
                setIsUploading(false);
                onUploadError === null || onUploadError === void 0 ? void 0 : onUploadError(message !== null && message !== void 0 ? message : 'Failed to upload');
            },
            onSettled: function () { return onRemove === null || onRemove === void 0 ? void 0 : onRemove(); },
        });
    };
    useEffect(function () {
        var reader = new FileReader();
        reader.onloadend = function () {
            reader.readyState === FileReader.DONE &&
                setFileDataUrl(reader.result);
        };
        reader.readAsDataURL(file);
        return function () { return reader.abort(); };
    }, [file]);
    useEffect(function () {
        !!autoStart && startUpload();
    }, [autoStart]); // eslint-disable-line
    return (_jsx(Fragment, { children: _jsxs(Box, __assign({ display: "block", borderRadius: "md", overflow: "hidden", bg: uploadError
                ? 'critical.100'
                : progress
                    ? "linear-gradient(90deg, #F0FFF5 ".concat(progress, "%, #F9FAFB ").concat(100 - progress, "%)")
                    : 'gray.50', mb: "6px" }, { children: [!!progress && (_jsx(Progress, { value: progress, max: 100, height: "2px", colorScheme: "success" }, void 0)), _jsxs(Grid, __assign({ gap: 2, templateColumns: "auto 1fr auto", templateRows: "1fr", alignItems: "center" }, { children: [_jsx(GridItem, { children: !!fileDataUrl && (_jsxs(Box, __assign({ position: "relative", cursor: "zoom-in" }, { children: [_jsx(Image, { boxSize: "60px", objectFit: "cover", src: fileDataUrl, fallbackSrc: fileType === 'pdf' ? PDFIcon : DefaultImageIcon }, void 0), _jsx(Box, __assign({ position: "absolute", top: "0px", left: "0px", boxSize: "32px", bgGradient: "linear(to-tl, transparent, transparent, white)", display: "none" }, { children: _jsx(Center, __assign({ boxSize: "18px", fontSize: "14px", color: "gray.900" }, { children: _jsx(RiEyeLine, {}, void 0) }), void 0) }), void 0)] }), void 0)) }, void 0), _jsxs(GridItem, { children: [_jsx(Text, __assign({ wordBreak: "break-all", noOfLines: 1 }, { children: label || fileName }), void 0), !!label && (_jsx(Text, __assign({ wordBreak: "break-all", noOfLines: 1, fontSize: "xs", color: "gray.700" }, { children: fileName.slice(-50) }), void 0))] }, void 0), _jsx(GridItem, __assign({ pr: "10px" }, { children: progress ? (
                            // progress text
                            _jsx(Text, { children: progress === 100 ? 'Saving...' : "Uploading... (".concat(progress, "%)") }, void 0)) : (
                            // action buttons
                            _jsxs(Flex, __assign({ justify: { lg: 'end', sm: 'start' }, gap: 2, flexWrap: "wrap" }, { children: [!!file && (_jsx(Button, __assign({ size: "xs", colorScheme: uploadError ? 'critical' : 'success', onClick: function () { return startUpload(); }, isDisabled: isUploading, isLoading: isUploading, loadingText: "Saving" }, { children: uploadError ? 'Retry' : 'Upload' }), void 0)), !!onRemove && (_jsx(Button, __assign({ size: "xs", colorScheme: "blackAlpha", onClick: function () { return onRemove(); }, isDisabled: isUploading, isLoading: isUploading }, { children: "Remove" }), void 0))] }), void 0)) }), void 0)] }), void 0)] }), void 0) }, void 0));
};
