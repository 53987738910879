export var LedgerHead;
(function (LedgerHead) {
    LedgerHead["Merchant"] = "merchant";
    LedgerHead["Statements"] = "statements";
})(LedgerHead || (LedgerHead = {}));
export var LedgerSubhead;
(function (LedgerSubhead) {
    LedgerSubhead["Wallet"] = "wallet";
})(LedgerSubhead || (LedgerSubhead = {}));
export var LedgerLogTag;
(function (LedgerLogTag) {
    LedgerLogTag["Cash"] = "cash";
    LedgerLogTag["Transfer"] = "transfer";
    LedgerLogTag["Order"] = "order";
    LedgerLogTag["Signup"] = "signup";
    LedgerLogTag["Payback"] = "payback";
    LedgerLogTag["PenaltyCharged"] = "penalty-approved";
    LedgerLogTag["PenaltyPayback"] = "penalty";
    LedgerLogTag["InterestCharged"] = "interest-approved";
    LedgerLogTag["InterestPayback"] = "interest";
    LedgerLogTag["PrincipalCharged"] = "principal-approved";
    LedgerLogTag["PrincipalPayback"] = "principal";
    LedgerLogTag["Funding"] = "funding";
    LedgerLogTag["Credit"] = "credit";
    LedgerLogTag["Approved"] = "approved";
})(LedgerLogTag || (LedgerLogTag = {}));
