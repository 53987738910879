import { useQueryModel } from '@obtainly/http';
import { useMemo } from 'react';
export function useOrderConfig(distributorId, merchantId) {
    var _a = useQueryModel('config.read_key_dictionary', {}, {
        refetchOnWindowFocus: true,
        refetchOnMount: true,
    }), configs = _a.data, isLoading = _a.isLoading;
    var isOrderActivated = useMemo(function () {
        var _a, _b, _c;
        if (!distributorId) {
            return false;
        }
        if (((_a = configs === null || configs === void 0 ? void 0 : configs["STOP_MERCHANT_DELIVERY.".concat(merchantId)]) === null || _a === void 0 ? void 0 : _a.value) === 'true') {
            return false;
        }
        if (((_b = configs === null || configs === void 0 ? void 0 : configs["STOP_DELIVERY.".concat(distributorId)]) === null || _b === void 0 ? void 0 : _b.value) === 'true') {
            return false;
        }
        if (((_c = configs === null || configs === void 0 ? void 0 : configs["STOP_DELIVERY.default"]) === null || _c === void 0 ? void 0 : _c.value) === 'true') {
            return false;
        }
        return true;
    }, [merchantId, distributorId, configs]);
    return {
        isLoading: isLoading,
        isOrderActivated: isOrderActivated,
    };
}
