var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-console */
import { Flex } from '@chakra-ui/react';
import { useFilter, useQueryStringParse, useRouter } from '@obtainly/hooks';
import { useQueryModel } from '@obtainly/http';
import { PageTabs } from '@obtainly/ui/web-desktop';
import { Card, CardBody, ContentLoader, SearchInput, } from '@obtainly/ui/web-shared';
import { basicSearch } from '@obtainly/utils';
import { orderBy } from 'lodash';
import React from 'react';
import { ContentHeader, Exporter, Layout, } from 'src/core/components';
import { SupplierForm, SuppliersTable } from '../components';
var PageTab;
(function (PageTab) {
    PageTab[PageTab["All"] = 0] = "All";
    PageTab[PageTab["Active"] = 1] = "Active";
    PageTab[PageTab["Inactive"] = 2] = "Inactive";
})(PageTab || (PageTab = {}));
var exportableColumns = function () {
    return [
        { label: 'Supplier ID', accessor: '_id', selected: true },
        { label: 'Status', accessor: 'status', selected: true },
        { label: 'Supplier Name', accessor: 'name', selected: true },
        { label: '# of Customers', accessor: 'customer_count', selected: true },
    ];
};
export var Suppliers = function () {
    var router = useRouter('supplier');
    var _a = useQueryModel('distributor.read_all_light_with_customer_count', {}), distributors = _a.data, isLoading = _a.isLoading, refetch = _a.refetch;
    var filters = useQueryStringParse([], ['page']);
    var filteredDistributors = useFilter(orderBy(distributors, 'name', 'asc'), filters, {
        search: function (supplier) {
            return basicSearch(supplier.name, filters.search) ||
                basicSearch(supplier.status, filters.search);
        },
    });
    var activeFilters = Object.keys(filters);
    var activeTab = React.useMemo(function () {
        if (activeFilters.length === 1) {
            return filters.status === 'active'
                ? PageTab.Active
                : filters.status === 'inactive'
                    ? PageTab.Inactive
                    : PageTab.All;
        }
        return PageTab.All;
    }, [filters, activeFilters]);
    var handleTabChange = function (index) {
        if (index === PageTab.Active) {
            router.switchQuery({ status: 'active' });
        }
        else if (index === PageTab.Inactive) {
            router.switchQuery({ status: 'inactive' });
        }
        else {
            router.go();
        }
    };
    return (_jsxs(Layout, __assign({ meta: { title: 'Suppliers' } }, { children: [_jsx(ContentHeader, __assign({ heading: "Suppliers", border: 0 }, { children: _jsxs(Flex, __assign({ gridGap: 2 }, { children: [_jsx(Exporter, { label: "Export", filename: "obtainly-suppliers", data: filteredDistributors || [], columns: exportableColumns(), isDisabled: isLoading || !(filteredDistributors === null || filteredDistributors === void 0 ? void 0 : filteredDistributors.length) }, void 0), _jsx(SupplierForm, { action: "create", refetch: refetch }, void 0)] }), void 0) }), void 0), _jsxs(Card, { children: [_jsx(PageTabs, { index: activeTab, onChange: handleTabChange, tabs: [{ label: 'All' }, { label: 'Active' }, { label: 'Inactive' }] }, void 0), _jsx(CardBody, { children: _jsx(SearchInput, { value: filters.search || '', onChange: function (value) {
                                return router.switchQuery(__assign(__assign({}, filters), { search: value }));
                            }, placeholder: "Search customers", isDisabled: !(distributors === null || distributors === void 0 ? void 0 : distributors.length) }, void 0) }, void 0), _jsxs(CardBody, __assign({ p: 0, pt: 2 }, { children: [_jsx(ContentLoader, { isLoading: isLoading }, void 0), !isLoading && (_jsx(SuppliersTable, { refetch: refetch, suppliers: filteredDistributors || [] }, void 0))] }), void 0)] }, void 0)] }), void 0));
};
