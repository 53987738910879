import { Merchant, Merchants } from './pages';
export var merchantRoutes = [
    {
        id: 'merchants',
        path: '/merchant',
        component: Merchants,
        requiresAuth: true,
        exact: true,
    },
    {
        id: 'merchant',
        path: '/merchant/:id',
        component: Merchant,
        requiresAuth: true,
        exact: true,
    },
];
export * from './components';
