import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useQueryModel } from '@obtainly/http';
import { LedgerLogTag } from '@obtainly/models';
import { ContentLoader, LedgerLogItem, ListMaker, } from '@obtainly/ui/web-shared';
import { useMemo } from 'react';
export var FundingRequestLogs = function (props) {
    var _a;
    var fundingRequestId = props.fundingRequestId, isLoadingFundingReq = props.isLoadingFundingReq, isPenaltyCharges = props.isPenaltyCharges;
    var _b = useQueryModel('funding_request.read_statement', { _id: fundingRequestId }, { enabled: !!fundingRequestId }), responseData = _b.data, isLoadingFundingRequestLogs = _b.isLoading;
    var isLoading = isLoadingFundingReq || isLoadingFundingRequestLogs;
    var CREDIT_HISTORY_TAGS = useMemo(function () { return [
        LedgerLogTag.Funding,
        LedgerLogTag.PenaltyPayback,
        LedgerLogTag.InterestPayback,
        LedgerLogTag.PrincipalPayback,
    ]; }, []);
    var logs = useMemo(function () {
        var _a;
        var list = (_a = responseData === null || responseData === void 0 ? void 0 : responseData.list) === null || _a === void 0 ? void 0 : _a.all;
        if (!(list === null || list === void 0 ? void 0 : list.length))
            return [];
        return list.filter(function (log) {
            if (isPenaltyCharges)
                return log.tag === LedgerLogTag.PenaltyCharged;
            return CREDIT_HISTORY_TAGS.includes(log.tag);
        });
    }, [CREDIT_HISTORY_TAGS, isPenaltyCharges, (_a = responseData === null || responseData === void 0 ? void 0 : responseData.list) === null || _a === void 0 ? void 0 : _a.all]);
    return (_jsxs(_Fragment, { children: [_jsx(ContentLoader, { isLoading: isLoading, contentUnavailable: !(logs === null || logs === void 0 ? void 0 : logs.length), errorMessage: "No transactions found" }, void 0), !isLoading && (logs === null || logs === void 0 ? void 0 : logs.length) ? (_jsx(ListMaker, { data: logs, component: _jsx(LedgerLogItem, {}, void 0) }, void 0)) : null] }, void 0));
};
