var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-console */
import React, { useMemo } from 'react';
import { Flex, Box, Text, Badge } from '@chakra-ui/react';
import { LedgerLogTag } from '@obtainly/models';
import { useQueryModel } from '@obtainly/http';
import { formatCurrency, formatTag, formatUnixTime } from '@obtainly/utils';
import { ContentLoader, ListMaker } from '@obtainly/ui/web-shared';
export var LedgerLogs = function (props) {
    var ledgerId = props.ledgerId, isLoadingLedger = props.isLoadingLedger, modifier = props.modifier;
    var _a = useQueryModel('ledger_log.read_by_ledger', { ledger_id: ledgerId }, { enabled: !!ledgerId }), ledgerLogs = _a.data, isLoadingLedgerLogs = _a.isLoading;
    var isLoading = isLoadingLedger || isLoadingLedgerLogs;
    var logs = useMemo(function () {
        if (!ledgerLogs)
            return [];
        if (!modifier)
            return ledgerLogs;
        return modifier(ledgerLogs);
    }, [ledgerLogs, modifier]);
    return (_jsxs(_Fragment, { children: [_jsx(ContentLoader, { isLoading: isLoading, contentUnavailable: !(ledgerLogs === null || ledgerLogs === void 0 ? void 0 : ledgerLogs.length), errorMessage: "No transactions found" }, void 0), !isLoading && (logs === null || logs === void 0 ? void 0 : logs.length) ? (_jsx(ListMaker, { data: logs, component: _jsx(LedgerLogItem, {}, void 0) }, void 0)) : null] }, void 0));
};
export var LedgerLogItem = function (_a) {
    var _b;
    var item = _a.item;
    var description = '';
    try {
        var json = JSON.parse(item.description);
        description = ((_b = json === null || json === void 0 ? void 0 : json.narration) === null || _b === void 0 ? void 0 : _b.split('|').join(' | ')) || '-';
    }
    catch (error) {
        description = item.description;
    }
    var colorScheme = item.value < 0
        ? 'critical'
        : item.value > 0
            ? item.tag === LedgerLogTag.Transfer
                ? 'success'
                : [LedgerLogTag.Credit, LedgerLogTag.Funding].includes(item.tag)
                    ? 'info'
                    : 'warning'
            : 'gray';
    return (_jsx(_Fragment, { children: item.value ? (_jsx(Flex, __assign({ px: "15px", py: "10px", pt: "1.25rem", alignItems: "flex-start", borderBottom: "1px solid", borderColor: "gray.100", justifyContent: "space-between" }, { children: _jsx(Flex, __assign({ alignItems: "flex-start", width: "100%" }, { children: _jsxs(Box, __assign({ ml: 2, width: "100%" }, { children: [_jsxs(Flex, __assign({ display: item.tag === 'penalty-approved' ? 'none' : 'flex', alignItems: "center", justifyContent: "space-between", mb: 1 }, { children: [item.tag !== 'penalty-approved' && (_jsx(Badge, __assign({ colorScheme: colorScheme }, { children: formatTag(item.tag) }), void 0)), _jsx(Text, __assign({ fontWeight: "semibold" }, { children: formatCurrency(item.value, { showCurrency: true }) }), void 0)] }), void 0), !!description && (_jsx(Text, __assign({ color: "gray.800", wordBreak: "break-word" }, { children: description }), void 0)), _jsxs(Flex, __assign({ alignItems: "center", justifyContent: item.tag === 'penalty-approved'
                                ? 'space-between'
                                : 'flex-start', mt: 2, mb: "15px" }, { children: [_jsx(Text, __assign({ fontSize: "sm", color: "gray.700" }, { children: formatUnixTime(item === null || item === void 0 ? void 0 : item.created, 'dd MMM yyy, hh:mm a') }), void 0), item.tag === 'penalty-approved' && (_jsx(Text, __assign({ color: "red.500", fontWeight: "semibold" }, { children: formatCurrency(item.value, { showCurrency: true }) }), void 0))] }), void 0)] }), void 0) }), void 0) }), void 0)) : null }, void 0));
};
