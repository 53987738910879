import React from 'react';
export function genericFilter(data, filters, filterConfig) {
    var _data = data || [];
    var _filters = filters ? Object.entries(filters) : [];
    if ((_data === null || _data === void 0 ? void 0 : _data.length) && (_filters === null || _filters === void 0 ? void 0 : _filters.length)) {
        _filters.forEach(function (_a) {
            var key = _a[0], value = _a[1];
            if (value) {
                _data = _data.filter(function (i) {
                    var _a;
                    return ((_a = filterConfig === null || filterConfig === void 0 ? void 0 : filterConfig[key]) === null || _a === void 0 ? void 0 : _a.call(filterConfig, i)) || i[key] === value;
                });
            }
        });
    }
    return _data;
}
export function useFilter(data, filters, filterConfig) {
    var filtered = React.useMemo(function () {
        return genericFilter(data || [], filters, filterConfig);
    }, [data, filters, filterConfig]);
    return filtered;
}
